import { CalendarSliceActions } from 'Store/Calendar';
import { Dispatch } from 'react';
interface Iprops {
    dispatch: Dispatch<any>
    dispatchReact: Dispatch<any>,
    APP_ID: string,
    navigate
}
const logout = (props: Iprops) => {
    props?.dispatch({ type: 'logout' });
    global.intercomSettings = {
        app_id: props?.APP_ID,
        hide_default_launcher: true,
        session_duration: 0,
    };
    if (!!global.Intercom) { global.Intercom('shutdown'); }
    props?.dispatchReact(CalendarSliceActions?.setUserInfo(undefined))
    props?.dispatchReact(CalendarSliceActions.setIsAuth(false))
    props?.dispatchReact(CalendarSliceActions.setSelectedStadium(undefined))
    props?.dispatchReact(CalendarSliceActions.setPitch(undefined))
    props?.dispatchReact(CalendarSliceActions.setPitchId(0))
    props?.navigate("/auth/login");
}

export default logout