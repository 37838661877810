import { api } from 'services/api'
import upDateFixedBooking from './updateFixedBooking'

const useApi = api.injectEndpoints({
    endpoints: build => ({
        upDateFixedBooking: upDateFixedBooking(build),
    }),
    overrideExisting: false,
})

export const {
    useUpDateFixedBookingMutation
} = useApi
