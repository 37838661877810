import React from 'react';

import TableComponents from './TableComponents';
import Locale from 'translations';
import { getActionLogValues } from './helpers/getActionLogValues';
import { useState } from 'react';
import { Button } from 'reactstrap';

const BookingLogsDetails = ({ data }) => {
    const { actionLogs, calendarPage } = Locale;
    const [LogsDetailsisOpened, setLogsDetailsisOpened] = useState(false)
    const dataKeys = Object.keys(data);
    return (
        <>
            <div className='divDetails w-75 '>
                <Button className='font14 mb-1 default'
                    color='default '
                    onClick={() => setLogsDetailsisOpened(!LogsDetailsisOpened)}>
                    {calendarPage?.viewChanges}
                    <i className="arrow ml-2 fa-solid fa-caret-down"
                        style={{
                            transform: LogsDetailsisOpened ? "rotate(-90deg)" : ""
                        }}>
                    </i>
                </Button>
                <div className='BookingLogsDetails' style={{ display: LogsDetailsisOpened ? "block" : "none" }}>
                    <table className="table table-striped table-bordered">
                        <tbody className='font-medium text-center'>
                            {dataKeys.map((key) => {
                                const value = getActionLogValues(data, key)
                                return <TableComponents title={actionLogs[key]} value={value} />
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
export default BookingLogsDetails;