import { Pitch, Stadium } from "services/user/me"
import { CalendarState, paymentMethodOfStadium, BookingState, AddonObj, UserData, ItemInReactSelect, PhoneCode } from './props';

const setLocale = (
    state: CalendarState,
    payload: { payload: string },
) => {
    state.locale =
        payload.payload
}

const setUserInfo = (
    state: CalendarState,
    payload: { payload: UserData | undefined },
) => {
    state.user =
        payload.payload

}
const setUserRole = (
    state: CalendarState,
    payload: { payload: string | undefined },
) => {
    state.userRole =
        payload.payload
}

const setIsAuth = (
    state: CalendarState,
    payload: { payload: boolean },
) => {
    state.isAuth =
        payload.payload
}

const setStadiumsList = (
    state: CalendarState,
    payload: { payload: Stadium[] | [] },
) => {
    state.stadiumsList =
        payload.payload
}

const setSelectedStadium = (
    state: CalendarState,
    payload: { payload: Stadium | undefined },
) => {
    state.selectedStadium =
        payload?.payload
}

const setPitchesforStadium = (
    state: CalendarState,
    payload: { payload: Pitch[] },
) => {
    state.pitchesforStadium =
        payload.payload
}

const setPitch = (
    state: CalendarState,
    payload: { payload: Pitch | undefined },
) => {
    state.pitch =
        payload.payload
}

const setPitchId = (
    state: CalendarState,
    payload: { payload: number },
) => {
    state.pitchId =
        payload?.payload
}


const setcurrentView = (
    state: CalendarState,
    payload: { payload: string },
) => {
    state.currentView =
        payload.payload
}
const setBookingState = (
    state: CalendarState,
    payload: { payload: BookingState },
) => {
    state.BookingState =
        payload.payload
}

const setpaymentMethodOfStadium = (
    state: CalendarState,
    payload: { payload: paymentMethodOfStadium[] }
) => {
    state.paymentMethodsArr =
        payload.payload
}
const setBookingModalIsOpened = (
    state: CalendarState,
    payload: { payload: boolean },
) => {
    state.BookingModalIsOpened =
        payload.payload
}

const setBookingModalTitle = (
    state: CalendarState,
    payload: { payload: string },
) => {
    state.BookingModalTitle =
        payload.payload
}


const setAddonDetails = (
    state: CalendarState,
    payload: { payload: AddonObj },
) => {
    state.addonObj =
        payload.payload
}

const setPhoneCodes = (
    state: CalendarState,
    payload: { payload: PhoneCode[] | [] },
) => {
    state.phoneCodes =
        payload.payload
}
export default {
    setLocale,
    setUserInfo,
    setIsAuth,
    setStadiumsList,
    setSelectedStadium,
    setPitch,
    setPitchId,
    setPitchesforStadium,
    setcurrentView,
    setBookingState,
    setpaymentMethodOfStadium,
    setBookingModalIsOpened,
    setBookingModalTitle,
    setAddonDetails,
    setUserRole,
    setPhoneCodes
}
