import { api } from 'services/api'
import getBookingPrice from './getBookingSlotPrice'

const useApi = api.injectEndpoints({
    endpoints: build => ({
        getBookingPrice: getBookingPrice(build),
    }),
    overrideExisting: false,
})

export const {
    useGetBookingPriceMutation
} = useApi
