import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

import { api } from '../services/api'
import storage from 'redux-persist/lib/storage'

import calendar from './Calendar'
import coachesState from './Coaches'
import loyaltyState from './LoyaltyProgram'
import coachCalendar from './CoachCalendar'
import manageStadium from './manageStadium'


const reducers = combineReducers({
  api: api.reducer,
  calendar,
  coachesState,
  loyaltyState,
  coachCalendar,
  manageStadium
})
import localforage from 'localforage';


const persistConfig = {
  key: 'root8',
  storage: localforage,
  whitelist: ['calendar', 'coachesState', 'loyaltyState']
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: false,
      // {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
    }).concat(api.middleware)

    return middlewares
  },
})

const persistor = persistStore(store)

setupListeners(store.dispatch)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = ReturnType<typeof store.dispatch>
export { store, persistor }
