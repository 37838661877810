import State from './state'
import Reducer from './reducer'

import { createSlice } from '@reduxjs/toolkit'
import { CalendarState } from './props'

const slice = createSlice({
  name: 'calendar',
  initialState: State as CalendarState,
  reducers: Reducer,
})

export const CalendarSliceActions = slice.actions

export default slice.reducer
