import React, { useEffect, useState } from 'react'
import SingleBooking from '../SingleBooking/SingleBooking';
import FixedBooking from '../FixedBooking/FixedBooking';
import useResetBookingState from 'Pages/CalendarPage/Hooks/useResetBookingState';
import Locale from 'translations';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import { CalendarSliceActions } from 'Store/Calendar';
import { useGetSlotInfoMutation } from 'services/getSlotInfo';
import {
    Modal, ModalHeader, ModalBody, Nav,
    NavItem, TabContent, TabPane, NavLink
} from 'reactstrap';

const NewBookingModals = ({ slotInfo }) => {
    const title = useSelector((state: RootState) => state.calendar.BookingModalTitle)
    const BookingModalIsOpened = useSelector((state: RootState) => state.calendar.BookingModalIsOpened)
    const dispatch = useDispatch()
    const [bookingAfterReset] = useResetBookingState()
    const { NewBookingModalTitle, calendarPage } = Locale
    const toggleModalCreate = () => {
        setopened(!BookingModalIsOpened)
        setslotCount(null)
        dispatch(CalendarSliceActions.setBookingModalIsOpened(!BookingModalIsOpened))
        if (bookingAfterReset)
            dispatch(CalendarSliceActions.setBookingState(bookingAfterReset))

    }
    const [activeTab, setActiveTab] = useState<string>('single');
    const [slotCount, setslotCount] = useState(null)

    const [opened, setopened] = useState<boolean>(false);
    const BookingState = useSelector((state: RootState) => state.calendar.BookingState)
    const [RequestSlortInfo] = useGetSlotInfoMutation()
    useEffect(() => {
        setopened(BookingModalIsOpened)
    }, [BookingModalIsOpened])

    const toggleTab = (tab: string) => {
        setActiveTab(tab)
        if (tab == "single")
            dispatch(CalendarSliceActions.setBookingState({
                ...BookingState,
                is_single_booking: true,
            }))
        else (dispatch(CalendarSliceActions.setBookingState({
            ...BookingState,
            is_single_booking: false,
        })))
    }

    const ToggleInfo = () => {
        if (slotCount == null) {
            RequestSlortInfo(slotInfo).then((data: any) => {
                if (data.data) {
                    let count = data?.data?.data.count
                    setslotCount(count)
                }
            })
        }
        else setslotCount(null)
    }

    useEffect(() => {
        if (BookingState.is_single_booking)
            setActiveTab("single")
        else {
            setActiveTab("fixed")
        }
    }, [BookingState.is_single_booking])

    return (
        <>
            <Modal
                isOpen={opened}
                toggle={toggleModalCreate}
                size="lg"
                className="create-booking">
                <ModalHeader className="font-weight-bold d-flex" toggle={toggleModalCreate}>
                    {title}
                    <i className="fa-solid fa-circle-info ml-2 cursor-pointer" role='button'
                        onClick={ToggleInfo}></i>
                    {slotCount !== null ? <p>{calendarPage?.percentage} {slotCount} %</p> : null}
                </ModalHeader>
                <ModalBody className="py-0">
                    <div className="main-tabs px-1">
                        {title === NewBookingModalTitle?.create ?
                            <Nav tabs className="align-items-center main-nav">
                                <NavItem>
                                    <NavLink
                                        className={activeTab === 'single' ? "active" : ""}
                                        onClick={() => { toggleTab('single') }}
                                    >
                                        {calendarPage?.singleBooking}
                                        <div className="tab-bar"></div>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={activeTab === 'fixed' ? "active" : ""}
                                        onClick={() => { toggleTab('fixed'); }}
                                    >
                                        {calendarPage?.fixedBooking}
                                        <div className="tab-bar"></div>
                                    </NavLink>
                                </NavItem>
                            </Nav> : ""
                        }
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="single">
                                {activeTab === 'single' ? (
                                    <SingleBooking
                                        title={title}
                                        toggleModalCreate={toggleModalCreate}
                                    />
                                ) : null}
                            </TabPane>

                            <TabPane tabId="fixed">
                                {activeTab === 'fixed' ? (
                                    <FixedBooking
                                        title={title}
                                        toggleModalCreate={toggleModalCreate}
                                    />
                                ) : null}
                            </TabPane>
                        </TabContent>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}


export default NewBookingModals