import { api } from 'services/api'
import fetchPhoneCodes from './fetchPhoneCodes'

const useApi = api.injectEndpoints({
    endpoints: build => ({
        fetchPhoneCodes: fetchPhoneCodes(build),
    }),
    overrideExisting: false,
})

export const {
    useLazyFetchPhoneCodesQuery,
} = useApi
