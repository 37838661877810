import React, { useEffect, useState } from 'react';
import Select from "react-select";
import getPitch from 'helpers/getPitch';

import { useDispatch, useSelector } from 'react-redux';
import { Stadium } from 'services/user/me';
import { RootState } from 'Store';
import { CalendarSliceActions } from 'Store/Calendar';
import { useGetPitchesOfStadiumMutation } from 'services/getPitchesOfStadiumPO';
import { useMalaebState } from 'context/global';
import { userRoleEnum } from 'Pages/Coaches/enums/userRolesEnum';
import { useGetPitchCoachMutation } from 'services/getPitchesOfStadiumInCoach';
import { getLangTitle } from 'Pages/Coaches/helpers/getLangTitle';

const ReactSelect = () => {
    const { locale } = useMalaebState();
    const dispatch = useDispatch()
    const userRole = useSelector((state: RootState) => state.calendar?.userRole)
    const stadiums = useSelector((state: RootState) => state.calendar?.stadiumsList)
    const selectedStadium = useSelector((state: RootState) => state.calendar.selectedStadium)
    const bookingState = useSelector((state: RootState) => state.calendar.BookingState)
    const [getPitchesOfStadiumPORequest, getPitchesOfStadiumPOResponse] = useGetPitchesOfStadiumMutation()
    const [getPitchesOfStadiumCoachRequest, getPitchesOfStadiumCoachResponse] = useGetPitchCoachMutation()
    const [myStadiums, setmyStadiums] = useState<Stadium[]>([])
    const [myStadium, setmyStadium] = useState<Stadium | undefined>(selectedStadium)

    const getPitches = (value) => {
        if (userRole !== userRoleEnum?.coach)
            getPitch({ stadium: value, dispatchRedux: dispatch, getPitchesOfStadiumRequest: getPitchesOfStadiumPORequest, bookingState })
        else
            getPitch({ stadium: value, dispatchRedux: dispatch, getPitchesOfStadiumRequest: getPitchesOfStadiumCoachRequest, bookingState })

    }

    const handleStadium = (value: Stadium) => {
        setmyStadium(value)
        dispatch(CalendarSliceActions.setSelectedStadium(value)) // when change stadium
        getPitches(value)
    }

    useEffect(() => {
        if (stadiums?.length) {
            console.log(stadiums);
            
            let myStadiumsArr: Stadium[] = []
            stadiums?.map((item) => myStadiumsArr.push(
                {
                    label: getLangTitle(item, "title"),
                    id: item.id,
                    label_ar: item?.title_ar || item?.title,
                    main_currency_label: item?.main_currency_label,
                    main_currency_label_ar: getLangTitle(item, "main_currency_label"),
                    supported_payment_methods: item?.supported_payment_methods,
                    phone_code: item?.phone_code,
                }))
            const myLocalizedStadium = myStadiumsArr?.find((stadium) => stadium?.id == selectedStadium?.id)
            setmyStadium(myLocalizedStadium)
            setmyStadiums(myStadiumsArr)
        }
    }, [stadiums, locale])


    return (
        <>
            <Select
                className="basic-single"
                value={myStadium}
                isClearable={true}
                getOptionValue={(option) => option.label} // changes here!!!
                isLoading={false}
                isRtl={locale}
                isSearchable={true}
                name="stadium"
                onChange={handleStadium}
                options={myStadiums}
            />
        </>
    );
}
export default ReactSelect;
