import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarSliceActions } from 'Store/Calendar';
import { RootState } from 'Store/index';
import Locale from 'translations';

export const AskToCancelFixedBooking = ({ askToCancelFixed, setaskToCancelFixed, askToCancel, setaskToCancel }) => {
    const BookingState = useSelector((state: RootState) => state.calendar.BookingState)
    const dispatch = useDispatch()
    const { calendarPage } = Locale
    const toggleAskToCancelFixedBooking = () => {
        setaskToCancelFixed(false)
        dispatch(CalendarSliceActions.setBookingState({
            ...BookingState,
            is_single_booking: false,
        }))
    }
    const handleOpenCancelModal = (isSingle: boolean) => {
        if (isSingle) {
            dispatch(CalendarSliceActions.setBookingState({
                ...BookingState,
                is_single_booking: true,
            }))
        }
        else {
            dispatch(CalendarSliceActions.setBookingState({
                ...BookingState,
                is_single_booking: false,
            }))
        }
        setaskToCancel(true)
    }

    return (
        <>
            <Modal
                isOpen={askToCancelFixed}
                toggle={toggleAskToCancelFixedBooking}
                size="md"
                className="create-booking view-booking"
            >
                <ModalHeader
                    className="font-weight-bold"
                    toggle={toggleAskToCancelFixedBooking}
                ></ModalHeader>
                <ModalBody className="py-0 text-center">
                    <h5 className="py-3">{calendarPage?.cancelFixedQues}</h5>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary"
                        onClick={() => handleOpenCancelModal(true)}
                    >
                        {calendarPage?.onlyThisBooking}
                    </Button>
                    <Button
                        color="secondary"
                        onClick={() => handleOpenCancelModal(false)}
                    >
                        {calendarPage?.entire}
                    </Button>
                </ModalFooter>
            </Modal>
        </>)
}
