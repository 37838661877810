import React from 'react';
import images from "Pages/CalendarPage/Helpers/images";
import Locale from "translations"; 

const useGetMenuData = () => {
const { profile } = Locale;
const allMenu = {
    menuItems: [
        {
            label: profile.schedule,
            icon: images?.sideNav?.schedule,
            route: '/calendar',
        },
        {
            label: profile.reporting,
            icon: images?.sideNav?.reporting,
            route: '/',
        },
        {
            label: profile.manageStadium,
            icon: images?.sideNav?.manageStadium,
            route: '/ManageStadium',
        },
        {
            label: profile.coaches,
            icon: images?.sideNav?.coach,
            route: '/coaches',
        },
        {
            label: profile.settingsAccount,
            icon: images?.sideNav?.settingsAccount,
            route: '/profile',
        },
        {
            label: profile.loyality,
            icon: images?.sideNav?.loyality,
            route: '/loyality-program',
        },
        {
            label: profile.couponDiscount,
            icon: images?.sideNav?.coupons,
            route: '/CouponDiscount',
        },
        {
            label: profile.billing,
            icon: images?.sideNav?.billing,
            route: '/billing',
        },
        {
            label: profile.malaebLive,
            icon: images?.sideNav?.malaebLive,
            route: '/Malaeb-Live',
        }
    ],
    menuItemsBottom: [
        {
            label: profile.logout,
            icon: 'fas fa-sign-out-alt',
            route: '/auth/login',
        },

    ],
    coachMenuItems: [

        {
            label: profile.coaches,
            icon: images?.sideNav?.schedule,
            route: '/coaches',
        },
        {
            label: profile.editDetails,
            icon: images?.sideNav?.coach,
            route: '/EditDetails',
        },
        {
            label: profile.settingsAccount,
            icon: images?.sideNav?.settingsAccount,
            route: '/profile',
        }
    ],
};
  return (
   {allMenu}
  )
}

export default useGetMenuData