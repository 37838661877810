import { api } from 'services/api'
import createFixedBooking from './createFixedBooking'

const useApi = api.injectEndpoints({
    endpoints: build => ({
        createFixedBooking: createFixedBooking(build),
    }),
    overrideExisting: false,
})

export const {
    useCreateFixedBookingMutation
} = useApi
