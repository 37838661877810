import { api } from 'services/api'
import getStadiums from './getStadiums'

const useApi = api.injectEndpoints({
    endpoints: build => ({
        getStadiums: getStadiums(build),
    }),
    overrideExisting: false,
})

export const {
    useLazyGetStadiumsQuery,
} = useApi
