export const repeatedWeekly = [
  {
    id: 0,
    label: 'S',
    isActive: false,
  },
  {
    id: 1,
    label: 'M',
    isActive: false,
  },
  {
    id: 2,
    label: 'T',
    isActive: false,
  },
  {
    id: 3,
    label: 'W',
    isActive: false,
  },
  {
    id: 4,
    label: 'T',
    isActive: false,
  },
  {
    id: 5,
    label: 'F',
    isActive: false,
  },
  {
    id: 6,
    label: 'S',
    isActive: false,
  },
];



