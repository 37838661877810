import { api } from 'services/api'
import deleteAddon from './deleteAddon'

const useApi = api.injectEndpoints({
    endpoints: build => ({
        deleteAddon: deleteAddon(build),
    }),
    overrideExisting: false,
})

export const {
    useDeleteAddonMutation
} = useApi
