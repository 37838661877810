import React from 'react'
import Locale from 'translations'


const getBookingType = () => {

    const { calendarPage } = Locale
    const paymentMethod = [
        {
            id: 1,
            label: calendarPage?.cash,
            value: 1,
        },
        {
            id: 5,
            label: calendarPage?.tap,
            value: 5,
        },
    ]
    const bookingType = [
        {
            id: 2,
            label: calendarPage?.owner,
            value: 2,
        },
        {
            id: 1,
            label: calendarPage?.user,
            value: 1,
        },
    ]
    const durationTime = [
        {
            id: 60,
            label: calendarPage?.mins60,
            value: 0,
        },
        {
            id: 90,
            label: calendarPage.mins90,
            value: 1,
        },
        {
            id: 120,
            label: calendarPage.mins120,
            value: 2,
        },
        {
            id: 180,
            label: calendarPage?.other,
            value: 180,
        },
    ];
    const durationOthers = [
        {
            id: 150,
            label: calendarPage["hours2.5"],
            value: 3,
        },
        {
            id: 180,
            label: calendarPage?.hours3,
            value: 4,
        },
        {
            id: 210,
            label: calendarPage["hours3.5"],
            value: 5,
        },
        {
            id: 240,
            label: calendarPage?.hours4,
            value: 6,
        },
        {
            id: 270,
            label: calendarPage["hours4.5"],
            value: 7,
        },
        {
            id: 300,
            label: calendarPage?.hours5,
            value: 8,
        },
        {
            id: 330,
            label: calendarPage["hours5.5"],
            value: 9,
        },
        {
            id: 360,
            label: calendarPage?.hours6,
            value: 9,
        },
        {
            id: 390,
            label: calendarPage["hours6.5"],
            value: 10,
        },
    ];
    return (
        [bookingType, durationTime, durationOthers,paymentMethod]
    )
}

export default getBookingType