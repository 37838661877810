import AuthRoute from 'components/AuthRoute';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import ForgotPasswordSubmitted from 'modules/auth/Components/ForgotPasswordSubmitted';
import ResetPassword from 'modules/auth/Components/ResetPassword';
import AccountSettings from 'modules/AccountSettings';
import React, { lazy } from 'react';
import { Route, Router, Routes, Switch } from 'react-router-dom';
import ForgotPassword from './modules/auth/Components/ForgotPassword';
import CalendarPage from 'Pages/CalendarPage/CalendarPage';

// Lazy Components
const Error404 = lazy(() => import('./components/Errors/Error404/Error404'));
const Login = lazy(() => import('./modules/auth/Components/Login'));
const ReportingModule = lazy(() => import('modules/reporting/containers/index'));
const ManageStadiumPage = lazy(() => import('Pages/ManageStadiumPage/ManageStadiumPage'));
const CouponDiscount = lazy(() => import('modules/couponDiscount/containers/CouponDiscount'));
const BillingPage = lazy(() => import('Pages/BillingPage/BillingPage'));
const CoachesPage = lazy(() => import('Pages/Coaches/CoachPage'));
const AddCoaches = lazy(() => import('Pages/Coaches/components/AddCoach'));
const EditCoach = lazy(() => import('Pages/Coaches/components/EditCoach'));
const Availability = lazy(() => import('Pages/Coaches/components/Availability'));
const LoyalityProgram = lazy(() => import('Pages/LoyalityProgram/LoyalityProgram'));
const Usage = lazy(() => import('Pages/LoyalityProgram/Components/Usage'));
const PackagesPage = lazy(() => import('Pages/Coaches/packagesComponents/PackagesPage'));
const VideosPage = lazy(() => import('Pages/VideosPage/VideosPageComponent'));


const myRoutes = (

  <Routes>
    {/* Login Module */}
    <Route path="/auth/login" exact element={<Login />} />
    <AuthRoute path="/auth/forgot_password" exact element={<ForgotPassword />} />
    <AuthRoute
      path="/auth/forgot_password_submitted"
      exact
      element={<ForgotPasswordSubmitted />}
    />
    <AuthRoute
      path="/auth/reset_password/:token/:email"
      exact
      element={<ResetPassword />}
    />
    <PrivateRoute exact path="/" element={<ReportingModule />} />
    <PrivateRoute exact path="/calendar" element={<CalendarPage />} />
    <PrivateRoute exact path="/profile" element={<AccountSettings />} />
    <PrivateRoute exact path="/ManageStadium" element={<ManageStadiumPage />} />
    <PrivateRoute exact path="/CouponDiscount" element={<CouponDiscount />} />
    <PrivateRoute exact path="/billing" element={<BillingPage />} />
    <PrivateRoute exact path="/coaches" element={<CoachesPage />} />
    <PrivateRoute exact path="/AddCoach" element={<AddCoaches />} />
    <PrivateRoute exact path="/editCoach" element={<EditCoach />} />
    <PrivateRoute exact path="/availability" element={<Availability />} />
    <PrivateRoute exact path="/packages" element={<PackagesPage />} />
    <PrivateRoute exact path="/loyality-program" element={<LoyalityProgram />} />
    <PrivateRoute exact path="/usage" element={<Usage />} />
    <PrivateRoute exact path="/EditDetails" element={<EditCoach />} />
    <PrivateRoute exact path="/Malaeb-Live/" element={<VideosPage />} />





    {/* General Routes */}
    <Route path="*" component={Error404} />
  </Routes>
);
export default myRoutes;
