import { api } from 'services/api'
import fetchUser from './me'

const useApi = api.injectEndpoints({
  endpoints: build => ({
    fetchUser: fetchUser(build),
  }),
  overrideExisting: false,
})

export const {
  useFetchUserMutation,
} = useApi
