import { api } from 'services/api'
import fetchCalendar from './calendar'

const useApi = api.injectEndpoints({
  endpoints: build => ({
    fetchCalendar: fetchCalendar(build),
  }),
  overrideExisting: false,
})

export const {
  useFetchCalendarMutation
} = useApi
