import LocalizedStrings from 'react-localization';

const Locale = new LocalizedStrings({
    en:{
        Error_Title: "Opps",
        Error_Content: "The page you are looking for might have been moved, renamed, or might never exist",
    },
    ar: {
        Error_Title: "اوبس",
        Error_Content: "ربما تم نقل الصفحة التي تبحث عنها أو إعادة تسميتها أو ربما لم تكن موجودة على الإطلاق",
    }
});

export default Locale;