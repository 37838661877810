import { Stadium } from "services/user/me"
import { Coach, CoachesState, Package } from "./props"

const setCoach = (
    state: CoachesState,
    payload: { payload: Coach },
) => {
    state.coach = {
        ...state.coach,
        ...payload.payload,
    }
}

const setPackage = (
    state: CoachesState,
    payload: { payload: Package },
) => {
    state.package = {
        ...state.package,
        ...payload.payload,
    }
}

export default {
    setCoach,
    setPackage,
  
}
