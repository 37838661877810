import * as yup from 'yup'
import Locale from 'translations';
const { validationErrorMessages } = Locale;

export const validateCreateAddons = yup.object({
    name: yup
        .string()
        .trim()
        .min(2, validationErrorMessages.name.min)
        .required(validationErrorMessages.name.required),
    cost: yup
        .number().typeError(validationErrorMessages.cost.type)
        .required(validationErrorMessages.cost.required),
})