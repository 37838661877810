import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
export default (build: EndpointBuilder<any, any, any>) =>
    build.mutation<Response, Body>({
        query: body => ({
            url: `/v4/pitch-owner-dashboard/stadiums/${body.stadiumId}/pitches`,
            method: 'Get',
        }),
    })

interface Body {
    stadiumId: number | undefined
}
