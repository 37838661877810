import { RootState } from 'Store';
import { CalendarSliceActions } from 'Store/Calendar';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyFetchPhoneCodesQuery } from 'services/fetchPhoneCodes';

export default function useFetchPhoneCodes() {
  const [phoneCodesReq, phoneCodesRes] = useLazyFetchPhoneCodesQuery()
  const phoneCodes = useSelector((state: RootState) => state?.calendar?.phoneCodes)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!phoneCodes)
      phoneCodesReq({})
        .then((data: any) => {
          dispatch(CalendarSliceActions?.setPhoneCodes(data?.data?.data))
        })
  }, []);

  return [phoneCodes];
}
