import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
export default (build: EndpointBuilder<any, any, any>) =>
    build.query<Response, body>({
        query: body => ({
            url: `/v3/pitch-owner-dashboard/zoho/print/${body.id}`,
            method: 'GET',
            
        }),
    })

export interface body {
    id: number | undefined
}
