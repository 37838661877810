import State from './state';
import Reducer from './reducer'
import { createSlice } from '@reduxjs/toolkit'
import { LoyaltyState } from './props'

const slice = createSlice({
  name: 'loyaltyState',
  initialState: State as LoyaltyState,
  reducers: Reducer,
})

export const loyaltySliceActions = slice.actions

export default slice.reducer
