import { api } from 'services/api'
import createSingleBooking from './createSingleBooking'

const useApi = api.injectEndpoints({
    endpoints: build => ({
        createSingleBooking: createSingleBooking(build),
    }),
    overrideExisting: false,
})

export const {
    useCreateSingleBookingMutation
} = useApi
