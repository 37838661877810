import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import { BookingState } from 'Store/Calendar/props';

export default function useResetBookingState() {
    const pitch_id = useSelector((state: RootState) => state.calendar.pitchId)
    const pitch = useSelector((state: RootState) => state.calendar.pitch)
    const selectedStadiumId = useSelector((state: RootState) => state.calendar.selectedStadium?.id)
    const [bookingAfterReset, setBooking] = useState<BookingState>();

    useEffect(() => {
        if (selectedStadiumId && pitch && pitch_id) {
            setBooking({
                amount_paid: 0,
                booking_name: "",
                booking_note: "",
                booking_phone_number: "",
                booking_status: 0,
                booking_status_label: "",
                booking_type: 2,
                coupon_code: "",
                coupon_discount: 0,
                end: "",
                fixed_booking: {},
                id: 0,
                is_single_booking: true,
                match_date: "",
                match_end_time: "",
                match_time: "",
                net_amount: 0,
                no_show: false,
                payment_actual_amount: 0,
                payment_currency: "",
                payment_expected_amount: 0,
                payment_method: null,
                payment_method_label: "",
                payment_order_duration: 60,
                payment_paid_status: 0,
                pitch: pitch,
                pitch_id: pitch_id,
                resourceId: 0,
                stadium_id: selectedStadiumId,
                start: "",
                total_addons_amount: 0,
                user_id: "",
                coach: null,
                is_coaching_booking: false,
                is_issued_invoice: false
            })
        }
    }, [selectedStadiumId, pitch, pitch_id]);
    return [bookingAfterReset];
}
