import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
export default (build: EndpointBuilder<any, any, any>) =>
build.query<Response, any>({
    query: () => '/v4/pitch-owner-dashboard/stadiums',
})

interface Response {
    data: Stadium[]
}

interface Stadium {
    id: number,
    title: string,
    title_ar: string
}