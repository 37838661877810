
import { getAnalytics, logEvent } from "firebase/analytics";
import { analytics } from "./FirebaseConfig";

export enum AnalyticsKeys {
  FirstOpen = 'MalaebBusiness_First_open',
  signin = 'MalaebBusiness_Sign_in',

  // .. Calendar page

  SchedulePageView = 'MalaebBusiness_Schedule_page_view',
  FixedBookingViewed = 'MalaebBusiness_Fixed_booking_page view',
  CalendarTabViewed = 'MalaebBusiness_calendar_tab_view',
  MarkAsPaid = 'MalaebBusiness_mark_as_paid_button',
  AddonsBtn = 'MalaebBusiness_addons_button',
  CancelBooking = 'MalaebBusiness_cancel_booking_button',
  EditBooking = 'MalaebBusiness_edit_booking_button',

  // .. Manage stadium page
  ManageStadiumViewed = 'MalaebBusiness_Manage_stadium_Page view',
  AddNewPitchClicked = 'MalaebBusiness_Add_new_pitch_clicks',
  AddDayOffClicked = 'MalaebBusiness_add_day_off_clicks',
  StadiumImagesViewed = 'MalaebBusiness_Stadium_images_tab_view',

  // .. Account Settings page
  AccountPageViewed = 'MalaebBusiness_Account_settings_page_view',

  // .. Loyality program page
  LoyalityProgramPageViewed = 'MalaebBusiness_Loyalyty_program_page_view',
  AddNewRuleBtnClicked = 'MalaebBusiness_Add_new_rule_button_click',
  DeactivateBtn = 'MalaebBusiness_Deactivatebuttonclick',
 InActiveRewardsTapViewed = 'MalaebBusiness_inactive_rewards_tab_view',

  // .. Coupons and discounts page
  CouponsAndDiscountsPageViewed = 'MalaebBusiness_Coupons_and_discounts_page_view',
  CreateNewBtn = 'MalaebBusiness_Create_new_button',
  EditBtn = 'MalaebBusiness_Edit_button',
  DeleteBtn = 'MalaebBusiness_delete_button',
  DiscountTapViewed = 'MalaebBusiness_discounts_tab_view',
  createNewDiscount = 'MalaebBusiness_create_new_discount_button',

  // .. Billing page
  BillingPageViewed = 'MalaebBusiness_Billing_page_view',

  // .. Coaches page
  CoachesPageViewed = 'MalaebBusiness_Coaches_page_view',
  AddCoachBtn = 'MalaebBusiness_add_new_coach_button',
  CustomerPageViewed = 'MalaebBusiness_customers_page_view',

}

export const firebaseAnalytics = (value) => {
  logEvent(analytics, value)
}
