import { api } from 'services/api'
import getDaysOffOfStadium from './getDaysOffOfStadium'

const useApi = api.injectEndpoints({
  endpoints: build => ({
    getDaysOffOfStadium: getDaysOffOfStadium(build),
  }),
  overrideExisting: false,
})

export const {
  useGetDaysOffOfStadiumMutation
} = useApi
