import { api } from 'services/api'
import AddAddon from './AddAddon'

const useApi = api.injectEndpoints({
    endpoints: build => ({
        AddAddon: AddAddon(build),
    }),
    overrideExisting: false,
})

export const {
    useAddAddonMutation
} = useApi
