import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'

export default (build: EndpointBuilder<any, any, any>) =>
    build.mutation<Response, Body>({
        query: body => ({
            url: `/v3/pitch-owner-dashboard/calendar`,
            method: 'POST',
            body,
        }),
    })



export interface Body {
    from_date: string,
    to_date: string,
    pitches: number[],
}
