import React from 'react'
import { Views } from "react-big-calendar";
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import { CoachEvent } from 'Pages/Coaches/components/calendarCoach/CoachEvent';
import CustomEventWeekView from './CustomEventWeekView';
import CustomEventDayView from './CustomEventDayView';
import { BookingState } from 'Store/Calendar/props';

export interface ItemOfCalendarEvents {
    item: {
        event: BookingState,
        title: string
    }
}
export const CustomEventFunction = (props: ItemOfCalendarEvents) => {
    const currentView = useSelector((state: RootState) => state.calendar.currentView)
    return (<>
        {
            props?.item?.event?.is_coaching_booking  ?
                <>
                    <CoachEvent item={props?.item} />
                </>
                : Views.WEEK === currentView ?

                    <CustomEventWeekView item={props?.item} />

                    :
                    <CustomEventDayView item={props?.item} />
        }
    </>);
};