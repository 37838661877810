import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { PriceInCoach, Range } from 'Store/Coaches/props'

export default (build: EndpointBuilder<any, any, any>) =>
    build.mutation<Response, data>({
        query: body => ({
            url: `/v4/pitch-owner-app/stadiums/${body.stadiumId}/addons`,
            method: 'POST',
            body,
        }),
    })

interface data {
    stadiumId: number | string
    cost: string
    name: string
}