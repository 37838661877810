import React, { useState } from 'react';
import Locale from 'translations';

/**
 * @Component PasswordField
 * @Description This component for the password input
 * @Props
 *    -> label: Text label for input field
 *    -> placeholder: Input field placeholder
 *    -> className: HTML classes for input field
 *    -> id: HTML ID
 *    -> color: Bootstrap color
 *    -> value: Input value
 *    -> onBlur: Input Function
 *    -> onChange: Input Function
 */

const PasswordField = (props) => {
  const { commons } = Locale;

  const [showPassword, setShowPassword] = useState(false);
//   const generateErrorsMsgs = (errors) => {
//     let message = [];
//     if (errors) {
//       for (const [k, v] of Object.entries(errors)) {
//         if (k === 'required' && v) {
//           message.push(commons.isRequired);
//         } else if (k === 'min' && v) {
//           message.push(` should be at least ${props.min} digits`);
//         } else if (k === 'password' && v) {
//           message.push(commons.ShouldMatch);
//         } else if (k === 'confirm' && v) {
//           message.push(commons.ShouldMatchPassword);
//         }
//       }
//       if (message.length === 1) {
//         return `${props.label} ${message[0]}`;
//       } else if (message.length > 1) {
//         return `${props.label} ${message.join(' & ')}`;
//       } else {
//         return;
//       }
//     }
//   };
  return (
    <>
      <div
        className={`my-2 control-field + ${
          props.color ? ' control-field--' + props.color : ''
        }`}
      >
        {/* Text Field Body */}
        <div className="control-field__body">
          {/* Label */}
          {props.label ? (
            <label
              className={`control-field__label ${
                props.color ? ' control-field__label--' + props.color : ''
              }`}
            >
              {props.label}
            </label>
          ) : null}

          {/* Password Input */}
          <div className="row no-gutters justify-content-between">
            <input
              type={showPassword ? 'text' : 'password'}
              name={props.name}
              className={(props.className ?? '') + ' control-field__input'}
              value={props.value}
              onChange={props.onChange}
              min={props.min}
              max={props.max}
              // onBlur={props.onBlur}
              placeholder={props.placeholder}
              // onKeyDown={props.onEnter}
              autoComplete="off"
            />

            {/* Show/Hide Password */}
            <span
              className={
                showPassword
                  ? 'fa-solid fa-eye-slash text-16 pointer text-gray-300'
                  : 'fas fa-eye text-16 pointer text-gray-300'
              }
              onClick={() => setShowPassword(!showPassword)}
            ></span>
          </div>
        </div>
      </div>

      {/* Feedback */}
      <small
        className={`text-caption control-field__feedback control-field__feedback--${
          props.color
        } ${props.color ? 'd-block' : 'd-none'} error-message`}
      >
        {/* {generateErrorsMsgs(props.errors)} */}
      </small>
    </>
  );
};

PasswordField.defaultProps = {
	label: true,
};

export default PasswordField;
