import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import Locale from 'translations';
import Loader from 'components/LoadingPage/LoadingPage';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import { useGetAddonsMutation } from 'services/getAddons';
import { AddonObj } from 'Store/Calendar/props';
import { AddonDetails } from 'services/SendAddons/addons';
import { CreateAddons, AddonsList, NoAddons } from '.';
import { Button, Modal, ModalHeader } from 'reactstrap';
import { CalendarSliceActions } from 'Store/Calendar';
import { useAddNotification } from '../Helpers/addNotification';
import { useLazyGetBookingQuery } from 'services/getBooking';

interface AddonsModaProps {
  addonsModalIsOpened: boolean
  setAddonsModalIsOpened: Dispatch<SetStateAction<boolean>>
}

const AddonsModal = (props: AddonsModaProps) => {
  const dispatch = useDispatch()
  const defaultSelectedStadium = useSelector((state: RootState) => state.calendar?.selectedStadium)
  const addonInBookingState = useSelector((state: RootState) => state.calendar?.BookingState?.addons)
  const bookingState = useSelector((state: RootState) => state?.calendar?.BookingState)
  const { addonsPage, messages } = Locale
  const [addonList, setAddonList] = useState<AddonObj[]>([]);
  const [addonDetailsList, setAddonDetailsList] = useState<AddonDetails[]>([]);
  const [createAddonIsOpened, setCreateAddonIsOpened] = useState<boolean>(false)
  const [isModalCreate, setIsModalAdd] = useState<boolean>(true)
  const [RequestGetAddons, ResponseGetAddons] = useGetAddonsMutation()
  const [getBookingDetailsRequest, getBookingDetailsResponse] = useLazyGetBookingQuery()

  const getBookingDetails = () => {
    getBookingDetailsRequest({ id: bookingState?.id }).then((data: any) => {
      if (data?.error) {
        useAddNotification(data?.error?.data?.message, messages.warning, "danger")
      }
      else {
        dispatch(CalendarSliceActions?.setBookingState(data?.data?.data))

      }
    }).catch((err) =>
      useAddNotification(messages?.errorMsg, messages.success, "success")
    )
  }

  const toggleAddonsModal = () => {
    props?.setAddonsModalIsOpened(!props?.addonsModalIsOpened)
    getBookingDetails()
  }


  const getAddonsData = () => {
    if (defaultSelectedStadium) {
      let stadiumid = defaultSelectedStadium.id;
      RequestGetAddons({ id: stadiumid }).then((data: any) => {
        const result = data?.data?.data;
        setAddonList(result);
      }).catch((error) => console.log(error))
    }
  }

  useEffect(() => {
    getAddonsData()
  }, []);


  useEffect(() => {
    if (addonList?.length) {
      let newAddonsArr: AddonDetails[] = [];
      (addonList?.map((addon) => {
        newAddonsArr?.push({
          addon_id: addon?.id,
          count: getCountOfAddon(addon) ? getCountOfAddon(addon) : 0,
          total_price: getCountOfAddon(addon) ? getCountOfAddon(addon) * Number(addon?.cost) : 0
        })
        return newAddonsArr
      }))
      setAddonDetailsList([...newAddonsArr])
    }
  }, [addonList])


  const goToCreate = (modalStatus: boolean) => {
    setCreateAddonIsOpened(true)
    setIsModalAdd(modalStatus)
  }

  const getCountOfAddon = (addon) => {
    const myAddon = addonInBookingState?.find((item) => item?.id == addon?.id)
    return myAddon?.count
  }

  return (
    <>
      <Modal className='addonsModule'
        isOpen={props?.addonsModalIsOpened}
        toggle={toggleAddonsModal}
        size="md"
      >
        <ModalHeader className='w-100 align-items-center pt-4 pb-5 d-flex justify-content-center position-relative flex-column'>
          <p className='title'>{addonsPage?.addons}</p>
          <i className="fa-solid fa-circle-arrow-left text-lemon fa-2xl position-absolute cursor-pointer"
            onClick={toggleAddonsModal}></i>
          <Button color='default' className=' createAddonBtn position-absolute'
            onClick={() => goToCreate(true)}
          >{addonsPage?.create}</Button>
        </ModalHeader>
        {addonList?.length ?
          <AddonsList addonList={addonList} setAddonList={setAddonList} toggleAddonsModal={toggleAddonsModal}
            addonDetailsList={addonDetailsList} setAddonDetailsList={setAddonDetailsList}
            goToCreate={goToCreate} addonsModalIsOpened={props?.addonsModalIsOpened} />
          : <NoAddons
            goToCreate={goToCreate}
          />}

      </Modal>
      {ResponseGetAddons?.isLoading ? <Loader /> : null}
      {createAddonIsOpened ? <CreateAddons getAddonsData={getAddonsData} createAddonIsOpened={createAddonIsOpened} setCreateAddonIsOpened={setCreateAddonIsOpened}
        setAddonsModalIsOpened={props?.setAddonsModalIsOpened} isModalCreate={isModalCreate} />
        : null
      }
      {getBookingDetailsResponse?.isLoading ? <Loader /> : null}
    </>
  );
};
export default AddonsModal;
