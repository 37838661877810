import React, { Dispatch, SetStateAction, useEffect } from 'react'
import Locale from 'translations'
import Loader from 'components/LoadingPage/LoadingPage'
import { TextField } from 'components/Form'
import { useFormik } from 'formik'
import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap'
import { validateCreateAddons } from '../validation/validateCreateAddons'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { useAddAddonMutation } from 'services/AddAddon'
import { useAddNotification } from '../Helpers/addNotification'
import { useUpdateAddonMutation } from 'services/updateAddon'

interface AddAddonsProps {
    setCreateAddonIsOpened: Dispatch<SetStateAction<boolean>>
    setAddonsModalIsOpened: Dispatch<SetStateAction<boolean>>
    createAddonIsOpened: boolean
    isModalCreate: boolean
    getAddonsData: VoidFunction
}

const CreateAddons = (props: AddAddonsProps) => {
    const stadium = useSelector((state: RootState) => state.calendar?.selectedStadium)
    const addonToEdit = useSelector((state: RootState) => state.calendar?.addonObj)
    const { addonsPage, messages } = Locale
    const [requestAddAddon, responseAddAddon] = useAddAddonMutation()
    const [requestUpdateAddon, responseUpdateAddon] = useUpdateAddonMutation()

    const toggleCreateModal = () => {
        props?.setCreateAddonIsOpened(!props.createAddonIsOpened)
    }

    const onSubmit = () => {
        if (stadium?.id) {
            if (props?.isModalCreate) {
                requestAddAddon({
                    stadiumId: stadium?.id,
                    ...values
                }).then((data: any) => {
                    if (data?.error) {
                        useAddNotification(data?.error?.data?.message, messages.warning, "danger")
                    }
                    else {
                        useAddNotification(addonsPage?.added, messages.success, "success")
                        goBack()
                    }
                }).catch((err) =>
                    useAddNotification(messages?.errorMsg, messages.success, "success")
                )
            }
            else {
                if (addonToEdit) {
                    requestUpdateAddon({
                        stadiumId: stadium?.id,
                        addon_id: addonToEdit?.id,
                        ...values
                    }).then((data: any) => {
                        if (data?.error) {
                            useAddNotification(data?.error?.data?.message, messages.warning, "danger")
                        }
                        else {
                            useAddNotification(addonsPage?.edited, messages.success, "success")
                            goBack()
                        }
                    }).catch((err) =>
                        useAddNotification(messages?.errorMsg, messages.success, "success")
                    )
                }
            }
        }
    }

    const goBack = () => {
        toggleCreateModal()
        props?.setAddonsModalIsOpened(true)
        props?.getAddonsData()
    }

    const {
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        errors,
        setValues,
        touched,
    } = useFormik({
        initialValues: {
            name: "",
            cost: ""
        },
        onSubmit,
        validateOnChange: true,
        validationSchema: validateCreateAddons,
    })

    useEffect(() => {
        if (!props?.isModalCreate) {
            if (addonToEdit)
                setValues({
                    name: addonToEdit?.name,
                    cost: addonToEdit?.cost
                })
        }
    }, [])

    return (
        <>
            <Modal className='addonsModule'
                isOpen={props?.createAddonIsOpened}
                toggle={toggleCreateModal}
                size="lg"
            >
                <ModalHeader className='w-100 align-items-center mx-auto pt-4 pb-5 d-flex justify-content-center position-relative'>
                    <p className='title'>{addonsPage?.newAddons}</p>
                    <i className="fa-solid fa-circle-arrow-left text-lemon fa-2xl position-absolute cursor-pointer" onClick={goBack}></i>
                </ModalHeader>
                <ModalBody className='d-flex align-items-center flex-column AddModalBody'>
                    <form className='w-100 addAddOnForm' onSubmit={handleSubmit}>
                        <div className='mb-4'>
                            <TextField
                                error={touched.name && errors.name ? errors.name : ""}
                                placeholder="Add On Name"
                                label={addonsPage?.name}
                                value={values.name}
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}

                            />
                        </div>
                        <div className='mb-5 pb-4'>
                            <TextField
                                error={touched.cost && errors.cost ? errors.cost : ""}
                                placeholder="0"
                                label={addonsPage?.Price}
                                value={values.cost}
                                name="cost"
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </div>
                        <Button color='default' className='w-100 mt-5' type='submit'
                        >{props?.isModalCreate ? addonsPage?.create : "Edit Add On"}</Button>
                    </form>
                </ModalBody>
            </Modal>
            {responseAddAddon?.isLoading ? <Loader /> : null}
        </>
    )
}

export default CreateAddons