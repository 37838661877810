import { RootState } from 'Store';
import { CalendarSliceActions } from 'Store/Calendar';
import { useMalaebState } from 'context/global';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Pitch } from 'services/user/me';

const PitchSelect = (props: { customPitch?: string | null }) => {
    const { locale } = useMalaebState();
    const dispatch = useDispatch()
    const pitchId = useSelector((state: RootState) => state.calendar.pitchId)
    const BookingState = useSelector((state: RootState) => state.calendar.BookingState)
    const pitchesforStadium = useSelector((state: RootState) => state.calendar.pitchesforStadium)
    const handlePitch = (value: number) => {
        dispatch(CalendarSliceActions.setPitchId(Number(value))) // current pitch id
        dispatch(CalendarSliceActions.setPitch(pitchesforStadium?.find((item: Pitch) => item?.id == value))) // current pitch
        dispatch(CalendarSliceActions.setBookingState({
            ...BookingState,
            pitch_id: value
        }))
    };

    return (
        <select
            value={pitchId}
            onChange={(e) => handlePitch(Number(e.target.value))}
        >
            {props?.customPitch ? <option key={0} value={0}>{props?.customPitch}</option> : null}
            {pitchesforStadium ? pitchesforStadium?.map((item) => {
                return <option key={item.id} value={item.id}>
                    {locale == "en" ? item.lable : item?.lable_ar}
                </option>
            }) : ""}

        </select>

    )
}

export default PitchSelect