const cash = require('assets/images/cash.png');
const tap = require('assets/images/tap.png');
const wallet = require('assets/images/wallet-2 18.png');
const circleImg = require('assets/images/Ellipse 346.png');
const circleImgGreen = require('assets/images/Ellipse 346 green.png');
const camera = require('assets/images/camera.png');
const watermark = require('assets/images/greenLogotype.png');
const play = require('assets/images/play-video.png');
const schedule = require('assets/images/schedule.png');
const reporting = require('assets/images/reporting.png');
const manageStadium = require('assets/images/manageStadium.png');
const coach = require('assets/images/coach.png');
const settingsAccount = require('assets/images/settingsAccount.png');
const loyality = require('assets/images/loyality.png');
const coupons = require('assets/images/coupons.png');
const billing = require('assets/images/billing.png');
const malaebLive = require('assets/images/malaebLive.png');
const Loadinglogo = require('assets/images/logo.png');
const logout = require('assets/images/logout.png');
const upload = require('assets/images/upload.png');
const imageStadium = require('assets/images/imageStadium.png');



const images = {
    sideNav: {
        schedule,
        reporting,
        manageStadium,
        coach,
        settingsAccount,
        loyality,
        coupons,
        billing,
        malaebLive,
        logout
    },
    paymentIconImg: {
        cash,
        tap,
        wallet,
    },
    coaches: {
        circleImg,
        camera,
        circleImgGreen
    },
    malaebLive: {
        watermark,
        play,
        thumbnial: require('assets/images/videoImg.png'),
        step3: require('assets/images/step3.png'),
        step4: require('assets/images/step4.png'),
        step5: require('assets/images/step5.png'),
    },
    logo: Loadinglogo,
    stadiumManagement: upload,
    stadiumImg:imageStadium

}
export default images