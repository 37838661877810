import React from 'react'
import images from 'Pages/CalendarPage/Helpers/images';
import Locale from 'translations';
import logout from './Helpers/logout';
import { RootState } from 'Store';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { userRoleEnum } from 'Pages/Coaches/enums/userRolesEnum';
import { Link } from 'react-router-dom';
import { useMalaebDispatch, useMalaebState } from 'context/global';
import useGetMenuData from './Helpers/useGetMenuData';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';

const SidebarLayout = () => {
    const location = useLocation();
    const userRole = useSelector((state: RootState) => state.calendar?.userRole)
    const userData = useSelector((state: RootState) => state.calendar?.user)
    const dispatch: any = useMalaebDispatch();
    const dispatchReact = useDispatch()
    const navigate = useNavigate();
    const { userLogo, APP_ID, userInfo } = useMalaebState();
    const { profile } = Locale;
    const { allMenu } = useGetMenuData()

    return (
        <nav id="sidebarMenu" className=" sidebar ">
            <div className="position-sticky">
                <div className='languageSelection mx-3'>
                    <p className='font-12 text-darkgrey'>{profile?.selectLang}</p>
                    <LanguageSwitcher />

                </div>
                <div className='d-flex flex-columns align-items-center pt-4'>
                    <img className='user-img' src={userLogo ? userLogo : userData?.image_url} />
                    <p className='gray-500 text-title5 font-normal pt-3'>{userData?.name}</p>
                </div>
                <div className='listOfItems mt-4 pt-1'>
                    {userRole !== userRoleEnum?.coach ?
                        (allMenu?.menuItems)?.map((item, index) =>
                            <Link key={index} to={item?.route} className={`list-item mx-2 py-2 d-flex align-items-center mt-2 ${location?.pathname == item?.route ? "active-list-item" : ""}`}>
                                <div className='iconDiv ml-2'>
                                    <img className='iconImg' src={item?.icon} />
                                </div>
                                <p className='list-item-text font14 gray-500 font-normal'>{item?.label}</p>
                            </Link>

                        ) :
                        allMenu?.coachMenuItems.map((item, index) =>
                            <Link key={index} to={item?.route} className={`list-item mx-2 py-2 d-flex align-items-center mt-2 ${location?.pathname == item?.route ? "active-list-item" : ""}`}>
                                <div className='iconDiv ml-2'>
                                    <img className='iconImg' src={item?.icon} />
                                </div>
                                <p className='list-item-text font14 gray-500 font-normal'>{item?.label}</p>
                            </Link>

                        )}

                    <div className=" logout mx-2 py-2 d-flex align-items-center mt-2" onClick={() => logout({ dispatch: dispatch, dispatchReact: dispatchReact, APP_ID: APP_ID, navigate })}>
                        <img className=' iconImg' src={images?.sideNav?.logout} />
                        <p className='list-item-text font14 gray-500 font-normal'>{profile?.logout}</p>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default SidebarLayout