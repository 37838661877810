import { useAddNotification } from 'Pages/CalendarPage/Helpers/addNotification'
import { userRoleEnum } from 'Pages/Coaches/enums/userRolesEnum'
import { useMalaebState } from 'context/global'
import { useLazyGetStadiumsQuery } from 'services/getStadiums'
import { useGetStadiumsOfCoachUserMutation } from 'services/getStadiumsOfCoachUser'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { CalendarSliceActions } from 'Store/Calendar'
import { UserData } from '../../Store/Calendar/props'
import React, { useEffect } from 'react'
import Locale from 'translations'
import Loader from 'components/LoadingPage/LoadingPage'

const Main = () => {
  const userRole = useSelector((state: RootState) => state.calendar?.userRole)
  const userData = useSelector((state: RootState) => state.calendar?.user)
  const stadiumsList = useSelector(
    (state: RootState) => state.calendar?.stadiumsList,
  )
  const dispatch = useDispatch()
  const { isAuth, userInfo }: { isAuth: boolean; userInfo: UserData } =
    useMalaebState()
  const { messages, commons } = Locale
  const [requestStadiums, responseStadiums] = useLazyGetStadiumsQuery()
  const [getStadiumForCoachRequest, getStadiumForCoachResponse] =
    useGetStadiumsOfCoachUserMutation()

  useEffect(() => {
    if (isAuth && stadiumsList) {
      if (stadiumsList?.length == 0) {
        if (userRole !== userRoleEnum?.coach) {
          requestStadiums({})
            .then((data: any) => {
              if (data?.error?.status === 401) {
                dispatch(CalendarSliceActions.setIsAuth(false))

                localStorage.removeItem('isAuth')
                localStorage.removeItem('token')
                localStorage.removeItem('userInfo')

                //show an alert to the user
                alert(commons?.sessionExpired)

                window.location.href = '/auth/login'

                return
              }

              const myStadiumsArr = data?.data?.data
              if (Array.isArray(myStadiumsArr))
                dispatch(CalendarSliceActions?.setStadiumsList(myStadiumsArr))
            })
            .catch(err => {
              useAddNotification(messages.errorMsg, messages?.Error, 'danger')
            })
        } else {
          getStadiumForCoachRequest({ coachId: userData?.coach })
            .then((data: any) => {
              const myStadiumsArr = data?.data?.data
              if (Array.isArray(myStadiumsArr))
                dispatch(CalendarSliceActions?.setStadiumsList(myStadiumsArr))
            })
            .catch(() => {
              useAddNotification(messages.errorMsg, messages?.Error, 'danger')
            })
        }
      }
    }
    if (userData == undefined) {
      dispatch(CalendarSliceActions?.setUserInfo(userInfo))
      dispatch(CalendarSliceActions.setUserRole(userInfo?.roles?.[0]))
      dispatch(CalendarSliceActions.setIsAuth(true))
    }
  }, [isAuth])
  useEffect(() => {}, [userData])

  return (
    <>
      {responseStadiums?.isLoading || getStadiumForCoachResponse?.isLoading ? (
        <Loader />
      ) : null}
    </>
  )
}

export default Main
