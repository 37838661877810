import { paymentMethodEnum } from "../Enums/PaymentMethodEnum";
import images from "./images";


export const getBookingPaymentImage = (booking) => {
    if (booking?.is_use_credit) {
        // .. booking fully paid with wallet  

        if (booking?.is_use_credit && ((booking?.final_price - booking?.amount_paid) > 0 && booking?.payment_method === paymentMethodEnum?.cash)) {
            // .. booking partial paid with wallet + user will pay cash
            return images.paymentIconImg.cash
        }
        return images.paymentIconImg.wallet
    }
    if (booking?.payment_method === paymentMethodEnum?.applePay || booking?.payment_method === paymentMethodEnum?.tab) {
        return images.paymentIconImg.tap
    }

    if (booking?.payment_method === paymentMethodEnum?.cash) {
        return images.paymentIconImg.cash
    }



}