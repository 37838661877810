import React, { createContext, useContext, useReducer } from "react";

import malaebReducer from "./reducers/malaebReducer";
const MalaebContext = createContext(undefined);
const MalaebDispatchContext = createContext(undefined);
const currentLocale = localStorage.getItem("currentLocale");

const initialState = {
	locale: currentLocale ?? "en",
	APP_ID: "xtwgqd0w",
	isAuth: localStorage.getItem("isAuth") ?? false,
	token: localStorage.getItem("token") ?? null,
	userInfo: JSON.parse(localStorage.getItem("userInfo")) ?? null,
	userLogo: null,
	defaultStadium: null,
	userPermissions: JSON.parse(localStorage.getItem("userInfo")) ? JSON.parse(localStorage.getItem("userInfo")).permissions : null,
};

function useMalaebState() {
	const context = useContext(MalaebContext);
	if (context === undefined) {
		throw new Error("useMalaebState must be used within a MalaebProvider");
	}
	return context;
}

function useMalaebDispatch() {
	const context = useContext(MalaebDispatchContext);
	if (context === undefined) {
		throw new Error(
			"useMalaebDispatch must be used within a MalaebProvider"
		);
	}
	return context;
}

function MalaebProvider({ children }) {
	const [state, dispatch] = useReducer(malaebReducer, initialState);
	return (
		<MalaebContext.Provider value={state}>
			<MalaebDispatchContext.Provider value={dispatch}>
				{children}
			</MalaebDispatchContext.Provider>
		</MalaebContext.Provider>
	);
}

export { MalaebProvider, useMalaebState, useMalaebDispatch };