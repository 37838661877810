import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { CalendarSliceActions } from 'Store/Calendar';
import { RootState } from 'Store/index';
import Locale from 'translations';

export const AskToEdit = ({ askToEdit, setasoToEdit, toggleModalView }) => {
    const BookingState = useSelector((state: RootState) => state.calendar.BookingState)
    const dispatch = useDispatch()
    const { calendarPage } = Locale
    const toggleAskToEdit = () => {
        setasoToEdit(!askToEdit)
    }
    const handleOpenEditModal = (isSingle: boolean) => {
        if (isSingle) {
            dispatch(CalendarSliceActions.setBookingState({
                ...BookingState,
                is_single_booking: true,
            }))
        }
        else {
            dispatch(CalendarSliceActions.setBookingState({
                ...BookingState,
                is_single_booking: false,
            }))
        }
        dispatch(CalendarSliceActions.setBookingModalIsOpened(true))
        toggleAskToEdit()
        toggleModalView()
    }

    return (
        <>
            <Modal
                isOpen={askToEdit}
                toggle={toggleAskToEdit}
                size="md"
                className="create-booking view-booking"
            >
                <ModalHeader
                    className="font-weight-bold"
                    toggle={toggleAskToEdit}
                ></ModalHeader>
                <ModalBody className="py-0 text-center">
                    <h5 className="py-3"> {calendarPage?.EditQues}</h5>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary"
                        onClick={() => handleOpenEditModal(true)} >
                        {calendarPage?.onlyThisBooking}  {/* single booking   */}
                    </Button>
                    <Button color="secondary"
                        onClick={() => handleOpenEditModal(false)} >
                        {calendarPage?.entire}  {/* fixed booking   */}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}
