import React from "react";
import { Route, Link } from "react-router-dom";
import { AuthProvider } from "context/auth";
import { useMalaebState } from "context/global";

// React Component
export default function AuthRoute({ component: Component, ...props }) {
	const { isAuth } = useMalaebState();

	if (!isAuth) {
		return (
			<Route
				{...props}
				render={(matchProps) => (
					<AuthProvider>
						<Component {...matchProps} />
					</AuthProvider>
				)}
			/>
		);
	} else {
		return <Link to="/" />;
	}
}
