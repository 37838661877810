import { getLang } from "components/LanguageSwitcher/LanguageSwitcher.locale"

export const getLangTitle = (
    data,
    key: string
) => {
    const lang = getLang()

    if (lang === "ar") {
        return data[`${key}_ar`]
    }

    return data[key]

}

