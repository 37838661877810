import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'

export default (build: EndpointBuilder<any, any, any>) =>
    build.mutation<Response, AddonsBody>({
        query: body => ({
            url: `/v4/pitch-owner-dashboard/booking/${body.id}/syncAddon`,
            method: 'POST',
            body,
        }),
    })

interface AddonsBody {
    addons: AddonDetails[],
    id: number,
}

export interface AddonDetails {
    addon_id: number|undefined,
    count: number,
    total_price: number,
}