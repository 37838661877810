import React, { useState } from 'react';


export default function DashboardLayout(props) {

  return (
    <div className="">
      <div className=''>
        {props.children}
      </div>
    </div>
  );
}
