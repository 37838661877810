import State from './state'
import Reducer from './reducer'

import { createSlice } from '@reduxjs/toolkit'
import { CoachCalendarState } from './props'

const slice = createSlice({
  name: 'coachCalendar',
  initialState: State as CoachCalendarState,
  reducers: Reducer,
})

export const  CoachCalendarSliceActions = slice.actions

export default slice.reducer
