import { api } from 'services/api'
import getSlotInfo from './getSlotInfo'

const useApi = api.injectEndpoints({
    endpoints: build => ({
        getSlotInfo: getSlotInfo(build),
    }),
    overrideExisting: false,
})

export const {
    useGetSlotInfoMutation
} = useApi
