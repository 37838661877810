import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { Moment } from 'moment'

export default (build: EndpointBuilder<any, any, any>) =>
    build.mutation<Response, data>({
        query: body => ({
            url: `/v3/pitch-owner-dashboard/bookings`,
            method: 'POST',
            body,
        }),
    })
// fixed-bookings
interface data {
    amount_paid: number,
    booking_name: string,
    booking_note: string,
    booking_type?: number,
    duration: number,
    last_match_date: string | Date | undefined,
    day_of_week: number
    match_date: string | Moment,
    match_time: string,
    payment_actual_amount: number,
    phone_number: string,
    pitch_id: number,
    user_id?: number | null,
    payment_method?: number
}