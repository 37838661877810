import React, { useEffect, useState } from "react";
import moment from 'moment';
import Loader from "components/LoadingPage/LoadingPage";
import { HeaderSection, ViewBookingModal, NewBookingModals, CalendarSection } from './Components'
import { useDispatch, useSelector } from "react-redux";
import { CalendarSliceActions } from "Store/Calendar";
import { BookingState } from 'Store/Calendar/props';
import { RootState } from 'Store/index';
import { SlotInfo } from 'services/getSlotInfo/getSlotInfo';
import { AnalyticsKeys, firebaseAnalytics } from "Analytics/analytics";

const CalendarPage = () => {
    const dispatch = useDispatch();
    const BookingState = useSelector((state: RootState) => state.calendar.BookingState)
    const [viewModalIsOpened, setviewModalIsOpened] = useState(false)
    const [slotInfo, setSlotInfo] = useState<SlotInfo>({
        start_time: "",
        end_time: "",
        pitch_id: null
    })

    const [currentDate, setCurrentDate] = useState(
        moment(new Date()).startOf('day').format('YYYY-MM-DD')
    );
    const [dateFrom, setDateFrom] = useState(
        moment(new Date()).startOf('day').format('YYYY-MM-DD')
    );

    const [dateTo, setDateTo] = useState(
        moment(new Date()).startOf('day').add(6, 'd').format('YYYY-MM-DD'))

    const handleSelectEvent = (event: BookingState) => {
        setviewModalIsOpened(true)
        dispatch(CalendarSliceActions.setBookingState(event))
    }
    useEffect(() => {
        firebaseAnalytics(AnalyticsKeys?.CalendarTabViewed)
        dispatch(CalendarSliceActions.setBookingModalIsOpened(false)) //close booking modal if it's openned
        dispatch(CalendarSliceActions.setcurrentView("week")) //close booking modal if it's openned
    }, [])

    return (
        <>

            <div className="container-fluid calendar-module">
                <HeaderSection currentDate={currentDate} setCurrentDate={setCurrentDate}
                    dateFrom={dateFrom} setDateFrom={setDateFrom} dateTo={dateTo} setDateTo={setDateTo}
                />
                <CalendarSection currentDate={currentDate} setCurrentDate={setCurrentDate}
                    dateFrom={dateFrom} setDateFrom={setDateFrom} dateTo={dateTo} setDateTo={setDateTo}
                    handleSelectEvent={handleSelectEvent} slotInfo={slotInfo} setSlotInfo={setSlotInfo}
                />
            </div>
            {BookingState ? <>
                <NewBookingModals slotInfo={slotInfo}
                />
                <ViewBookingModal BookingState={BookingState} opened={viewModalIsOpened} setOpenView={setviewModalIsOpened} />
            </> : ""
            }

        </>
    )
}

export default CalendarPage