import React, { Dispatch, KeyboardEventHandler, MouseEventHandler, SetStateAction } from 'react'
import Locale from 'translations'
import { Modal, ModalBody } from 'reactstrap'

interface deleteAddonProps {
    text: string
    open: number | undefined
    onClose: MouseEventHandler<any>
    handleDelete: MouseEventHandler<HTMLButtonElement>
}
export default function DeleteAddonModal(props: deleteAddonProps) {
    const { addonsPage } = Locale

    return (
        <Modal
            isOpen={props?.open ? true : false}
            toggle={props?.onClose}
            size="lg"
            className='vh-100 d-flex justify-content-center align-items-center modalDeleteAddon' >
            <ModalBody >
                <h3 className='titleModal'>{props?.text}</h3>
                <p className='text-muted text-body mt-2'>This add on will be removed from the stadium.</p>
                <div className='btnsDiv d-flex justify-content-end pt-5'>
                    <button className='cancel mr-2' onClick={props?.onClose}>{addonsPage?.cancel}</button>
                    <button className='delete' onClick={props?.handleDelete}
                    >{addonsPage?.deleteAddon}</button>

                </div>
            </ModalBody>
        </Modal >

    )
}
