import { api } from 'services/api'
import cancelFixedBooking from './cancelFixedBooking'

const useApi = api.injectEndpoints({
    endpoints: build => ({
        cancelFixedBooking: cancelFixedBooking(build),
    }),
    overrideExisting: false,
})

export const {
    useCancelFixedBookingMutation
} = useApi