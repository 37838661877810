import { api } from 'services/api'
import getPitchesOfStadium from './getPitchesOfStadiumPO'

const useApi = api.injectEndpoints({
  endpoints: build => ({
    getPitchesOfStadium: getPitchesOfStadium(build),
  }),
  overrideExisting: false,
})

export const {
  useGetPitchesOfStadiumMutation
} = useApi
