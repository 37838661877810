import React, { useEffect, useState } from "react";
import moment from 'moment';
import Locale from 'translations';
import { DatePickerField } from 'components/Form';
import ReactSelect from "./ReactSelect";
import PitchSelect from "./PitchSelect";

import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
const minDay: any = moment(new Date()).subtract(10, "d")

const HeaderSection = ({ currentDate, setCurrentDate, setDateTo, setDateFrom, dateTo, dateFrom }) => {
    const { messages } = Locale;
    const [isDatePickerOpened, setIsDatePickerOpened] = useState(false);

    const userData = useSelector((state: RootState) => state.calendar.user)


    return (
        <>
            <div className='d-flex justify-content-between align-items-center py-2 mb-4 calendarTitleDiv'>
                <h3 className="h3 text-capitalize title">
                    {messages.hello}  {userData?.name}  </h3>
                <div className="d-flex justify-content-end  selectsDiv">

                    <div className="stadium-select pitch-select">
                        <ReactSelect />
                    </div>
                    <div className="pitch-select ml-3">
                        <PitchSelect />

                    </div>
                </div>
            </div>


            <div className="date-range">
                <DatePickerField
                    minDate={moment(new Date(minDay)).toDate()}
                    label="Starting Date"
                    placeholder="Select Date"
                    date={moment(currentDate)}
                    onChangeDate={(date) => {
                        setDateFrom(moment(date).subtract(6, 'd').format('YYYY-MM-DD'));
                        setDateTo(moment(date).add(6, 'd').format('YYYY-MM-DD'));
                        setCurrentDate(moment(date).startOf('day').format('YYYY-MM-DD'));
                        setIsDatePickerOpened(!isDatePickerOpened);
                    }}
                    isOpened={isDatePickerOpened}
                />
                <div className="pointer"
                    onClick={() => setIsDatePickerOpened(!isDatePickerOpened)}
                >
                    <i className="fa-solid fa-chevron-down"
                        onClick={() => setIsDatePickerOpened(!isDatePickerOpened)}
                    ></i>
                </div>
            </div>

        </>
    )
}

export default HeaderSection