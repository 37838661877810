import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
export default (build: EndpointBuilder<any, any, any>) =>
    build.mutation<Response, Body>({
        query: body => ({
            url: `/v3/pitch-owner-dashboard/stadiums/${body.id}/pitches/discounts`,
            method: 'POST',
            body,
        }),
    })

interface Body {
    start_date: string,
    end_date: string,
    pitch_id: number,
    id: number | undefined
}
