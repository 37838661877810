import moment from 'moment'
import React from 'react'
import { getTimeIntervalsForDiscounts } from './getTimeIntervalsForDiscounts'
import { getDaysOffInEachSlot } from './getDaysOffInEachSlot'

export const generateLayout = (pitchId, slot, resourceId, FemaleFrienlyTimes, daysOffofStadium, DiscountSlots, currentView, selectedStadium) => {
  let className: string = "" // 'calendarDayOff disabled' || "FemaleFriendlySlot" || "DiscountSlot" || FemaleDiscountSlot
  if (DiscountSlots) {
    const newDate = moment(slot).startOf('day').format('YYYY-MM-DD')
    const defaultFormat = "YYYY-MM-DD HH:mm"
    let discountedDays = DiscountSlots[newDate]
    const newTime = moment(slot).format('HH:mm:ss')

    // female friendly
    FemaleFrienlyTimes && FemaleFrienlyTimes?.forEach(element => {
      let dayOfSlot = moment(newDate).day()
      dayOfSlot === 0 ? dayOfSlot = 6 : dayOfSlot = dayOfSlot - 1
      if (element.day === dayOfSlot) {

        const startDateOfSlot = moment(`${newDate} ${element.valid_from}`, defaultFormat).toDate()
        const endDateOFSlot = moment(`${newDate} ${element.valid_to}`, defaultFormat).toDate()
        const checkOfSlot = moment(slot).isBetween(startDateOfSlot, endDateOFSlot, null, '[]')

        if (checkOfSlot) {
          className = 'FemaleFriendlySlot'
        }
      }
    });

    if (currentView == "week") {
      let time_intervals
      if (discountedDays) {
        className = getTimeIntervalsForDiscounts(discountedDays, time_intervals, newTime, className)
      }
      let daysOffforPitch = daysOffofStadium && daysOffofStadium?.filter((day) => ((day.pitch_ids?.map((item) => String(item))).includes(String(pitchId))))
      if (getDaysOffInEachSlot(daysOffforPitch, slot, newDate))
        className = 'calendarDayOff disabled'
    }
    else {
      let time_intervals
      if (discountedDays) {
        let discountedDaysOFPitch = discountedDays?.filter((item) => item?.pitch_ids.includes(resourceId))
        className = getTimeIntervalsForDiscounts(discountedDaysOFPitch, time_intervals, newTime, className)
      }
      let daysOffforPitch = daysOffofStadium && daysOffofStadium?.filter((day) => ((day.pitch_ids?.map((item) => String(item))).includes(String(resourceId))))
      if (getDaysOffInEachSlot(daysOffforPitch, slot, newDate))
        className = 'calendarDayOff disabled'
    }

  }
  return { className }
}
