import React from 'react'

export default function PrintReciept({ printUrl, setprintUrl }) {
    return (
        <>
            <div className={`${printUrl !== "" ? "d-flex w-100 vh-100  position-absolute printReciept" : "d-none"}`}>
                <div className='close' onClick={() => setprintUrl("")}>
                    <i className="fa-solid fa-left-long closeIcon" ></i>
                </div>
                <iframe title='printRecieptUrl' src={printUrl} className="printRecieptUrl">
                </iframe> </div>
        </>
    )
}
