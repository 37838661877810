import React from 'react';

const TableComponents = ({ title, value }) => {
    return (
        <tr>
            <td>{title}</td>
            <td>{value}</td>
        </tr>
    );
}

export default TableComponents;
