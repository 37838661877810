import * as yup from 'yup'
import Locale from 'translations';
const { ErrorMsgs } = Locale;

export const validationScheme = yup.object({
    booking_name: yup
        .string()
        .trim()
        .min(2, ErrorMsgs.booking_name.min)
        .required(ErrorMsgs.booking_name.required),

    booking_phone_number: yup
        .number().typeError(ErrorMsgs.booking_phone_number.type)
        // .test('len', ErrorMsgs.booking_phone_number.min, val => val?.toString().length == 8)
        .required(ErrorMsgs.booking_phone_number.required),

    phoneCode: yup
        .number().typeError(ErrorMsgs.phoneCode.required)
        .required(ErrorMsgs.phoneCode.required),

    match_date: yup
        .date().typeError(ErrorMsgs.match_date.type)
        .required(ErrorMsgs.match_date.required),

    match_time: yup
        .string()
        .required(ErrorMsgs.match_time.required),

    payment_actual_amount: yup
        .number().typeError(ErrorMsgs.payment_actual_amount.type)
        .required(ErrorMsgs.payment_actual_amount.required),

    user_id: yup.string()
        .when('booking_type', ([booking_type], schema) =>
            booking_type === 1 ? schema.required(ErrorMsgs.user_id.required) : schema,
        ),

    payment_method: yup
        .number().typeError(ErrorMsgs.payment_method.required)
        .required(ErrorMsgs.payment_method.required),

    amount_paid: yup
        .number().typeError(ErrorMsgs.amount_paid.type)
        .required(ErrorMsgs.amount_paid.required),

    last_match_date: yup
        .date()
        .when("match_date", ([match_date], schema) => (match_date && schema.min(match_date,ErrorMsgs.last_match_date.required)))
        .required(ErrorMsgs.last_match_date.required),

})