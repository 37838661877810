import LocalizedStrings from 'react-localization';

const Locale = new LocalizedStrings({
    en: {
        en: "English",
        ar: "العربية",
    },
    ar: {
        en: "English",
        ar: "العربية",
    }
});

const getLang = () => {
    return Locale.getLanguage()
}


export { getLang };
export default Locale;