import React, { useState, useEffect } from 'react'
import { TextField, SelectWithIcon, TextAreaField, TimePickerComponent, DatePickerField, TextFieldWithSelect } from 'components/Form';
import { Button, Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import { phoneCode } from 'Store/Calendar/props';
import { useLazyFetchPhoneCodesQuery } from 'services/fetchPhoneCodes';
import { getPaymentMethod } from 'Pages/CalendarPage/Helpers/getPaymentMethod';
import { checkPrice } from 'Pages/CalendarPage/Helpers/getPendingPrice';
import { useUpDateSingleBookingMutation } from 'services/updateSingleBooking';
import { useAddNotification } from '../../Helpers/addNotification';
import { useCreateSingleBookingMutation } from 'services/createSingleBooking';
import { getDatatoSubmit } from 'Pages/CalendarPage/Helpers/getDatatoSubmit';
import { useGetBookingPriceMutation } from 'services/getBookingSlotPrice';
import { useFormik } from 'formik';
import { validationScheme } from 'Pages/CalendarPage/validation/validate';
import { userRoleEnum } from 'Pages/Coaches/enums/userRolesEnum';
import { useGetAvailablePitchesToSwapMutation } from 'services/getAvailablePitchesToSwap';
import { useSwapPitchesMutation } from 'services/SwapPitches';
import { getLangTitle } from 'Pages/Coaches/helpers/getLangTitle';
import { useMalaebState } from 'context/global';
import moment from 'moment';
import dayjs from 'dayjs';
import Locale from 'translations';
import Loader from 'components/LoadingPage/LoadingPage';
import useFetchPhoneCodes from 'hooks/useGetAppPhoneCodes';
import getBookingType from 'Pages/CalendarPage/Helpers/getBookingVariables';


export default function SingleBooking({ title, toggleModalCreate }) {
    const BookingState = useSelector((state: RootState) => state.calendar.BookingState)
    const oldDuration = BookingState?.payment_order_duration
    const oldPitchId = useSelector((state: RootState) => state.calendar.pitchId)
    const role = useSelector((state: RootState) => state.calendar.user?.roles?.[0])
    const pitchesForStadium = useSelector((state: RootState) => state.calendar.pitchesforStadium)
    const selectedStadium = useSelector((state: RootState) => state.calendar.selectedStadium)
    const paymentMethodOfStadium = useSelector((state: RootState) => state.calendar.paymentMethodsArr)
    const userRole = useSelector((state: RootState) => state.calendar?.userRole)

    const { locale } = useMalaebState();
    const { NewBookingModalTitle, calendarPage, messages } = Locale;
    const [bookingType, durationTime, durationOthers, paymentMethod] = getBookingType()
    const [phoneCodes] = useFetchPhoneCodes();
    const [isLoading, setisLoading] = useState<boolean>(false);
    const [pitchesToSwap, setpitchesToSwap] = useState([]);
    const [selectedPitchinSwapBooking, setSelectedPitchinSwapBooking] = useState<any>();
    const [selectedPitchinModal, setselectedPitchinModal] = useState<any>();


    const [phoneCodesReq, phoneCodesRes] = useLazyFetchPhoneCodesQuery()
    const [requestgetPrice] = useGetBookingPriceMutation()
    const [Editreq] = useUpDateSingleBookingMutation()
    const [CreateReq] = useCreateSingleBookingMutation()
    const [requestgetPitchesToSwap, responsePitchesToSwap] = useGetAvailablePitchesToSwapMutation()
    const [requestgSwapPitches, responseSwapPitches] = useSwapPitchesMutation()


    const {
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        errors,
        setValues,
        touched,
        setFieldValue,
        dirty,
        isValid,
    } = useFormik({
        initialValues: {
            ...BookingState,
            alreadyPaid: false,
            pending_price: 0,
            booking_phone_number: BookingState?.booking_phone_number?.slice(3),
            phoneCode: BookingState?.booking_phone_number ? BookingState?.booking_phone_number?.slice(0, 3) : selectedStadium?.phone_code,
            paymentMethodOptions: paymentMethodOfStadium,
            payment_method: BookingState?.payment_method ? BookingState?.payment_method : paymentMethod?.[0]?.value
        },
        onSubmit: (values) => { submitData() },
        validateOnChange: true,
        validationSchema: validationScheme,
    })




    useEffect(() => { //get pending price
        checkPrice(values, setValues)
    }, [values.payment_actual_amount, values.amount_paid])


    /////////////////////////////////////////////SWAP BOOKINGS BETWEEN COURTS/////////////////////////////// 
    useEffect(() => {
        setselectedPitchinModal(pitchesForStadium?.filter((pitch) => pitch.id === values.pitch_id)?.[0].lable)
        setSelectedPitchinSwapBooking(pitchesForStadium?.filter((pitch) => pitch.id === values.pitch_id)?.[0].lable)
        if (title == NewBookingModalTitle.edit)
            requestgetPitchesToSwap({ booking_id: BookingState?.id }).then((data: any) => {
                setpitchesToSwap(data?.data?.data)
            })
    }, [])

    const swapBooking = (e) => {
        requestgSwapPitches({ booking_id: BookingState?.id, pitch_id: e.value }).then((data: any) => {
            setSelectedPitchinSwapBooking(pitchesForStadium?.filter((pitch) => pitch.id === e.value)?.[0]?.lable)
            setselectedPitchinModal(pitchesForStadium?.filter((pitch) => pitch.id === e.value)?.[0]?.lable)
            useAddNotification(data?.data?.data, messages?.success, "success")
            setFieldValue("pitch_id", e.value)
        })
    }
    useEffect(() => {
        if (pitchesForStadium) {
            setselectedPitchinModal(pitchesForStadium?.filter((pitch) => { return pitch.id === values.pitch_id })[0].lable)
        }
    }, [values.pitch_id])

    const handleClickAlreadyPaid = (e) => {
        if (e.target.checked) {
            let payment_actual_amount = values.payment_actual_amount;
            setValues({
                ...values,
                [`alreadyPaid`]: true,
                [`amount_paid`]: payment_actual_amount
            })
        } else {
            setValues({
                ...values,
                [`alreadyPaid`]: false
            })
        }
    };


    useEffect(() => {
        //get default price if the user in booking creation case or when Edit& change the pitch or the duration
        if (oldDuration !== values.payment_order_duration || oldPitchId !== values.pitch_id || title == NewBookingModalTitle.create) {
            const body = {
                pitch_id: values.pitch_id,
                match_date: moment(values.match_date).format('YYYY-MM-DD'),
                match_time: values.match_time,
                duration: values.payment_order_duration,
            }
            requestgetPrice(body).then((data: any) => {
                let price = data?.data?.data.price
                setValues({
                    ...values,
                    ["payment_actual_amount"]: price,
                    ['payment_expected_amount']: price
                })
            })
        }
    }, [values.payment_order_duration, values.pitch_id, values.match_date, values.match_time])


    const submitData = () => {
        const data = getDatatoSubmit(values, role)
        setisLoading(true);
        if (title !== NewBookingModalTitle.create) {
            const dataforupdate = {
                ...data,
                id: values.id
            }
            Editreq(dataforupdate).then((data: any) => {
                setisLoading(false);
                if (data.error) {
                    useAddNotification(data?.error?.data?.message, messages?.warning, "danger")
                }
                else {
                    useAddNotification(calendarPage?.bookingEdited, messages?.success, "success")
                    toggleModalCreate()
                }
            }).catch(() => {
                useAddNotification(messages.errorMsg, messages?.warning, "danger")
            })
        }
        else {
            CreateReq(data).then((data: any) => {
                setisLoading(false);
                if (data.error) {
                    useAddNotification(data?.error?.data?.message, messages?.warning, "danger")
                }
                else {
                    useAddNotification(calendarPage?.bookingCreated, messages?.success, "success")
                    toggleModalCreate()
                }
            }).catch(() => {
                useAddNotification(messages.errorMsg, messages?.warning, "danger")
            })
        }
    }


    return (
        <>
            <form onSubmit={handleSubmit}>
                <Row className="mx-0">
                    <Col sm="6" className="green-label">
                        <TextField
                            error={touched.booking_name && errors.booking_name ? errors.booking_name : ""}
                            placeholder={calendarPage?.bookingNamePlace}
                            label={calendarPage?.bookingName}
                            value={values.booking_name}
                            name="booking_name"
                            onChange={handleChange}
                        />
                    </Col>
                    <Col sm="6" className="green-label">
                        <TextFieldWithSelect
                            error={touched.booking_phone_number && errors.booking_phone_number ? errors.booking_phone_number : ""}
                            errorPhoneCode={touched.phoneCode && errors.phoneCode ? errors.phoneCode : ""}
                            className="phone-input"
                            label={calendarPage?.phone}
                            placeholder={calendarPage?.phoneplaceHolder}
                            name="booking_phone_number"
                            selectName="phoneCode"
                            selectPosition="prepend"
                            value={values.booking_phone_number}
                            onChange={handleChange}
                            onChangeSelect={handleChange}
                            selectValue={values.phoneCode}
                        >
                            <option defaultValue="">Select</option>
                            {phoneCodes?.map((item: phoneCode) => (
                                <option key={item.country_id} value={item.code}>
                                    {item.code}
                                </option>
                            ))}
                        </TextFieldWithSelect>
                    </Col>
                </Row>
                <Row className="mx-0 mb-1">
                    <Col sm="4" className="green-label">
                        <DatePickerField
                            label={calendarPage?.startDate}
                            placeholder={calendarPage?.selectDate}
                            name={calendarPage?.startDate}
                            date={values.match_date ? moment(values.match_date) : ""}
                            onChangeDate={(date) => date ? setFieldValue("match_date", moment(date)) : ""
                            }
                        />
                    </Col>
                    <Col sm="4" className="green-label">
                        <TimePickerComponent
                            error={touched.match_time && errors.match_time ? errors.match_time : ""}
                            label={calendarPage?.startingTime}
                            name="match_time"
                            value={values.match_time}
                            onChange={(newvalue: string) => setFieldValue("match_time", newvalue ? dayjs(newvalue).format("HH:mm:ss") : "")
                            }
                        />

                    </Col>
                    {values.payment_order_duration >= 180 ?
                        <Col sm="4" className="green-label">
                            <SelectWithIcon
                                error={touched.booking_name && errors.booking_name ? errors.payment_order_duration : ""}
                                label={calendarPage?.duration}
                                value={`${values.payment_order_duration} ${calendarPage?.mins}`}
                                name="payment_order_duration"
                                icon={'fa-solid fa-clock'}
                                options={durationOthers}
                                placeholder="Select"
                                onChange={(e) => setFieldValue("payment_order_duration", e.value)}
                            />
                        </Col> :
                        <Col sm="4" className="green-label">
                            <SelectWithIcon
                                error={touched.payment_order_duration && errors.payment_order_duration ? errors.payment_order_duration : ""}
                                label={calendarPage?.duration}
                                value={`${values.payment_order_duration} ${calendarPage?.mins}`}
                                name="payment_order_duration"
                                placeholder={calendarPage?.select}
                                icon={'fa-solid fa-clock'}
                                options={durationTime}
                                onChange={(e) => setFieldValue("payment_order_duration", e.value)}
                            />
                        </Col>
                    }
                    {userRole === userRoleEnum?.admin ? <Col sm="4" className="green-label">
                        <SelectWithIcon
                            label={calendarPage?.bookingType}
                            placeholder={calendarPage?.select}
                            value={values.booking_type !== null ? values.booking_type === 1 ? calendarPage?.user : calendarPage?.owner : null}
                            name="booking_type"
                            icon={'fas fa-user'}
                            options={bookingType}
                            onChange={(e) => setValues({
                                ...values,
                                [`booking_type`]: e.value,
                                [`user_id`]: "",
                                [`payment_method`]: e.value == 1 ? paymentMethodOfStadium?.[0]?.value : paymentMethod?.[0]?.value,
                                paymentMethodOptions: e.value == 1 ? paymentMethodOfStadium : paymentMethod
                            })}
                        />
                    </Col> : ""}

                    {values.booking_type == 1 ? <Col sm="4" className="green-label">
                        <TextField
                            error={touched.booking_type && values.booking_type == 1 && errors.user_id ? errors.user_id : ""}
                            placeholder={calendarPage?.userId}
                            label={calendarPage?.userId}
                            value={values.user_id ? values.user_id : ""}
                            type="number"
                            name="user_id"
                            onChange={(e) => setFieldValue("user_id", e.target.value)}
                        />
                    </Col> : ""}
                    {userRole === userRoleEnum?.admin && title == NewBookingModalTitle.edit ?
                        <Col sm="4" className="green-label">
                            <SelectWithIcon
                                label={calendarPage?.swapBooking}
                                placeholder={calendarPage?.select}
                                value={selectedPitchinSwapBooking}
                                name="SwapBookings"
                                icon={'fa-solid fa-vector-square'}
                                options={pitchesToSwap}
                                onChange={(e) => {
                                    swapBooking(e)
                                }}
                            />
                        </Col> : ""
                    }
                    {userRole === userRoleEnum?.admin && title == NewBookingModalTitle.edit ?
                        <Col sm="4" className="green-label">
                            <SelectWithIcon
                                label={calendarPage?.editPitch}
                                placeholder={calendarPage?.select}
                                value={selectedPitchinModal}
                                name="EditPitch"
                                icon={'fa-solid fa-vector-square'}
                                options={pitchesForStadium}
                                onChange={(e) => { setFieldValue("pitch_id", e.value) }}
                            />
                        </Col> : ""
                    }
                </Row>

                <Row className="mx-0 align-items-center pricing-section">
                    <Col sm="6" className="green-label">
                        <TextField
                            error={touched.payment_actual_amount && errors.payment_actual_amount ? errors.payment_actual_amount : ""}
                            label={calendarPage?.bookingPrice}
                            value={values.payment_actual_amount}
                            name="payment_actual_amount"
                            type="number"
                            onChange={handleChange}
                        />
                        <p className="text-left text-gray-300 text-caption mx-1">
                            {values.payment_expected_amount ?
                                `${calendarPage?.defaultPrice} ${values.payment_expected_amount}
                             ${selectedStadium ? getLangTitle(selectedStadium, "main_currency_label") : "BHD"} ${calendarPage?.youCanChange}`
                                : null}
                        </p>
                    </Col>
                    <Col sm="6">
                        <div className="d-flex align-items-center justify-content-end pb-1">
                            <label className="switch-checkbox mb-0" htmlFor="checkbox">
                                <input
                                    type="checkbox"
                                    id="checkbox"
                                    onClick={(e) => {
                                        handleClickAlreadyPaid(e);
                                    }}
                                    checked={values.alreadyPaid ? true : false}
                                />
                                <div className="slider round"></div>
                            </label>
                            <h6 className="px-2 text-body font-weight-bold">{calendarPage?.alreadyPaid}</h6>
                        </div>
                    </Col>
                </Row>
                <Row className="mx-0 mb-1 ">
                    <Col sm="6" className="green-label">
                        <SelectWithIcon
                            error={touched.payment_method && errors.payment_method ? errors.payment_method : ""}
                            label={calendarPage?.paymentMethod}
                            placeholder={calendarPage?.select}
                            options={values?.paymentMethodOptions}
                            value={values.payment_method ? getPaymentMethod(values.payment_method) : ""}
                            name="payment_method"
                            icon={'fa-regular fa-credit-card'}
                            onChange={(e) => { setFieldValue("payment_method", e.value) }}
                        />
                    </Col>
                </Row>
                {!values.alreadyPaid ?
                    <Row className="mx-0 align-items-center pricing-section">
                        <Col sm="6">
                            <TextField
                                error={touched.amount_paid && errors.amount_paid ? errors.amount_paid : ""}
                                placeholder={calendarPage?.totalRecieved}
                                label={calendarPage?.totalRecieved}
                                value={values.amount_paid}
                                name="amount_paid"
                                onChange={(e) => setFieldValue("amount_paid", e.target.value)}
                            />
                        </Col>
                        <Col sm="6">
                            <div className="total-price justify-content-center bg-green-white">
                                <p>
                                    {calendarPage?.pending}  {values.pending_price} BHD
                                </p>
                            </div>
                        </Col>
                    </Row>
                    : null}

                <Row className="mx-0 mb-1 ">
                    <Col sm="12" className="green-label">
                        <TextAreaField
                            label={calendarPage?.additionalNotes}
                            value={values.booking_note}
                            name="booking_note"
                            onChange={(e) => { setFieldValue("booking_note", e.target.value) }}
                        />
                    </Col>
                </Row>
                <Row className='w-100 mx-0 justify-content-between'>
                    <Button color="secondary"
                        className='w-48'
                        onClick={toggleModalCreate}
                    >
                        {calendarPage?.cancel}
                    </Button>
                    <Button
                        color="default"
                        className='w-48'
                        type='submit'
                    >
                        {title == NewBookingModalTitle.create ? NewBookingModalTitle?.createBtn : NewBookingModalTitle?.editBtn}
                    </Button>
                </Row>
            </form>
            {isLoading || phoneCodesRes?.isLoading || responseSwapPitches?.isLoading ? <Loader /> : null}

        </>
    )
}
