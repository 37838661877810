import { StadiumDetailsState } from "./props";

export default {
    stadiumDetails: {
        closing_time: "",
        opening_time: "",
        is_support_split_payment: false,
        split_payment_policy_hours: 0,
        supported_payment_methods: [],
        title: "",
        title_ar: "",
        upfront_payment: false,
        upfront_price: 0,
        daysoff: [],
        pitches: []
    }
} as StadiumDetailsState