import { api } from 'services/api'
import fetchDiscountedSlots from './discountSlots'

const useApi = api.injectEndpoints({
  endpoints: build => ({
    fetchDiscountedSlots: fetchDiscountedSlots(build),
  }),
  overrideExisting: false,
})

export const {
  useFetchDiscountedSlotsMutation
} = useApi
