import React from 'react'
import Locale from 'translations'
import { ModalBody, Button } from 'reactstrap'

interface Props {
    goToCreate: (arg0: boolean) => void
}

const NoAddons = (props: Props) => {
    const { addonsPage } = Locale


    return (
        <>
            <ModalBody className='d-flex align-items-center flex-column'>
                <Button color='default' className='w-100 mb-3'
                    onClick={() => props?.goToCreate(true)}
                >{addonsPage?.create}</Button>
                <p className='fw-semibold text-headline text-darkgrey'>{addonsPage?.NoAddons}</p>
            </ModalBody>
        </>
    )
}

export default NoAddons