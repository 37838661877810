import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useCancelSingleBookingMutation } from 'services/cancelSingleBooking';
import { useAddNotification } from '../../Helpers/addNotification';
import { RootState } from 'Store/index';
import { CalendarSliceActions } from 'Store/Calendar';
import { useCancelFixedBookingMutation } from 'services/cancelFixedBooking';
import { Loader } from '..';
import Locale from 'translations';
import useResetBookingState from 'Pages/CalendarPage/Hooks/useResetBookingState';

export const AskToCancelBooking = ({ setaskToCancelFixed, askToCancel, setaskToCancel, toggleModalView }) => {
  const BookingState = useSelector((state: RootState) => state.calendar.BookingState)
  const dispatch = useDispatch()
  const [cancelSingleReq, cancelSingleRes] = useCancelSingleBookingMutation()
  const [cancelFixedReq, cancelFixedRes] = useCancelFixedBookingMutation()

  const [bookingAfterReset] = useResetBookingState()
  const { calendarPage, messages } = Locale
  const toggleAskToCancelBooking = () => {
    setaskToCancel(!askToCancel)
  }
  const handleCancelBooking = () => {
    if (BookingState.is_single_booking) {
      cancelSingleReq({ id: BookingState.id }).then((data: any) => {
        if (data.error) {
          useAddNotification(data?.error?.data?.message, messages?.warning, "danger")
        }
        else {
          useAddNotification(calendarPage?.bookingCanceled, messages?.success, "success")
          toggleAskToCancelBooking()
          toggleModalView()
          setaskToCancelFixed(false)
          if (bookingAfterReset) dispatch(CalendarSliceActions.setBookingState(bookingAfterReset))
        }

      }).catch(() => {
        useAddNotification(messages.errorMsg, messages?.warning, "danger")
      })
    }
    else {
      if (BookingState.fixed_booking?.id) {
        cancelFixedReq({ id: BookingState.fixed_booking?.id }).then((data: any) => {
          if (data.error) {
            useAddNotification(data?.error?.data?.message, messages?.warning, "danger")
          }
          else {
            useAddNotification(calendarPage?.bookingCanceled, messages?.success, "success")
            toggleAskToCancelBooking()
            toggleModalView()
            setaskToCancelFixed(false)
            if (bookingAfterReset) dispatch(CalendarSliceActions.setBookingState(bookingAfterReset))
          }

        }).catch(() => {
          useAddNotification(messages.errorMsg, messages?.warning, "danger")
        })
      }
    }

  }
  return (
    <>
      <Modal
        isOpen={askToCancel}
        toggle={toggleAskToCancelBooking}
        size="md"
        className="create-booking view-booking"
      >
        <ModalHeader
          className="font-weight-bold"
          toggle={toggleAskToCancelBooking}
        >
          {calendarPage?.cancelBookingTitle}
        </ModalHeader>
        <ModalBody className="py-0">
          <h5 className="py-3 text-center">
            {calendarPage?.cancelBookingQues}
          </h5>
        </ModalBody>
        <ModalFooter>
          <Button color="default"
            onClick={handleCancelBooking}
          >
            {calendarPage?.yesCancel}
          </Button>
          <Button className="cancel-booking"
            onClick={toggleAskToCancelBooking}
          >
            {calendarPage?.goback}
          </Button>
        </ModalFooter>
      </Modal>
      {cancelFixedRes?.isLoading || cancelSingleRes?.isLoading ? <Loader /> : null}
    </>
  )
}
