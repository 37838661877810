import { api } from 'services/api'
import getBooking from './getBooking'

const useApi = api.injectEndpoints({
    endpoints: build => ({
        getBooking: getBooking(build),
    }),
    overrideExisting: false,
})

export const {
    useLazyGetBookingQuery
} = useApi