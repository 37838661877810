import { useMalaebDispatch, useMalaebState } from "context/global";
import React, { Dispatch } from "react";
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
} from "reactstrap";
import Locale from "./LanguageSwitcher.locale";
export default function LanguageSwitcher() {
	const { locale } = useMalaebState();
	Locale.setLanguage(locale);
	const dispatch:Dispatch<any> = useMalaebDispatch();

	return (
		<UncontrolledDropdown className="lang-dropdown">
			<DropdownToggle caret className="languageSwitcher">

				{Locale[locale]}
			</DropdownToggle>

			<DropdownMenu>
				{locale == "ar" ? <DropdownItem
					onClick={() => {
						if (locale === "ar") {
							dispatch({ type: "setLocale", payload: "en" });
							dispatch({ type: "setLoading", payload: true });
						}
					}}
				>
					<span className={`${locale === "en" ? "ml-2" : "mr-2"}`}>
						English
					</span>
				</DropdownItem> :
					<DropdownItem
						onClick={() => {
							if (locale === "en") {
								dispatch({ type: "setLocale", payload: "ar" });
								dispatch({ type: "setLoading", payload: true });
							}
						}}
					>
						<span className="text-center">العربية</span>
					</DropdownItem>}
			</DropdownMenu>
		</UncontrolledDropdown>
	);
}
