import React from 'react'
import FixedBooking from '../FixedBooking/FixedBooking';


export const Day = ({ item, items, values, setValues }) => {
    const weeklyClicked = (item, items) => {
        if (values.payment_order_duration) {
            items = items.map((result) => {
                if (item.id === result.id) {
                    item.isActive = !item.isActive;
                    setValues({
                        ...values,
                        fixed_booking: {
                            ...values,
                            ['day_of_week']: item.id
                        }
                    })
                } else {
                    result.isActive = false;
                }
                return result;
            });
        }

    };
    return (
        <>
            <li
                key={item.id}
                className={item.id == values.day_of_week ? 'is-active' : ''}
                onClick={() => weeklyClicked(item, items)}
            >
                {item.label}
            </li>
        </>
    );
};
//h7otha fel fixed booking:


