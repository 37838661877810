import React from "react";
import { Link } from "react-router-dom";
import Locale from "./Error404.locale";
import { userRoleEnum } from "Pages/Coaches/enums/userRolesEnum";
import { useMalaebState } from "context/global";

const Error404 = (props) => {
	const { userInfo } = useMalaebState();
	const userRole = userInfo?.roles[0]
	return (
		<div className="error-404 text-center">
			<div className="row align-items-end h-100">
				<div className="col-md-4 mx-auto">
					<h4 className="error-404__title">{Locale.Error_Title}</h4>
					<p className="error-404__content">{Locale.Error_Content}</p>
					<Link className="btn btn-primary w-75 mt-2" to={userRole === userRoleEnum?.coach ? "/coaches" : "/"}>
						Back To Home
					</Link>
				</div>
			</div>
		</div >
	);
};

export default Error404;
