import React from 'react'
import { getBookingPaymentImage } from '../Helpers/getImageOfPayment'
import { ItemOfCalendarEvents } from './CustomEvent'
import Locale from 'translations'
import SingleBooking from '../Components/SingleBooking/SingleBooking';

const CustomEventWeekView = (props: ItemOfCalendarEvents) => {
    const { calendarPage } = Locale
    return (
        <>
            <div className='d-flex justify-content-between align-items-center rbcEventContentH1 px-2'>
                <div className='w-50'>
                    <p className={props?.item.event.booking_name?.length > 15 && props?.item.event.payment_order_duration <= 60 ? "font-weight-bold text-font10 text-left" : "font-weight-bold text-font13 text-left"}
                    >
                        {props?.item.event.booking_name}
                    </p>
                </div>
                <div className=''>
                    <div className='d-flex align-items-center w-100 justify-content-end pl-1'>
                        <div className='imgDiv text-right'>
                            <img className='w-100' src={getBookingPaymentImage(props?.item.event)} />
                            {/* {item?.event?.is_use_credit === 1 ? <img className='w-100' src={tap} alt='tap method' /> : props?.item.event.payment_method === 1 ? <img className='w-100' src={cash} alt='cash method' /> : <img className='w-100' src={tap} alt='tap method' />} */}
                        </div>
                        <div className='pl-1'>
                            <p className='font-bold font14 text-left'>
                                {props?.item.event.net_amount} {props?.item.event.payment_currency}
                            </p>

                        </div>

                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-between px-1'>
                <p className='text-left font12 d-flex align-items-center '>
                    {props?.item.event.is_single_booking ? (
                        <>
                            <span className='point mr-1'></span>
                            <span > {calendarPage?.singleBooking} </span>
                        </>
                    ) : (
                        <>
                            <span className={`point mr-1 ${props?.item.event?.fixed_booking?.is_expiring_soon
                                ? 'bg-danger'
                                : ''
                                }`}></span>
                            <span
                                className={`font-bold ${props?.item.event?.fixed_booking?.is_expiring_soon
                                    ? 'text-maroon'
                                    : ''
                                    }`}
                            >
                                {calendarPage?.fixedBooking}
                            </span>
                        </>
                    )}
                </p>
                <p className='text-left text-caption2 font-bold text-danger '>{props?.item.event.no_show ? calendarPage?.noShow : ""}</p>

            </div>
            <div>
                <p className='font12'>{props?.item.event.is_used_coupon ? calendarPage?.couponUsed : ""}</p>

            </div>
        </>
    )
}

export default CustomEventWeekView