import State from './state';
import Reducer from './reducer'
import { createSlice } from '@reduxjs/toolkit'
import {  StadiumDetailsState } from './props'

const slice = createSlice({
  name: 'managestadiumState',
  initialState: State as StadiumDetailsState,
  reducers: Reducer,
})

export const manageStadiumSliceAction = slice.actions

export default slice.reducer
