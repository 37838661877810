import { CalendarState, PhoneCode } from './props';

export default {
    locale: undefined,
    user: undefined,
    isAuth: false,
    userRole: undefined,
    selectedStadium: undefined,
    stadiumsList: [],
    stadiumsListFromAPI: [],
    pitchesforStadium: undefined,
    pitch: undefined,
    pitchId: undefined,
    currentView: "week",
    BookingState: {},
    paymentMethodsArr: [],
    BookingModalIsOpened: false,
    BookingModalTitle: "Create",
    addonObj: undefined,
    phoneCodes: []
} as unknown as CalendarState

const resetState = () => {

}
