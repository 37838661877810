import axios from 'axios';
const profileURL = process.env.REACT_APP_API_URL;

export const fetchUserProfile = async () => {
  return await axios.get(`${profileURL}/auth/me`).then((res) => res.data.data);
};

export const uploadFile = async (data) => {
  return await axios
    .post(
      `${profileURL}/profile/image`,
      data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const fetchImages = async (url) => {
  return await axios
    .get(url)
    .then((res) => res.config.url)
    .catch((err) => err.response);
};

export const changePassword = async (data) => {
  return await axios
    .post(`${profileURL}/profile/update-password`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const updateProfile = async (data) => {
  return await axios
    .post(`${profileURL}/profile`, data)
    .then((res) => res)
    .catch((err) => err.response);
};
