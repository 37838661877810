import React from "react";

/**
 * @Component TextAreaField
 * @Description This component for any text input group like text, email, etc.
 * @Props
 *    -> name: Name for text area field
 *    -> label: Text label for text area field
 *    -> placeholder: Text area field placeholder
 *    -> className: HTML classes for text area field
 *    -> id: HTML ID
 *    -> rows: number of textarea rows
 *    -> color: Bootstrap color
 *    -> value: Text area value
 *    -> feedbackMessage: Feedback message for validation
 *    -> onBlur: Text area Function
 *    -> onChange: Text area Function
 *    -> isInvalid: [Bool] set field is valid or not
 */

export default function TextAreaField(props) {
	return (
		<>
			<div
				className={`my-2 input-group control-field ${props.error ? "control-field-- text-danger" : ""
					}`}
			>
				{/* Text Area Field Body */}
				<div className={`control-field__body ${props?.error ? "border border-danger" : ""}`}>
					{/* Label */}
					<label
						className={`control-field__label ${props.error ? " control-field__label-- text-danger" : ""
							}`}
					>
						{props.label}
					</label>

					{/* Text Area */}
					<div className="row no-gutters">
						<textarea
							role="textbox"
							name={props.name}
							className={`${props.className ?? ""} control-field__input w-100`}
							id={props.id}
							rows={props.rows}
							onChange={props.onChange}
							onBlur={props.onBlur}
							placeholder={props.placeholder}
							value={props.value}
							disabled={props.disabled}
						></textarea>
					</div>
				</div>
			</div>

			{/* Error */}
			<small
				className={`text-caption control-field__feedback control-field__ d-block error-message text-danger`}
			>
				{props.error}
			</small>
		</>
	);
}
