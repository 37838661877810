import React, { Dispatch, SetStateAction } from 'react'
import Locale from 'translations'
import Loader from 'components/LoadingPage/LoadingPage'
import { Addon } from '.'
import { RootState } from 'Store'
import { AddonObj } from 'Store/Calendar/props'
import { useSelector } from 'react-redux'
import { Button, ModalBody, ModalFooter } from 'reactstrap'
import { useSendAddonsMutation } from 'services/SendAddons'
import { useAddNotification } from "Pages/CalendarPage/Helpers/addNotification";
import { AddonDetails } from 'services/SendAddons/addons'


interface AddonListProps {
    addonDetailsList: AddonDetails[]
    addonList: AddonObj[]
    setAddonDetailsList: Dispatch<SetStateAction<AddonDetails[]>>
    setAddonList: Dispatch<SetStateAction<AddonObj[]>>
    goToCreate: (arg0: boolean) => void
    toggleAddonsModal: VoidFunction
    addonsModalIsOpened: boolean

}

const AddonsList = (props: AddonListProps) => {
    const selectedStadium = useSelector((state: RootState) => state?.calendar?.selectedStadium)
    const bookingState = useSelector((state: RootState) => state?.calendar?.BookingState)
    const { addonsPage, messages } = Locale
    const [sendAddonsRequest, sendAddonsResponse] = useSendAddonsMutation()


    const sendAddons = () => {
        sendAddonsRequest({
            id: bookingState?.id,
            addons: props?.addonDetailsList
        }).then((data: any) => {
            if (data?.error) {
                useAddNotification(data?.error?.data?.message, messages.warning, "danger")
            }
            else {
                useAddNotification(addonsPage?.added, messages.success, "success")
                props?.toggleAddonsModal()
            }
        }).catch((err) =>
            useAddNotification(messages?.errorMsg, messages.success, "success")
        )
    }


    const accumlativeSum = () => {
        let sum = 0
        props?.addonDetailsList?.map((item: AddonDetails) => {
            return sum += Number(item?.total_price)
        })
        return sum
    }

    return (
        <>
            <ModalBody className='d-flex align-items-center flex-column'>
                {props?.addonList?.map((addon: AddonObj, index: number) =>
                    <Addon
                        addon={addon} index={index} goToCreate={props?.goToCreate}
                        addonList={props?.addonList} setAddonList={props?.setAddonList}
                        addonDetailsList={props?.addonDetailsList} setAddonDetailsList={props?.setAddonDetailsList} />)}
            </ModalBody>
            <ModalFooter className='d-flex justify-content-center'>
                <div className='w-100 py-2 d-flex justify-content-between align-items-center pl-3'>
                    <div>
                        <p className='fw-light text-body pb-2'>{addonsPage?.addonPrice}</p>
                        <p className='text-title3 text-darkgrey fw-semibold'>
                            {addonsPage?.cash} {selectedStadium?.main_currency_label} {accumlativeSum()}</p>
                    </div>
                    <Button color='default' className='py-2 w-40' onClick={sendAddons}>{addonsPage?.add}</Button>
                </div>
            </ModalFooter>
            {sendAddonsResponse?.isLoading ? <Loader /> : null}
        </>
    )
}

export default AddonsList