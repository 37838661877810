import React, { createContext, useContext, useReducer } from "react";
const AuthContext = createContext();
const AuthDispatchContext = createContext();
const initialState = {};

function AuthReducer(state, action) {
	switch (action.type) {
		case "Company_Logo": {
			return {
				...state,
				companyLogo: action.companyLogo,
				avatarID: action.avatarID,
			};
		}

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

function useAuthState() {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error("useAuthState must be used within a AuthProvider");
	}
	return context;
}

function useAuthDispatch() {
	const context = useContext(AuthDispatchContext);
	if (context === undefined) {
		throw new Error("useAuthDispatch must be used within a AuthProvider");
	}
	return context;
}

function AuthProvider({ children }) {
	const [state, dispatch] = useReducer(AuthReducer, initialState);
	return (
		<AuthContext.Provider value={state}>
			<AuthDispatchContext.Provider value={dispatch}>
				{children}
			</AuthDispatchContext.Provider>
		</AuthContext.Provider>
	);
}

export { AuthProvider, useAuthState, useAuthDispatch };
