import React, { useState } from 'react';
import logoImg from 'assets/images/logo.png';
import TextField from 'components/Form/TextField/TextField';
import { useNavigate } from 'react-router-dom';
import validate, { isFormValid } from 'helpers/validate';
import { forgetPassword } from 'services/auth';
import { Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import Locale from 'translations';

const ForgotPassword = () => {
  let history = useNavigate();
  const [email, setEmail] = useState('');
  const [spin, setSpin] = useState(false);
  const [errors, setErrors] = useState({});
  const { auth } = Locale;

  const forgetPasswordHandler = async (e) => {
    setSpin(true);
    let requestBody = {
      email,
    };
    if (isFormValid(errors)) {
      const response = await forgetPassword(requestBody);
      if (response?.status === 200) {
        setSpin(false);
        history.push({
          pathname: '/auth/forgot_password_submitted',
          state: { email },
        });
      }
    }
    setSpin(false);
  };

  return (
    <>
      <section className="app-login app-reset">
        <div className="overlay"></div>
        <div className="login-box">
          <div className="logo">
            <Link to={'/'}>
              <img src={logoImg} alt="logo" />
            </Link>
          </div>
          <div className="login-content">
            <h5 className="font-weight-bold mb-3 text-subtitle">
              {auth.forgetPassword}
            </h5>
            <p className="text-body">{auth.enterEmail}</p>
            <div className="my-3">
              <TextField
                placeholder={auth.emailPlaceholder}
                label="Email"
                value={email}
                name="email"
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    ...validate(e.target, {
                      required: true,
                      email: true,
                    }),
                  });
                  setEmail(e.target.value);
                }}
                color={
                  errors?.email?.required || errors?.email?.email
                    ? 'danger'
                    : ''
                }
                errors={errors?.email}
              />
            </div>
            <button
              className="btn btn-default w-100"
              onClick={forgetPasswordHandler}
              disabled={spin}
            >
              {spin ? <Spinner color="light" size="sm" /> : null} {auth.reset}
            </button>
            <p className="text-body pt-4 text-center">{auth.sendLink}</p>
          </div>
        </div>
      </section>
    </>
  );
};
export default ForgotPassword;
