import { ItemInReactSelect } from "Store/Calendar/props"
import { CoachBookingState, CoachCalendarState } from "./props"

const setBookingState = (
    state: CoachCalendarState,
    payload: { payload: CoachBookingState },
) => {
    state.coachBookingState =
        payload.payload
}

const setcurrentView = (
    state: CoachCalendarState,
    payload: { payload: string },
) => {
    state.currentView =
        payload.payload
}

const setBookingModalIsOpened = (
    state: CoachCalendarState,
    payload: { payload: boolean },
) => {
    state.coachBookingModalIsOpened =
        payload.payload
}

const setBookingModalTitle = (
    state: CoachCalendarState,
    payload: { payload: string },
) => {
    state.BookingModalTitle =
        payload.payload
}

const setSelectedCoach = (
    state: CoachCalendarState,
    payload: { payload: ItemInReactSelect },
) => {
    state.selectedCoach =
        payload.payload
}
export default {
    setBookingState,
    setcurrentView,
    setBookingModalIsOpened,
    setBookingModalTitle,
    setSelectedCoach
}