import React, { useEffect, useState } from "react";
import Locale from 'translations';
import BookingLogs from "../ActionLogs/BookingLogs";
import AddonsModal from "../../addonsComponent/AddonsModal";
import PrintReciept from "../PrintReciept/PrintReciept";
import useResetBookingState from "Pages/CalendarPage/Hooks/useResetBookingState";
import Loader from "components/LoadingPage/LoadingPage";

import { Row, Modal, ModalHeader, ModalBody, ModalFooter, Button, Col } from 'reactstrap';
import { useSendNoShowFlagMutation } from "services/NoShowFlag";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import { useLazyFetchReceiptQuery } from "services/FetchReceipt";
import { useAddNotification } from "Pages/CalendarPage/Helpers/addNotification";
import { CalendarSliceActions } from "Store/Calendar";
import { AskToEdit } from "Pages/CalendarPage/Components/AskToEdit/AskToEdit";
import { AskToCancelBooking } from "../AskToCancelBooking/AskToCancelBooking";
import { AskToCancelFixedBooking } from "../AskToCancelFixedBooking/AskToCancelFixedBooking";
import { Permissions } from 'services/user/me';
import { AddonObj } from "Store/Calendar/props";
import { userRoleEnum } from "Pages/Coaches/enums/userRolesEnum";
import { AnalyticsKeys, firebaseAnalytics } from "Analytics/analytics";

const ViewBookingModal = ({ BookingState, opened, setOpenView }) => {
    const dispatch = useDispatch()
    const BookingModalIsOpened = useSelector((state: RootState) => state?.calendar?.BookingModalIsOpened)
    const userRole = useSelector((state: RootState) => state.calendar?.userRole)
    const { NewBookingModalTitle, calendarPage } = Locale;
    const [modalViewIsOpened, setmodalViewIsOpened] = useState(false)
    const [noShowIsApeared, setnoShowIsApeared] = useState(false)
    const [addonsModalIsOpened, setAddonsModalIsOpened] = useState(false)
    const [askToEdit, setaskToEdit] = useState<boolean>(false)
    const [askToCancel, setaskToCancel] = useState<boolean>(false)
    const [askToCancelFixed, setaskToCancelFixed] = useState<boolean>(false)
    const [printUrl, setprintUrl] = useState<string>("")
    const { ModalFooterVar, messages } = Locale;
    const [bookingAfterReset] = useResetBookingState()
    const [requestSendNoShowFlag, responseSendNoShowFlag] = useSendNoShowFlagMutation()
    const [requestReceipt, responseReceipt] = useLazyFetchReceiptQuery()

    useEffect(() => {
        setmodalViewIsOpened(opened)
        const today = new Date();
        const endBookingTime = new Date(BookingState.end)
        if (today > endBookingTime && BookingState.is_single_booking) {
            setnoShowIsApeared(true)
        }
    }, [opened])


    const toggleModalView = () => {
        setmodalViewIsOpened(!modalViewIsOpened);
        setOpenView(false);
    }

    useEffect(() => {
        if (!BookingModalIsOpened && !modalViewIsOpened && bookingAfterReset)
            dispatch(CalendarSliceActions?.setBookingState(bookingAfterReset))

    }, [BookingModalIsOpened, modalViewIsOpened])

    const handleChange = (e) => {
        requestSendNoShowFlag({
            id: BookingState?.id,
            no_show: e.target.checked
        })
            .then((data: any) => {
                if (data.error) {
                    useAddNotification(data?.error?.data?.message, messages?.warning, "danger")
                }
                else
                    dispatch(CalendarSliceActions?.setBookingState({
                        ...BookingState,
                        no_show: data?.data?.data?.no_show
                    }))
            }).catch(() => {
                useAddNotification(messages.errorMsg, messages?.warning, "danger")
            })

    }



    const fetchIframe = () => {
        requestReceipt({ id: BookingState.id })
            .then((data: any) => {
                if (data.error) {
                    useAddNotification(data?.error?.data?.message, messages?.warning, "danger")
                }
                else
                    setprintUrl(data?.data?.url)
            }).catch(() => {
                useAddNotification(messages.errorMsg, messages?.warning, "danger")
            })
    }
    const handleOpenEditModal = () => {
        dispatch(CalendarSliceActions.setBookingModalTitle(NewBookingModalTitle.edit))
        firebaseAnalytics(AnalyticsKeys?.EditBooking)

        if (BookingState.is_single_booking) {

            toggleModalView()
            dispatch(CalendarSliceActions.setBookingModalIsOpened(true))
        }
        else {
            setaskToEdit(true)
        }
    }

    const handleOpenAddonsModal = () => {
        setAddonsModalIsOpened(true)
        firebaseAnalytics(AnalyticsKeys?.AddonsBtn)
    }


    const handleCancelBooking = () => {
        firebaseAnalytics(AnalyticsKeys?.CancelBooking)
        if (BookingState.is_single_booking)
            setaskToCancel(true)
        else {
            setaskToCancelFixed(true)
        }
    }

    return (
        <>
            <Modal
                isOpen={modalViewIsOpened}
                toggle={toggleModalView}
                size="lg"
                className="create-booking view-booking"
            >
                <ModalHeader className="font-weight-bold" toggle={toggleModalView}>
                    <span className="font-weight-bold">{calendarPage?.bookingId} : {BookingState.id}</span>
                </ModalHeader>
                <ModalBody className="py-0">
                    <Row className="mx-0 pt-2">
                        <Col sm="6">
                            <div className="d-flex">
                                <h3 className="font-weight-bold">{calendarPage?.name} : </h3>
                                <h6 className=" pl-1"> {` ${BookingState.booking_name}`}</h6>
                            </div>
                        </Col>
                        <Col sm="6" className="mb-2 d-flex">
                            <h3 className="font-weight-bold">{calendarPage?.phone} : </h3>
                            <h6 className=" pl-1">{BookingState.booking_phone_number}</h6>
                        </Col>
                        <Col sm="6" className="d-flex mb-2">
                            <h4 className="font-weight-bold">{calendarPage?.pitchName} :</h4>

                            <h4 className=" pl-1">{BookingState?.pitch?.lable}</h4>
                        </Col>
                        <Col sm="6" className="d-flex mb-2">
                            <h6 className="font-weight-bold">{calendarPage?.matchDate} : </h6>
                            <h4 className=" pl-1">{BookingState.match_date}</h4>
                        </Col>
                    </Row>
                    <Row className="d-flex mx-0 pb-2 justify-content-end">
                        <Col sm="6" className="d-flex mb-2">
                            <h6 className="font-weight-bold">{calendarPage?.matchTime} : </h6>
                            <h4 className=" pl-1">{`${BookingState.match_time} - ${BookingState.match_end_time}`}</h4>
                        </Col>
                    </Row>
                    <div className="booking-box">
                        <div className="booking-item d-block">
                            <p className="repeat-weekly pt-0 pb-1 font-weight-bold">
                                {BookingState?.is_single_booking
                                    ? calendarPage?.singleBooking
                                    : BookingState?.fixed_booking?.bookings_count ?
                                        `${calendarPage?.fixedandCount} = ${BookingState?.fixed_booking?.bookings_count} `
                                        : calendarPage?.fixedBooking}
                            </p>
                            <div className="w-100 d-flex justify-content-between">
                                <p>{calendarPage?.pricePerBooking}</p>
                                <p>{` ${BookingState.payment_actual_amount}  ${BookingState.payment_currency}`}
                                </p>
                            </div>
                        </div>
                        <div className="booking-item flex-column">
                            <div className="d-flex justify-content-between w-100">
                                <p>{calendarPage?.addons}</p>
                                <p>
                                    {` ${BookingState?.total_addons_amount} ${BookingState?.payment_currency}`}
                                </p>
                            </div>
                            {BookingState?.addons?.length ? BookingState?.addons?.map((item: AddonObj, index) =>
                                item?.count == 0 ? "" :
                                    <div key={index} className=" w-100">
                                        <p className="fw-light text-caption text-muted">{item?.count} x {item?.name}</p>
                                    </div>
                            ) : null}
                        </div>

                        <div className="booking-item">
                            <p>{calendarPage?.amountRecieved}</p>
                            <p>
                                {`  ${BookingState?.amount_paid} ${BookingState?.payment_currency}`}
                            </p>
                        </div>
                        <div className="booking-item">
                            <p>{calendarPage?.netAmount}</p>
                            <p>
                                {`  ${BookingState?.net_amount} ${BookingState?.payment_currency}`}
                            </p>
                        </div>
                        <div className="booking-item">
                            <p>{calendarPage?.couponUsed}</p>
                            <p>
                                {BookingState?.coupon_code !== null ? BookingState?.coupon_code : calendarPage?.noCoupon}
                            </p>
                        </div>
                        {BookingState?.coupon_code !== null ? <div className="booking-item">
                            <div>
                                <p>{calendarPage?.couponDiscount}</p>
                            </div>
                            <div>
                                <p>
                                    {BookingState?.coupon_discount !== 0 ? BookingState?.coupon_discount : ""}
                                </p>
                            </div>
                        </div> : ""}
                        {
                            BookingState?.is_use_credit ?
                                <div className="booking-item">
                                    <p>{calendarPage?.wallet}</p>
                                    <p>
                                        {`  ${BookingState?.credit_amount} ${BookingState?.payment_currency}`}
                                    </p>
                                </div> :
                                null
                        }
                        <div className="booking-item">
                            <p>{calendarPage?.bookingType}</p>
                            <p>
                                {BookingState?.is_coaching_booking ? calendarPage?.coach : calendarPage?.stadium}
                            </p>
                        </div>
                        {
                            BookingState?.is_coaching_booking ?
                                <div className="booking-item">
                                    <p>{calendarPage?.coachName}</p>
                                    <p>
                                        {BookingState?.coach?.name}
                                    </p>
                                </div> :
                                null
                        }

                        {noShowIsApeared ? <div className="booking-item ">
                            <div className='d-flex align-items-center justify-content-center checkBoxDiv'>
                                <label className="container px-0 mb-0">{calendarPage?.noShow}
                                    <input type="checkbox"
                                        onChange={(e) => handleChange(e)}
                                        checked={BookingState.no_show ? true : false}
                                    />
                                    <span className="checkmark"></span>
                                </label>

                            </div>
                        </div> : ""}

                        <div className="booking-item price">
                            <p>{calendarPage?.totalPrice}</p>
                            <p>

                                {BookingState?.payment_actual_amount
                                    ? BookingState?.payment_actual_amount + BookingState?.total_addons_amount
                                    : 0}{' '}{BookingState?.payment_currency}
                            </p>
                        </div>
                    </div>
                </ModalBody>

                <ModalFooter className="pt-1">
                    {BookingState?.booking_note ?
                        <div className="booking_note w-100 mb-2 ">
                            <p className="text-body fw-light">{BookingState?.booking_note}</p>
                        </div> : null}
                    {userRole === userRoleEnum?.admin || !BookingState?.is_user_made ||
                        (BookingState?.is_user_made && Permissions?.EditBooking) ?
                        (
                            <Button color="default"
                                onClick={() => handleOpenEditModal()}
                            >
                                {ModalFooterVar.edit}
                            </Button>
                        ) : null
                    }

                    {userRole === userRoleEnum?.admin || !BookingState?.is_user_made ||
                        (BookingState?.is_user_made && Permissions?.AddAdons) ?
                        (
                            <Button color="default"
                                onClick={() => handleOpenAddonsModal()}
                            >
                                {ModalFooterVar.addons}

                            </Button>
                        ) : null
                    }

                    {BookingState?.is_issued_invoice ? (
                        <Button color="default"
                            onClick={() => fetchIframe()}
                        >
                            {ModalFooterVar.receipt}
                        </Button>
                    ) : null
                    }

                    {userRole === userRoleEnum?.admin || !BookingState?.is_user_made ||
                        (BookingState?.is_user_made && Permissions.CancelBooking) ?
                        (
                            <Button
                                className="cancel-booking"
                                onClick={handleCancelBooking}
                            >
                                {ModalFooterVar.cancel}
                            </Button>
                        ) : null}

                </ModalFooter>
                {Permissions?.AdminViewLogs || Permissions?.PitchOwnerViewLogs ?
                    < BookingLogs ActionLogs={BookingState.action_log} />
                    : ""}


            </Modal >
            <PrintReciept printUrl={printUrl} setprintUrl={setprintUrl} />
            {addonsModalIsOpened ? <AddonsModal
                addonsModalIsOpened={addonsModalIsOpened}
                setAddonsModalIsOpened={setAddonsModalIsOpened}
            /> : null}
            <AskToEdit askToEdit={askToEdit} setasoToEdit={setaskToEdit} toggleModalView={toggleModalView} />
            <AskToCancelBooking setaskToCancelFixed={setaskToCancelFixed} askToCancel={askToCancel} setaskToCancel={setaskToCancel} toggleModalView={toggleModalView} />
            <AskToCancelFixedBooking askToCancelFixed={askToCancelFixed} setaskToCancelFixed={setaskToCancelFixed}
                askToCancel={askToCancel} setaskToCancel={setaskToCancel} />
            {responseSendNoShowFlag?.isLoading || responseReceipt?.isLoading ? <Loader /> : null}
        </>
    )
}

export default ViewBookingModal