import React, { useMemo, useState, useEffect, useCallback } from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import Locale from 'translations';
import CustomeHeader from './CustomeHeader';
import CustomWeekView from './CustomWeekView';
import getDaysOffandFemaleDays from '../Helpers/getDaysOffandFemaleDays';
import useResetBookingState from '../Hooks/useResetBookingState';
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'Store/index';
import { useFetchCalendarMutation } from 'services/bookings';
import { BookingState, paymentMethodOfStadium } from 'Store/Calendar/props';
import { useFetchDiscountedSlotsMutation } from 'services/discountSlots';
import { CustomEventFunction } from './CustomEvent';
import { CalendarSliceActions } from 'Store/Calendar/index';
import { useAddNotification } from '../Helpers/addNotification';
import { getDaysOffInEachSlot } from '../Helpers/getDaysOffInEachSlot';
import { generateLayout } from '../Helpers/generateLayout';
import { Loader } from '../Components';
import { useGetDaysOffOfStadiumMutation } from 'services/getDaysOffOfStadium';
import { getPaymentMethod } from '../Helpers/getPaymentMethod';
import { useMalaebState } from 'context/global';
import { MutationDefinition } from '@reduxjs/toolkit/dist/query';
import { MutationActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { Body } from 'services/bookings/calendar';

export default function CalendarSection(props) {
    const { NewBookingModalTitle, calendarPage, messages } = Locale;
    const [bookingAfterReset] = useResetBookingState()
    const dispatch = useDispatch()
    const { locale } = useMalaebState()
    const pitch = useSelector((state: RootState) => state.calendar.pitch)
    const pitchId = useSelector((state: RootState) => state.calendar.pitchId)
    const selectedStadium = useSelector((state: RootState) => state.calendar.selectedStadium)
    const BookingState = useSelector((state: RootState) => state.calendar.BookingState)
    const pitchesForStadium = useSelector((state: RootState) => state.calendar.pitchesforStadium)
    const [daysOffofStadium, setDaysOffofStadium] = useState<any[]>([])
    const [femaleFrienlyTimes, setFemaleFrienlyTimes] = useState<any[]>([])
    const [discountSlots, setDiscountSlots] = useState([])
    const [currentView, setCurrentView] = useState(Views.WEEK);
    const [myEvents, setEvents] = useState([]);

    const [requestOfCalendar, responseOfCalendar] = useFetchCalendarMutation()
    const [requestOfDiscount, responseOfDiscount] = useFetchDiscountedSlotsMutation()
    const [requestOfDaysOff, responseOfDaysOff] = useGetDaysOffOfStadiumMutation()

    let resources = pitchesForStadium && pitchesForStadium?.map((pitch) => {
        return {
            id: pitch.id,
            title: pitch.lable,
            title_ar: pitch?.lable_ar,
            subTitle: `(${pitch.pitch_size} * ${pitch.pitch_size})`
        };
    });
    const messagesOfCalendar = {
        previous: calendarPage?.back,
        next: calendarPage?.next,
        week: calendarPage?.week,
        day: calendarPage?.day,
    }
    const localizer = momentLocalizer(moment);
    moment.locale('en', {
        week: {
            dow: 1,
            doy: 1,
        },
    });
    const { views } = useMemo(() => ({
        views: {
            week: CustomWeekView,
            day: true
        },
    }), [])

    const onView = (view: string) => {
        if (view === 'week') {
            props.setDateFrom(moment(props.currentDate).startOf('isoWeek').format('YYYY-MM-DD'));
            props.setDateTo(moment(props.currentDate).endOf('isoWeek').format('YYYY-MM-DD'));
        } else {
            props.setDateFrom(moment(props.currentDate).subtract(1, 'd').format('YYYY-MM-DD'));
            props.setDateTo(moment(props.currentDate).add(1, 'd').format('YYYY-MM-DD'));
        }
        setCurrentView(view);
        dispatch(CalendarSliceActions?.setcurrentView(view)) // current pitch id

    };

    const onNavigate = (date, view: string) => {
        props.setCurrentDate(date);
        if (view === 'week') {

            props.setDateFrom(moment(date).format('YYYY-MM-DD'));
            props.setDateTo(moment(date).add(6, 'd').format('YYYY-MM-DD'));
        } else {
            props.setDateFrom(moment(date).subtract(1, 'd').format('YYYY-MM-DD'));
            props.setDateTo(moment(date).add(1, 'd').format('YYYY-MM-DD'));
        }
    };
    const eventStyleGetter = (event, start, end, isSelected) => {
        let backgroundColor;
        if (event.is_coaching_booking == 1) {
            backgroundColor = 'rgb(68, 95, 190)'
        }
        else {
            if (event.booking_type === 1) { backgroundColor = '#98c11d' } //owner created 
            else if (event.booking_type === 2) { backgroundColor = '#39A3DC' }//user created
            else { backgroundColor = '#C5C5C5' }
        }
        const style = {
            backgroundColor,
            borderRadius: '0px',
            opacity: 1,
            color: 'white',
            border: '0px',
            display: 'block',
            fontSize: '14px',
            innerHeight: "150px"
        };
        return { style }
    };
    const handleSelectSlot = ({ start, end, resourceId, action, slots }) => {
        const newDate = moment(slots[0]).startOf('day').format('YYYY-MM-DD')
        let check: boolean
        if (Views.WEEK === currentView)
            check = getDaysOffInEachSlot(daysOffofStadium, slots[0], newDate)

        else {
            let daysOffforPitch = daysOffofStadium?.filter((day) => ((day.pitch_ids?.map((item) => String(item))).includes(String(resourceId))))
            check = getDaysOffInEachSlot(daysOffforPitch, slots[0], newDate)
        }
        if (check) {
            useAddNotification(calendarPage?.cannottCreate, messages?.info, "warning")
        }
        else {
            if (slots?.length > 1 && action === 'select') {
                useAddNotification(calendarPage?.selectOne, messages?.info, "warning")
            }
            else {
                props.setSlotInfo({
                    start_time: moment(start).format('HH:mm:ss'),
                    end_time: moment(end).format('HH:mm:ss'),
                    pitch_id: Views.WEEK === currentView ? pitchId : resourceId
                })
                dispatch(CalendarSliceActions.setBookingModalIsOpened(true))
                dispatch(CalendarSliceActions.setBookingModalTitle(NewBookingModalTitle.create))
                if (bookingAfterReset) {
                    dispatch(CalendarSliceActions.setBookingState({
                        ...bookingAfterReset,
                        match_date: moment(start),
                        pitch_id: resourceId ?? pitchId,
                        match_time: moment(start).format('HH:mm:ss'),
                        fixed_booking: {
                            last_match_date: "",
                            day_of_week: moment(start).day()
                        }
                    }))
                }
            }
        }
    }

    useEffect(() => {
        let myCalendarRequest
        let discountRequest
        if (pitchId && selectedStadium) {
            if (Views.WEEK === currentView) {
                if (pitch) getDaysOffandFemaleDays(pitch, setFemaleFrienlyTimes)
                if (!BookingState.match_date) {
                    const data = {
                        from_date: props.dateFrom,
                        to_date: props.dateTo,
                        pitches: [pitchId],
                    }

                    myCalendarRequest = requestOfCalendar(data)
                    myCalendarRequest.then((data: any) => {
                        if (!data.error) {
                            let result = data?.data.data
                            if (result.length > 0) {
                                result = result.map((item: BookingState) => {
                                    let mappingItem = { ...item }
                                    mappingItem.start = moment(mappingItem.start).toDate();
                                    mappingItem.end = moment(mappingItem.end).toDate();
                                    return mappingItem;
                                });
                            }
                            setEvents(result);
                        } else {
                            if (data?.error?.name === 'AbortError') return;
                            else useAddNotification(data?.error?.data?.message, messages.warning, "danger")
                        }

                    }).catch(() => {
                        useAddNotification(messages.errorMsg, messages?.warning, "danger")
                    })
                    discountRequest = requestOfDiscount({
                        start_date: props.dateFrom,
                        end_date: props.dateTo,
                        pitch_id: pitchId,
                        id: selectedStadium?.id
                    })
                    discountRequest.then((data: any) => {
                        if (data?.error) {
                            if (data?.error?.name === 'AbortError') return;
                            else useAddNotification(data?.error?.data?.message, messages.warning, "danger")
                        }
                        else setDiscountSlots(data?.data)
                    }).catch(() => {
                        useAddNotification(messages.errorMsg, messages?.warning, "danger")
                    })
                }

            }
            else {
                setFemaleFrienlyTimes([])
                setDiscountSlots([])
                let pitchesIDs: number[] = []
                pitchesForStadium?.map((pitchObj) => pitchesIDs.push(pitchObj.id))
                if (BookingState.match_date == "") {
                    myCalendarRequest = requestOfCalendar({
                        from_date: props.dateFrom,
                        to_date: props.dateTo,
                        pitches: pitchesIDs,
                    })
                    myCalendarRequest.then((data: any) => {
                        if (!data.error) {
                            let result = data?.data.data
                            if (result.length > 0) {
                                result = result.map((item: BookingState) => {
                                    let mappingItem = { ...item }
                                    mappingItem.start = moment(mappingItem.start).toDate();
                                    mappingItem.end = moment(mappingItem.end).toDate();
                                    return mappingItem;
                                })
                            }
                            setEvents(result);

                        }
                        else {
                            if (data?.error?.name === 'AbortError') return;
                            else useAddNotification(data?.error?.data?.message, messages.warning, "danger")
                        }
                    }).catch(() => {
                        useAddNotification(messages?.errorMsg, messages.warning, "danger")
                    })
                }

            }

        }
        return () => {
            myCalendarRequest?.abort()
            discountRequest?.abort()
        }
    }, [props.currentDate, props.dateFrom, props.dateTo, pitchId, BookingState.match_date, currentView])

    useEffect(() => {
        let myRequest
        if (selectedStadium) {
            myRequest = requestOfDaysOff({ stadiumId: selectedStadium?.id })
            myRequest.then((data: any) => {
                setDaysOffofStadium(data?.data?.data)
            }).catch(() => {
                useAddNotification(messages?.errorMsg, messages.warning, "danger")
            })
            let paymentMethodsArray = selectedStadium?.supported_payment_methods
            let paymentOptions: paymentMethodOfStadium[] = []
            paymentMethodsArray?.map((item) => {
                paymentOptions.push({
                    id: Number(item),
                    label: getPaymentMethod(Number(item)),
                    value: Number(item),

                })
                return paymentOptions
            })
            dispatch(CalendarSliceActions?.setpaymentMethodOfStadium(paymentOptions))
        }
        return () => {
            myRequest?.abort()
        }
    }, [selectedStadium])


    useEffect(() => {
        if (bookingAfterReset)
            dispatch(CalendarSliceActions?.setBookingState(bookingAfterReset))
    }, [bookingAfterReset])

    useEffect(() => {
        if (pitchesForStadium && pitchId == 0) {
            dispatch(CalendarSliceActions?.setPitch(pitchesForStadium?.[0]))
            dispatch(CalendarSliceActions?.setPitchId(pitchesForStadium?.[0]?.id))
        }
    }, [pitchId])

    const customSlotPropGetter = (slot, resourceId) => {
        return generateLayout(pitchId,slot, resourceId, femaleFrienlyTimes, daysOffofStadium, discountSlots, currentView, selectedStadium)
    }

    return (
        <>
            <Calendar
                culture="ar"
                messages={messagesOfCalendar}
                scrollToTime={moment().set({ h: 20, m: 0 }).toDate()}
                step={30}
                localizer={localizer}
                events={myEvents}
                startAccessor="start"
                endAccessor="end"
                date={props.currentDate}
                onSelectSlot={handleSelectSlot}
                selectable
                selected
                onSelectEvent={props.handleSelectEvent}
                views={views}
                defaultView={currentView}
                resources={currentView === Views.WEEK ? null : resources}
                onView={onView}
                onNavigate={onNavigate}
                components={{
                    event: (props) => {
                        return (
                            <CustomEventFunction item={props} />
                        )
                    },
                    resourceHeader: (props: any) => {
                        return (
                            <CustomeHeader
                                title={locale == "en" ? props.resource.title : props.resource.title_ar}
                                subTitle={props.resource.subTitle}
                            />
                        );
                    },
                }}
                toolbar={(e) => console.log('eee', e)}
                eventPropGetter={eventStyleGetter}
                slotPropGetter={customSlotPropGetter}
                showMultiDayTimes={true}
            />
            {responseOfCalendar?.isLoading || responseOfDaysOff?.isLoading ? <Loader /> : null}

        </>
    )
}
