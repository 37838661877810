import React, { useState } from 'react';
import logoImg from 'assets/images/logo.png';
import { Link } from 'react-router-dom';
import Mail from 'assets/images/mail.png';
import { useEffect } from 'react';
import Locale from 'translations';
import { forgetPassword } from 'services/auth';

const ForgotPasswordSubmitted = (props) => {
  const { success } = Locale;
  const [counter, setCounter] = React.useState(50);
  const [isResend, setIsResend] = useState(false);

  const resendEmailVerification = () => {
    let requestBody = {
      email: props.location.state.email,
    };
    forgetPassword(requestBody);
    setIsResend(true);
  };

  useEffect(() => {
    if (isResend) {
      const timer =
        counter > 0
          ? setInterval(() => setCounter(counter - 1), 700)
          : setIsResend(false);
      return () => {
        clearInterval(timer);
      };
    }
  }, [counter, isResend]);

  return (
    <>
      <section className="app-login app-success">
        <div className="overlay"></div>
        <div className="login-box">
          <div className="logo">
            <Link to={'/'}>
              <img src={logoImg} alt="logo" />
            </Link>
          </div>
          <div className="login-content text-center">
            <h5 className="font-weight-bold mb-3 text-subtitle text-green">
              {success.submittedSuccessfully}
            </h5>
            <p className="text-body">{success.passswordCheck}</p>

            <img src={Mail} className="my-3" alt="Submitted Success" title="" />

            {!isResend ? (
              <>
                <p className="text-headline">{success.notReceive}</p>
                <p className="text-headline">
                  {success.checkSpam}
                  <button
                    className="btn-clear text-green"
                    onClick={resendEmailVerification}
                  >
                    {success.resendEmail}
                  </button>
                </p>
              </>
            ) : (
              <p>
                {success.pleaseWait}{' '}
                <span className="text-danger">00:{counter}</span>{' '}
                {success.receiveMail}
              </p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotPasswordSubmitted;
