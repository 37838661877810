import React from 'react'
import Locale from 'translations'
import { getBookingPaymentImage } from '../Helpers/getImageOfPayment'
import { ItemOfCalendarEvents } from './CustomEvent'

const CustomEventDayView = (props: ItemOfCalendarEvents) => {
    const { calendarPage } = Locale

    return (

        <div className='d-flex justify-content-between px-2 py-1 align-items-center'>
            <div className='w-40'>
                <h6 className="font-weight-bold pb-1 text-left">
                    {props?.item.event.booking_name}
                </h6>
                <p className='font12 d-flex align-items-center '>
                    {props?.item.event.is_single_booking ? (
                        <>
                            <span className='point mr-1'></span>
                            <span > {calendarPage?.singleBooking}</span>
                            <span className='pl-2 font14'>
                                {props?.item.event.booking_note ? <i className="fa-solid fa-book"></i> : ""}</span>
                        </>
                    ) : (
                        <>
                            <span className={`point mr-1 ${props?.item.event?.fixed_booking?.is_expiring_soon
                                ? 'bg-danger'
                                : ''
                                }`}></span>
                            <span
                                className={`${props?.item.event?.fixed_booking?.is_expiring_soon
                                    ? 'text-danger'
                                    : ''
                                    }`}
                            >
                                {calendarPage?.fixedBooking}
                            </span>

                        </>
                    )}
                </p>
            </div>
            <span className='text-caption1 pl-3 text-danger font-bold text-center'>{props?.item.event.no_show ? calendarPage?.noShow : ""}</span>

            <div className='d-flex align-items-center w-50 justify-content-end pl-1'>
                <div className='imgDiv text-right'>
                    <img className='w-25' src={getBookingPaymentImage(props?.item.event)} />

                </div>

                <div className='pl-1'>
                    <h3 className='font-bold text-title3'>
                        {props?.item.event.net_amount} {props?.item.event.payment_currency}
                    </h3>
                    <p className='text-caption'>{props?.item.event.is_used_coupon ? calendarPage?.couponUsed : null}</p>

                </div>

            </div>
        </div>
    )
}

export default CustomEventDayView