import { api } from 'services/api'
import getNumberOfBookings from './getNumberOfBookings'

const useApi = api.injectEndpoints({
    endpoints: build => ({
        getNumberOfBookings: getNumberOfBookings(build),
    }),
    overrideExisting: false,
})

export const {
    useGetNumberOfBookingsMutation
} = useApi
