import { Dispatch, SetStateAction } from 'react';
import { Pitch, Price } from 'services/user/me';


const getDaysOffandFemaleDays = (pitch: Pitch, setFemaleFrienlyTimes: Dispatch<SetStateAction<Price[]>>) => {

    if (pitch)
        setFemaleFrienlyTimes(pitch?.prices?.filter((price) => price?.female_friendly === 1))
}

export default getDaysOffandFemaleDays;