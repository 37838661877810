import { api } from 'services/api'
import getAvailablePitchesToSwap from './getAvailablePitchesToSwap'

const useApi = api.injectEndpoints({
    endpoints: build => ({
        getAvailablePitchesToSwap: getAvailablePitchesToSwap(build),
    }),
    overrideExisting: false,
})

export const {
    useGetAvailablePitchesToSwapMutation
} = useApi


