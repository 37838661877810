import React from 'react';
import SidebarLayout from './SidebarLayout';

declare global {
    interface Window {
        intercomSettings: string;
    }
}
const Sidebar = () => {
    return (
        <SidebarLayout  />

    )
}

export default Sidebar 