import { StadiumDetailsInterface, StadiumDetailsState } from './props';

const setStadiumManageState = (
    state: StadiumDetailsState,
    payload: { payload: StadiumDetailsInterface },
) => {
    state.stadiumDetails = {
        ...state.stadiumDetails,
        ...payload.payload,
    }
}
export default {
    setStadiumManageState
}