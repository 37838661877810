import moment from "moment";

export const getDaysOffInEachSlot = (daysOff, slot, newDate) => {
    let checkOfSlot;
    const defaultFormat = "YYYY-MM-DD HH:mm";
    daysOff && daysOff?.forEach(element => {
        const startDate = moment(element?.start_at + element?.start_time_at, defaultFormat).toDate()
        const endDate = moment(element?.end_at + element?.end_time_at, defaultFormat).toDate()
        const checkIfDayOff = moment(slot).isBetween(startDate, endDate, null, '[]')
        if (checkIfDayOff) {
            const startDateOfSlot = moment(newDate + element?.start_time_at, defaultFormat).toDate()
            const endDateOFSlot = moment(newDate + element?.end_time_at, defaultFormat).toDate()
            checkOfSlot = moment(slot).isBetween(startDateOfSlot, endDateOFSlot, null, '[]')
        }
    })

    return checkOfSlot
}