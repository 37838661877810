import React, { useRef } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';

interface TimePickerProps {
    color?: string,
    label?: string,
    disabled?: boolean,
    readOnly?: boolean,
    value?: any,
    onChange?: any
    name: string
    error: string
    minTime?: any
    onBlur?: {
        (e: React.FocusEvent<any, Element>): void
    }
    maxTime?: any
    ampm?: boolean
    key?: string
}

const TimePickerComponent = (props: TimePickerProps) => {
    const clockRef = useRef<any>(null);
    const clickOnClock = (e) => { clockRef.current?.click() }
    return (
        <>
            <div className={`my-2 control-field + ${props?.error ? ' control-field-- border border-danger' : ''}`}
            >
                {/* Date Range Body */}
                <div
                    className={`control-field__body position-relative ${props?.disabled && props?.readOnly ? 'isDisabled' : ''}`}>
                    {/* Label */}
                    {props?.label ? (
                        <label
                            className={`control-field__label ${props?.error ? ' control-field__label-- text-danger' : ''
                                }`}
                        >
                            {props?.label}
                        </label>
                    ) : null}
                    <div className="row no-gutters justify-content-between timePicker">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                key={props?.key}
                                ampm={props?.ampm ?? false}
                                ref={clockRef}
                                minTime={props?.minTime}
                                maxTime={props?.maxTime}
                                openTo="hours"
                                views={['hours', 'minutes']}
                                className='w-100 textTimePicker'
                                value={dayjs(props?.value, 'HH:mm:ss')}
                                onChange={props?.onChange}
                                InputProps={{ readOnly: true }}
                                minutesStep={30}
                                renderInput={(params) => {
                                    return <TextField
                                        variant="standard"
                                        focused={false}
                                        onClick={clickOnClock}
                                        name={props?.name}
                                        {...params} />
                                }}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
            </div>
            <small
                className={`text-caption control-field__feedback control-field__ d-block error-message text-danger`}
            >
                {props?.error}
            </small>
        </>
    );
}

TimePickerComponent.defaultProps = {
    color: "#000000",
    label: "Select",
    disabled: false,
    readOnly: false,
    value: undefined,
    onChange: () => { }
}

export default TimePickerComponent;
