import { api } from 'services/api'
import getProfileInCoachApp from './getProfileInCoachApp'

const useApi = api.injectEndpoints({
  endpoints: build => ({
    getProfileInCoachApp: getProfileInCoachApp(build),
  }),
  overrideExisting: false,
})

export const {
    useGetProfileInCoachAppMutation,
} = useApi
