import React from 'react';
import BookingLogsDetails from './BookingLogsDetails';
import moment from 'moment';
import Locale from 'translations';
import { getActionLogValues } from './helpers/getActionLogValues';
const BookingLogs = ({ ActionLogs }) => {
    const { calendarPage } = Locale
    return (
        <>
            {
                ActionLogs ? <>
                    {ActionLogs.map((log) => {
                        console.log(log?.action)
                        let time = moment(log.created_at).format("YYYY-MM-DD hh:mm A")
                        return <>
                            <div className='actionLogs w-75 m-auto'>
                                <div className='bookingLog p-3'>
                                    <div className=' d-flex justify-content-between '>
                                        <div className='w-40 headersOfLogs'>
                                            <h5 className='bookingCreationType bold-text pb-1 text-left'>{getActionLogValues(log.action, "status")}</h5>
                                            <h5 className='bold-text pb-1 text-left'>{calendarPage?.by} : {log.user_data.name}</h5>
                                            <h5 className='bold-text pb-1 text-left'>{calendarPage?.role} : ({log.user_data.role})</h5>
                                        </div>
                                        <BookingLogsDetails
                                            data={log.data} />
                                    </div>
                                    <h6 className='font-medium font14 dateOfEdit'>{time}</h6>
                                    <div className='clrDiv'></div>

                                </div>
                            </div>
                        </>
                    }
                    )}
                </>
                    : ""
            }
        </>
    );
}
export default BookingLogs;
