export const getTimeIntervalsForDiscounts = (discountDays, time_intervals, newTime, className) => {
    let price_90, price_60, price_120;
    discountDays?.map((day) => {

        price_90 = day.price_90;
        price_60 = day.price_60;
        price_120 = day.price_120;
        day?.time_intervals?.forEach((element) => {
            if (element == newTime) {
                className = className === 'FemaleFriendlySlot' ? `FemaleDiscountSlot ` : `DiscountSlot`
            }
        })
    })

    return className
}