export const checkPrice = (values, setValues) => {
    let payment_actual_amount = values.payment_actual_amount;
    let amount_paid = values.amount_paid;
    if (values.is_single_booking) {
        if (payment_actual_amount == amount_paid && amount_paid != '') {
            setValues({
                ...values,
                [`alreadyPaid`]: true,
                [`pending_price`]: 0,
            })
        }
        else {
            let pending_price = payment_actual_amount - amount_paid;
            if (pending_price < 0) pending_price = 0;
            setValues({
                ...values,
                [`alreadyPaid`]: false,
                [`pending_price`]: pending_price
            })
        }
    }
    else {
        let numberOfBookings = values.numberOfBookings;
        let totalPrice = payment_actual_amount * numberOfBookings;
        if (amount_paid == totalPrice && amount_paid != '' ) {
            setValues({
                ...values,
                [`alreadyPaid`]: true,
                [`pending_price`]: 0
            })

        } else {
            let pending_price = payment_actual_amount * numberOfBookings - amount_paid;
            let totalPrice = payment_actual_amount * numberOfBookings;
            if (pending_price < 0) pending_price = 0;
            setValues({
                ...values,
                [`alreadyPaid`]: false,
                 pending_price,
                 totalPrice

            })
        }
    }
}
