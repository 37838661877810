export default function malaebReducer(state, action) {
	switch (action.type) {
		case "setLocale":
			window.location.reload();
			localStorage.setItem("currentLocale", action.payload);
			return { ...state, locale: action.payload };

		case "setLoading":
			return { ...state, loading: action.payload };

		case "login":
			localStorage.setItem("isAuth", true);
			localStorage.setItem("token", action.token);
			localStorage.setItem("userInfo", JSON.stringify(action.userInfo));
			return {
				...state,
				isAuth: action.isAuth,
				token: action.token,
				userInfo: action.userInfo,
				companyInfo: action.companyInfo,
				userPermissions: action?.userInfo?.permissions,
			};
		case 'user_Info':
			return {
				...state,
				userInfo: action.userInfo,
			}

		case "logout":
			localStorage.removeItem("isAuth");
			localStorage.removeItem("token");
			localStorage.removeItem("userInfo");
			return {
				...state,
				isAuth: false,
				token: null,
				userInfo: null,
				companyInfo: null,
				defaultStadium: null,
				userPermissions: null
			};

		case 'Save_User_Logo':
			return {
				...state,
				userLogo: action.userLogo,
			};
		case 'Default_Stadium':
			return {
				...state,
				defaultStadium: action.defaultStadium,
			};

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}
