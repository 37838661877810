import React, { useState, useEffect } from 'react'

import { TextField, SelectWithIcon, TimePickerComponent, DatePickerField, TextFieldWithSelect } from 'components/Form';
import { useSelector } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { useLazyFetchPhoneCodesQuery } from 'services/fetchPhoneCodes';
import { RootState } from 'Store/index';
import { phoneCode } from 'Store/Calendar/props';
import { repeatedWeekly } from 'constantsOfProject';
import { Day } from '../Day/Day';
import { getPaymentMethod } from 'Pages/CalendarPage/Helpers/getPaymentMethod';
import { useGetNumberOfBookingsMutation } from "services/getNumberOfBookings"
import { checkPrice } from 'Pages/CalendarPage/Helpers/getPendingPrice';
import { useAddNotification } from 'Pages/CalendarPage/Helpers/addNotification';
import { useUpDateFixedBookingMutation } from 'services/updateFixedBooking';
import { useGetBookingPriceMutation } from 'services/getBookingSlotPrice';
import { getDatatoSubmit } from 'Pages/CalendarPage/Helpers/getDatatoSubmit';
import { useFormik } from 'formik';
import { validationScheme } from 'Pages/CalendarPage/validation/validationFixed';
import { useCreateFixedBookingMutation } from 'services/createFixedBooking';
import { userRoleEnum } from 'Pages/Coaches/enums/userRolesEnum';
import moment from 'moment';
import dayjs from 'dayjs';
import Locale from 'translations';
import Loader from 'components/LoadingPage/LoadingPage';
import { AnalyticsKeys, firebaseAnalytics } from 'Analytics/analytics';
import getBookingType from 'Pages/CalendarPage/Helpers/getBookingVariables';


export default function FixedBooking({ title, toggleModalCreate }) {

  const bookingState = useSelector((state: RootState) => state.calendar.BookingState)
  const paymentMethodOfStadium = useSelector((state: RootState) => state.calendar.paymentMethodsArr)
  const oldDuration = bookingState?.payment_order_duration
  const oldPitchId = useSelector((state: RootState) => state.calendar.pitchId)
  const pitchesForStadium = useSelector((state: RootState) => state.calendar.pitchesforStadium)
  const selectedStadium = useSelector((state: RootState) => state.calendar.selectedStadium)
  const userRole = useSelector((state: RootState) => state.calendar?.userRole)

  const [selectedPitchinModal, setselectedPitchinModal] = useState<any>();
  const [phoneCodes, setPhoneCodes] = useState([]);
  const [errorMsgs, seterrorMsgs] = useState<string[]>([]);
  const [isLoading, setisLoading] = useState<boolean>(false);
  const { NewBookingModalTitle, calendarPage, messages } = Locale;
  const [bookingType, durationTime, durationOthers, paymentMethod] = getBookingType()

  const [NumberOfBookingsrequest] = useGetNumberOfBookingsMutation()
  const [updateRequest] = useUpDateFixedBookingMutation()
  const [phoneCodesReq] = useLazyFetchPhoneCodesQuery()
  const [requestgetPrice] = useGetBookingPriceMutation()
  const [createFixedRequest] = useCreateFixedBookingMutation()
  const onSubmit = (values) => {
    const data = getDatatoSubmit(values, userRole)
    setisLoading(true);
    if (title !== NewBookingModalTitle?.create) {
      const dataforupdate = {
        ...data,
        id: bookingState?.fixed_booking?.id
      }
      updateRequest(dataforupdate).then((data: any) => {
        setisLoading(false);
        if (data.error) {
          useAddNotification(data?.error?.data?.message, messages?.warning, "danger")
        }
        else {
          useAddNotification(calendarPage?.bookingEdited, messages?.success, "success")
          toggleModalCreate()
        }
      }).catch(() => {
        useAddNotification(messages.errorMsg, messages?.warning, "danger")
      })
    }
    else {
      createFixedRequest(data).then((data: any) => {
        setisLoading(false);
        if (data.error) {
          useAddNotification(data?.error?.data?.message, messages?.warning, "danger")
        }
        else {
          useAddNotification(calendarPage?.bookingCreated, messages?.success, "success")
          toggleModalCreate()
        }
      }).catch(() => {
        useAddNotification(messages.errorMsg, messages?.warning, "danger")
      })
    }
  }

  useEffect(() => {
    if (errorMsgs)
      errorMsgs.map((error) => useAddNotification(error[0], messages?.warning, "danger"))
  }, [errorMsgs])

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    setValues,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      amount_paid: bookingState?.amount_paid,
      booking_name: bookingState?.booking_name,
      booking_note: bookingState?.booking_note,
      booking_type: bookingState?.booking_type,
      payment_order_duration: bookingState?.payment_order_duration,
      last_match_date: bookingState?.fixed_booking?.last_match_date,
      day_of_week: bookingState?.fixed_booking?.day_of_week,
      match_date: bookingState?.match_date,
      match_time: bookingState?.match_time,
      payment_actual_amount: bookingState?.payment_actual_amount,
      booking_phone_number: bookingState?.booking_phone_number?.slice(3),
      phoneCode: bookingState?.booking_phone_number ? bookingState?.booking_phone_number?.slice(0, 3) : selectedStadium?.phone_code,
      pitch_id: bookingState?.pitch_id,
      user_id: bookingState?.user_id,
      payment_method: bookingState?.payment_method ? bookingState?.payment_method : paymentMethod?.[0]?.value,
      numberOfBookings: null,
      totalPrice: null,
      paymentMethodOptions: paymentMethod

    },
    onSubmit,
    validateOnChange: true,
    validationSchema: validationScheme,
  })

  const AllWeek = repeatedWeekly?.map((item, i, items) => (
    <Day
      setValues={setValues}
      items={items}
      item={item}
      key={i}
      values={values}
    />
  ));

  useEffect(() => {
    firebaseAnalytics(AnalyticsKeys?.FixedBookingViewed)
    phoneCodesReq({}).then((data: any) => {
      setPhoneCodes(data?.data?.data)
    })
  }, [])

  useEffect(() => {
    if (pitchesForStadium) {
      setselectedPitchinModal(pitchesForStadium?.filter((pitch) => { return pitch.id === values.pitch_id })?.[0]?.lable)
    }
  }, [values.pitch_id])

  useEffect(() => {
    //get payment actual amount if change the total amount
    if ((values.totalPrice != "" || values.totalPrice != 0) && values.numberOfBookings) {

      let payment_actual_amount = values.totalPrice / values.numberOfBookings;
      setValues({
        ...values,
        payment_actual_amount,
      });
    }
  }, [values.totalPrice, values.numberOfBookings])


  //get number of Bookings
  useEffect(() => {
    if (values.last_match_date !== "") {
      const body = {
        first_match_date: moment(values.match_date).format('YYYY-MM-DD'),
        last_match_date: moment(values.last_match_date).format('YYYY-MM-DD'),
        match_time: values.match_time,
        duration: values.payment_order_duration,
        pitch_id: values.pitch_id,
        day_of_week: values.day_of_week,
      };
      NumberOfBookingsrequest(body).then((data: any) => {
        let number = data?.data?.data?.number_of_booking
        setValues({
          ...values,
          ["numberOfBookings"]: number,
        })
      })
    }
  }, [
    values?.day_of_week,
    values.match_date,
    values?.last_match_date
  ])

  useEffect(() => { //get pending price

    if (values.numberOfBookings)
      checkPrice(values, setValues);
  }, [
    values.payment_actual_amount,
    values.amount_paid,
    values.numberOfBookings,
  ])

  const handleClickAlreadyPaid = (e) => {
    if (e.target.checked) {
      firebaseAnalytics(AnalyticsKeys?.MarkAsPaid)
      let totalPrice = values.totalPrice;
      setValues({
        ...values,
        ["alreadyPaid"]: true,
        ["amount_paid"]: totalPrice,
      });
    } else {
      setValues({
        ...values,
        ["alreadyPaid"]: false,
      });
    }
  };

  useEffect(() => {
    if (values.match_time) {
      if (values.fixed_booking?.day_of_week || oldDuration !== values.payment_order_duration || oldPitchId !== values.pitch_id || title === NewBookingModalTitle.create) {
        const body = {
          pitch_id: values.pitch_id,
          match_date: moment(values.match_date).format('YYYY-MM-DD'),
          match_time: values.match_time,
          duration: values.payment_order_duration,
          day_of_week: values?.day_of_week
        }
        requestgetPrice(body).then((data: any) => {
          let price = data?.data?.data.price
          setValues({
            ...values,
            ['payment_actual_amount']: price,
            ['payment_expected_amount']: price
          })
        })
      }
    }
  }, [values.payment_order_duration, values.pitch_id, values.match_date, values.match_time])


  return (
    <>
      <form onSubmit={handleSubmit}>
        <Row className="mx-0">
          <Col sm="6" className="green-label">
            <TextField
              error={touched.booking_name && errors.booking_name ? errors.booking_name : ""}
              placeholder={calendarPage?.bookingNamePlace}
              label={calendarPage?.bookingName}
              value={values.booking_name}
              name="booking_name"
              onChange={handleChange}
            />
          </Col>
          <Col sm="6" className="green-label">
            <TextFieldWithSelect
              error={touched.booking_phone_number && errors.booking_phone_number ? errors.booking_phone_number : ""}
              errorPhoneCode={touched.phoneCode && errors.phoneCode ? errors.phoneCode : ""}
              className="phone-input"
              label={calendarPage?.phone}
              placeholder={calendarPage?.phoneplaceHolder}
              name="booking_phone_number"
              selectName="phoneCode"
              selectPosition="prepend"
              value={values.booking_phone_number}
              onChange={handleChange}
              onChangeSelect={handleChange}
              selectValue={values.phoneCode}
            >
              <option defaultValue="">Select</option>
              {phoneCodes?.map((item: phoneCode) => (
                <option key={item.country_id} value={item.code}>
                  {item.code}
                </option>
              ))}
            </TextFieldWithSelect>
          </Col>
        </Row>
        <Row className="mx-0 mb-1">
          <Col sm="4" className="green-label">
            <DatePickerField
              label={calendarPage?.startDate}
              placeholder={calendarPage?.selectDate}
              name="match_date"
              date={values.match_date ? moment(values.match_date) : ""}
              onChangeDate={(date) => date ? setFieldValue("match_date", moment(date)) : ""}
            />
          </Col>
          <Col sm="4" className="green-label">
            <TimePickerComponent
              error={""}
              label={calendarPage?.startingTime}
              name="match_time"
              value={values.match_time}
              onChange={(newvalue: string) => setFieldValue("match_time", newvalue ? dayjs(newvalue).format("HH:mm:ss") : "")
              }
            />
          </Col>
          {values.payment_order_duration >= 180 ?
            <Col sm="4" className="green-label">
              <SelectWithIcon
                error={touched.payment_order_duration && errors.payment_order_duration ? errors.payment_order_duration : ""}
                label={calendarPage?.duration}
                value={`${values.payment_order_duration} ${calendarPage?.mins}`}
                name="payment_order_duration"
                icon={'fa-solid fa-clock'}
                options={durationOthers}
                placeholder="Select"
                onChange={(e) => setFieldValue("payment_order_duration", e.value)}
              />
            </Col> :
            <Col sm="4" className="green-label">
              <SelectWithIcon
                error={touched.payment_order_duration && errors.payment_order_duration ? errors.payment_order_duration : ""}
                label={calendarPage?.duration}
                value={`${values.payment_order_duration} ${calendarPage?.mins}`}
                name="payment_order_duration"
                placeholder="Select"
                icon={'fa-solid fa-clock'}
                options={durationTime}
                onChange={(e) => setFieldValue("payment_order_duration", e.value)}
              />
            </Col>
          }
          <Col sm="4" className="green-label">
            <DatePickerField
              error={touched.last_match_date && errors?.last_match_date ? errors?.last_match_date : ""}
              label={calendarPage?.endingDate}
              placeholder={calendarPage?.selectDate}
              name="last_match_date"
              date={values?.last_match_date ? moment(values?.last_match_date) : ""}
              onChangeDate={(date) => date ? setValues({
                ...values,
                last_match_date: date
              }) : ""}
            />
          </Col>
          <Col sm="4" className="green-label">
            <div
              className={`repeated-week {createbookingState.durationTime ? '' : 'disabled mt-2 pb-2'
              }`}
            >
              <p className='text-left'>{calendarPage?.repeatedWeekly}</p>
              <ul className="list-unstyled">
                {AllWeek}
              </ul>
            </div>
          </Col>
          {userRole === userRoleEnum?.admin ? <Col sm="4" className="green-label">
            <SelectWithIcon
              label={calendarPage?.bookingType}
              placeholder={calendarPage?.select}
              value={values.booking_type !== null ? values.booking_type === 1 ? calendarPage?.user : calendarPage?.owner : null}
              name="booking_type"
              icon={'fas fa-user'}
              options={bookingType}
              onChange={(e) => setValues({
                ...values,
                [`booking_type`]: e.value,
                [`user_id`]: "",
                [`payment_method`]: e.value == 1 ? paymentMethodOfStadium?.[0]?.value : paymentMethod?.[0]?.value,
                paymentMethodOptions: e.value == 1 ? paymentMethodOfStadium : paymentMethod
              })}
            />
          </Col> : ""}
          {userRole === userRoleEnum?.admin && title == NewBookingModalTitle.edit ?
            <Col sm="6" className="green-label">
              <SelectWithIcon
                label={calendarPage?.editPitch}
                placeholder={calendarPage?.select}
                value={selectedPitchinModal}
                name="EditPitch"
                icon={'fa-solid fa-vector-square'}
                options={pitchesForStadium}
                onChange={(e) => { setFieldValue("pitch_id", e.value) }}
              />
            </Col> : ""
          }

          {values.booking_type == 1 ? <Col sm="6" className="green-label">
            <TextField
              error={touched.user_id && values.booking_type == 1 && errors.user_id ? errors.user_id : ""}
              placeholder={calendarPage?.userId}
              label={calendarPage?.userId}
              value={values.user_id ? values.user_id : ""}
              type="number"
              name="user_id"
              onChange={(e) => setFieldValue("user_id", e.target.value)}
            />
          </Col> : ""}

        </Row>
        <Row className="mx-0 mb-1 ">
          <Col sm="6" className="green-label">
            <SelectWithIcon
              error={touched.payment_method && errors.payment_method ? errors.payment_method : ""}
              label={calendarPage?.paymentMethod}
              placeholder={calendarPage?.select}
              options={values?.paymentMethodOptions}
              value={values.payment_method ? getPaymentMethod(values.payment_method) : ""}
              name="payment_method"
              icon={'fa-regular fa-credit-card'}
              onChange={(e) => { setFieldValue("payment_method", e.value) }}
            />
          </Col>
        </Row>
        <Row className="mx-0 pricing-section align-items-start">
          <Col sm="3">
            <TextField
              error={errors.payment_actual_amount ? errors.payment_actual_amount : ""}
              label={calendarPage?.bookingPrice}
              placeholder="Enter Booking Price"
              value={values.payment_actual_amount}
              name="payment_actual_amount"
              type="number"
              onChange={handleChange}
            />
            <p className="text-gray-300 text-caption mx-1">
              {values.payment_expected_amount ?
                `default price is  ${values.payment_expected_amount} BHD`
                : null}
            </p>
          </Col>
          <Col sm="3" className='pt-2'>
            <p className="text-caption ">{calendarPage?.NumberOfBookings}</p>
            <p
              className={`bg-gray-100 p-2 rounded text-gray-300 ${values.numberOfBookings === '' ? 'py-3' : null
                }`}>
              {values.numberOfBookings}
            </p>
          </Col>
          <Col sm="6">
            <div className="d-flex align-items-center  justify-content-end pb-1">
              <label className="switch-checkbox mb-0" htmlFor="checkbox">
                <input
                  type="checkbox"
                  id="checkbox"
                  onClick={(e) => {
                    handleClickAlreadyPaid(e);
                  }}
                  checked={values.alreadyPaid ? true : false}
                />
                <div className="slider round"></div>
              </label>
              <h6 className="px-2 text-body font-weight-bold">{calendarPage?.alreadyPaid}</h6>
            </div>
            <div className="total-price">
              <h6>{calendarPage?.totalPrice}</h6>
              <div>
                <input className='text-center'
                  type="number"
                  name='totalPrice'
                  value={values.totalPrice}
                  onChange={(e) => setFieldValue("totalPrice", e.target.value)}
                  disabled={values.numberOfBookings ? false : true}
                />
                <span>{selectedStadium?.main_currency_label}</span>
              </div>
            </div>
          </Col>
        </Row>
        {!values.alreadyPaid ?
          <Row className="mx-0 align-items-center pricing-section">
            <Col sm="3">
              <TextField
                error={touched.amount_paid && errors.amount_paid ? errors.amount_paid : ""}
                placeholder={calendarPage?.totalRecieved}
                label={calendarPage?.totalRecieved}
                value={values.amount_paid}
                name="amount_paid"
                onChange={(e) => setFieldValue("amount_paid", e.target.value)}
              />
            </Col>
            <Col sm="6">
              <div className="total-price justify-content-center bg-green-white">
                <p>
                  {values.pending_price} {selectedStadium?.main_currency_label} {calendarPage?.pending}
                </p>
              </div>
            </Col>
          </Row>
          : null}
        <Row className='w-100 mx-0 justify-content-between'>
          <Button color="secondary"
            className='w-48'
            onClick={toggleModalCreate}>
            {calendarPage?.cancel}
          </Button>
          <Button
            color="default"
            className='w-48'
            type='submit'>
            {title == NewBookingModalTitle.create ? NewBookingModalTitle?.createBtn : NewBookingModalTitle?.editBtn}
          </Button>
        </Row>
      </form>
      {isLoading ? <Loader /> : null}

    </>

  )
}