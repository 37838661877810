import { api } from 'services/api'
import SwapPitches from './SwapPitches'

const useApi = api.injectEndpoints({
    endpoints: build => ({
        SwapPitches: SwapPitches(build),
    }),
    overrideExisting: false,
})

export const {
    useSwapPitchesMutation
} = useApi


