import PasswordField from 'components/Form/PasswordField/PasswordField';
import validate, { isFormValid } from 'helpers/validate';
import React,{ useEffect ,useState} from 'react';
import { store } from 'react-notifications-component';
import {
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Col,
} from 'reactstrap';
import { changePassword } from 'services/profile';

const ChangePassword = ({ changePasswordModal, toggleChangePassword }) => {
  const [resetPasswordState, setResetPasswordState] = useState({
    oldPassword: '',
    password: '',
    passwordConfirmation: '',
  });
  const [errors, setErrors] = useState({});
  const isPasswordMatch =
    resetPasswordState.passwordConfirmation === resetPasswordState.password;

  const checkFormErrors = () => {
    setErrors({
      ...errors,
      ...validate(
        { name: 'oldPassword', value: resetPasswordState.oldPassword },
        { required: true, min: 6 }
      ),
      ...validate(
        { name: 'password', value: resetPasswordState.password },
        { required: true, min: 6 }
      ),
      ...validate(
        {
          name: 'confirm',
          value: isPasswordMatch,
        },
        { required: true, confirm: true }
      ),
    });
  };

  //Reset Password State
  const resetPassword = async () => {
    checkFormErrors();
    let requestBoady = {
      old_password: resetPasswordState.oldPassword,
      password: resetPasswordState.password,
      password_confirmation: resetPasswordState.passwordConfirmation,
    };
    if (isFormValid(errors)) {
      const response = await changePassword(requestBoady);
      if (response?.status === 200) {
        store.addNotification({
          title: 'Success',
          message: 'Password Changed successfully',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
        toggleChangePassword();
      }
    }
  };

  useEffect(() => {
    if (!isPasswordMatch) {
      setErrors({
        ...errors,
        ...validate(
          {
            name: 'confirm',
            value: isPasswordMatch,
          },
          {
            required: true,
            confirm: true,
          }
        ),
      });
    } else {
      setErrors({
        ...errors,
        ...validate(
          {
            name: 'confirm',
            value: isPasswordMatch,
          },
          {
            required: false,
            confirm: false,
          }
        ),
      });
    }
  }, [resetPasswordState.password, resetPasswordState.passwordConfirmation]);

  return (
    <Modal
      isOpen={changePasswordModal.isOpen}
      size="lg"
      className="create-booking"
      toggle={toggleChangePassword}
    >
      <ModalHeader className="font-weight-bold" toggle={toggleChangePassword}>
        Change Password
      </ModalHeader>
      <ModalBody className="py-0">
        <Row className="mx-0">
          <Col sm="12">
            <PasswordField
              label="Old Password"
              placeholder="Enter Old Password"
              name="oldPassword"
              onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(
                    { name: 'oldPassword', value: e.target.value },
                    {
                      required: true,
                      min: 6,
                    }
                  ),
                });
                setResetPasswordState({
                  ...resetPasswordState,
                  oldPassword: e.target.value,
                });
              }}
              min={6}
              color={
                errors?.oldPassword?.required || errors?.oldPassword?.min
                  ? 'danger'
                  : ''
              }
              errors={errors?.oldPassword}
            />
          </Col>
          <Col sm="12">
            <PasswordField
              label="New Password"
              placeholder="Enter New Password"
              name="password"
              onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(
                    { name: 'password', value: e.target.value },
                    {
                      required: true,
                      min: 6,
                    }
                  ),
                });
                setResetPasswordState({
                  ...resetPasswordState,
                  password: e.target.value,
                });
              }}
              min={6}
              color={
                errors?.password?.required || errors?.password?.min
                  ? 'danger'
                  : ''
              }
              errors={errors?.password}
            />
          </Col>
          <Col sm="12">
            <PasswordField
              label="Confirm Password"
              placeholder="Repeat Password"
              name="confirm"
              onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(
                    { name: 'confirm', value: !isPasswordMatch },
                    {
                      required: true,
                      confirm: true,
                    }
                  ),
                });
                setResetPasswordState({
                  ...resetPasswordState,
                  passwordConfirmation: e.target.value,
                });
              }}
              min={6}
              color={
                errors?.confirm?.required || errors?.confirm?.confirm
                  ? 'danger'
                  : ''
              }
              errors={errors?.confirm}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="create-footer">
        <Button color="secondary" onClick={toggleChangePassword}>
          Cancel
        </Button>
        <Button color="default" onClick={resetPassword}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ChangePassword;
