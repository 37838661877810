import { api } from 'services/api'
import sendAddons from './addons'

const useApi = api.injectEndpoints({
    endpoints: build => ({
        sendAddons: sendAddons(build),
    }),
    overrideExisting: false,
})

export const {
    useSendAddonsMutation
} = useApi
