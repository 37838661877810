import { RewardInList } from "services/RewardsListAPI/getRewardsList"
import { LoyaltyState } from "./props"

const setSelectedReward = (
    state: LoyaltyState,
    payload: { payload: RewardInList },
) => {
    state.selectedReward = {
        ...state.selectedReward,
        ...payload.payload,
    }
}
export default {
    setSelectedReward
}