import { api } from 'services/api'
import getPitchCoach from './getPitchCoach'

const useApi = api.injectEndpoints({
  endpoints: build => ({
    getPitchCoach: getPitchCoach(build),
  }),
  overrideExisting: false,
})

export const {
  useGetPitchCoachMutation
} = useApi
