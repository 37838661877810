import axios from 'axios';
import { useMalaebDispatch, useMalaebState } from 'context/global';
import { store } from 'react-notifications-component';
const URL = process.env.REACT_APP_AUTH_API;

let axiosInterceptor = null;
let axiosRequestInterceptor = null;
const AxiosConfiguration = () => {
  let currentLocale = localStorage.getItem('currentLocale') ?? 'en';
  let { token } = useMalaebState();
  const dispatch = useMalaebDispatch();

  axios.defaults.headers.common['X-Locale'] = currentLocale;
  axios.defaults.headers.common['App-Platform'] = "malaebBusiness";
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  
  let counter = 0;
  if (!!axiosInterceptor || axiosInterceptor === 0) {
    axios.interceptors.response.eject(axiosInterceptor);
  }

  if (!!axiosRequestInterceptor || axiosRequestInterceptor === 0) {
    axios.interceptors.request.eject(axiosRequestInterceptor);
  }
  axiosRequestInterceptor = axios.interceptors.request.use(
    (request) => {
      counter++;
      if (request.data && request.data.hasOwnProperty('search')) {
        return request;
      }
      else if (request.url === `${URL}/calendar` || request.url === `${URL}/auth/me` || request.url == `${URL}/phone-codes`) {
        return request;
      }
      else {
        dispatch({ type: 'setLoading', payload: true });
        return request;
      }
    },
    (error) => {
      counter--;
    }
  );

  axiosInterceptor = axios.interceptors.response.use(
    (response) => {
      counter--;
      // if (!response.config.url.includes("view_user_profile")) {
      if (counter === 0) {
        dispatch({ type: 'setLoading', payload: false });
        return response;
      } else if (response.config.url == `${URL}/calendar` || response.config.url == `${URL}/auth/me` || response.config.url == `${URL}/phone-codes`) {
        return response
      }
      else {
        return response;
      }
    },

    (error) => {
      counter--;
      // Show Alert FOr Error
      if (counter === 0) {
        dispatch({ type: 'setLoading', payload: false });
      }
      if (error.response && error.response.status === 400) {
        store.addNotification({
          title: 'Something Went Wrong!',
          message: error.response.data.message,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      } else if (error.response && error.response.status === 401) {
        store.addNotification({
          title: 'Unauthenticated!',
          message: 'Go Back to Login',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
        localStorage.removeItem('isAuth');
        localStorage.removeItem('token');
        localStorage.removeItem('currentLocale');
        window.location.href = '/auth/login';
      } else if (error.response && error.response.status === 422) {
        let errorsMsgs = ``;
        Object.values(error.response.data.errors).map(
          (msg) => (errorsMsgs += msg + '\n')
        );
        store.addNotification({
          title: error.response.data.message,
          message: errorsMsgs,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      } else if (error.response && error.response.status === 500) {
        store.addNotification({
          title: 'Something Went Wrong!',
          message: 'Internal Server Error, Please try again later',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      } else if (error.response && error.response.status === 404) {
        store.addNotification({
          title: 'Something Went Wrong!',
          message: error.response.data.message ? error.response.data.message : 'Page not found 404',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      }
      return Promise.reject(error);
    }
  );
};

export default AxiosConfiguration;
