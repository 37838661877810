import React, { useState, useEffect, useRef, Dispatch } from 'react';
import { Col, Row } from 'reactstrap';
import { useMalaebDispatch } from 'context/global';
import { fetchUserProfile, uploadFile, updateProfile } from 'services/profile';
import { store } from 'react-notifications-component';
import { useGetProfileInCoachAppMutation } from 'services/getProfileInCoachApp';
import { useSelector } from 'react-redux';
import { userRoleEnum } from 'Pages/Coaches/enums/userRolesEnum';
import { AnalyticsKeys, firebaseAnalytics } from 'Analytics/analytics';
import { RootState } from 'Store';
import { useFetchUserMutation } from 'services/user';
import { UserProfile } from 'services/user/me';

import Loader from 'components/LoadingPage/LoadingPage';
import TextFieldWithSelect from 'components/Form/TextFieldWithSelect/TextFieldWithSelect';
import useFetchPhoneCodes from 'hooks/useGetAppPhoneCodes';
import validate, { isFormValid } from 'helpers/validate';
import Locale from 'translations';
import ChangePassword from './ChangePassword';
import TextField from 'components/Form/TextField/TextField';
import { useAddNotification } from 'Pages/CalendarPage/Helpers/addNotification';

const AccountSettings = () => {
  const dispatch: Dispatch<any> = useMalaebDispatch();
  const userInfo = useSelector((state: RootState) => state.calendar.user)
  const userRole = useSelector((state: RootState) => state.calendar?.userRole)
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<any>({});
  const [phoneCodes] = useFetchPhoneCodes();
  const [file, setFile] = useState(userInfo?.image_url);
  const { commons, accountSettingPage, messages } = Locale;
  const [profileState, setProfileState] = useState<UserProfile>({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    phoneCode: '',
    id: 0
  });
  const [requestProfileCoach, responseProfileCoach] = useGetProfileInCoachAppMutation()
  const [requestProfilePO, responseProfilePO] = useFetchUserMutation()
  const [changePasswordModal, setChangePasswordModal] = useState({
    isOpen: false,
  });


  useEffect(() => {
    firebaseAnalytics(AnalyticsKeys?.AccountPageViewed)
    if (userRole) {
      if (userRole === userRoleEnum.coach) {
        requestProfileCoach({}).then((data: any) => {
          const user: UserProfile = data?.data?.data
          setProfileState({
            ...profileState,
            first_name: user.profile.first_name,
            last_name: user.profile.last_name,
            email: user.email,
            phone_number: user?.phone_number.toString().slice(3),
            phoneCode: user?.phone_number.toString().slice(0, 3),
          });
          setFile(user?.image_url)
        })
      }
      else {
        requestProfilePO({}).then((data: any) => {
          const user = data?.data?.data
          setProfileState({
            ...profileState,
            first_name: user?.first_name,
            last_name: user?.last_name,
            email: user.email,
            phone_number: user?.phone_number.toString().slice(3),
            phoneCode: user?.phone_number.toString().slice(0, 3),
          });
          setFile(user?.image_url)
        })
      }
    }
  }, [userRole]);

  const toggleChangePassword = () => {
    setChangePasswordModal({
      isOpen: !changePasswordModal.isOpen,
    });
  };

  const onFileChange = async (e) => {
    const fileType = e.target.files[0];
    const formData = new FormData();
    formData.append('file', fileType);
    formData.append('name', fileType.name);
    formData.append('bucket', 'user');
    if (!fileType.name.match(/.(jpg|jpeg|png|gif|svg)$/i)) {
      useAddNotification(commons.somethingWentWrong, messages?.warning, "danger")
    } else if (fileType.size > 500000) {
      useAddNotification(commons.somethingWentWrong, messages?.warning, "danger")

    } else {
      const response = await uploadFile(formData);
      if (response?.status === 200) {
        useAddNotification(accountSettingPage?.imageAdded, messages?.success, "success")
        setFile(URL.createObjectURL(fileType));
      }
    }
  };

  const handleBtnClick = () => {
    inputFileRef?.current?.click();
  };

  const checkFormErrors = () => {
    setErrors({
      ...errors,
      ...validate(
        { name: 'first_name', value: profileState.first_name },
        { required: true, min: 3 }
      ),
    });
  };

  const submitProfile = async () => {
    checkFormErrors();
    let body = {
      first_name: profileState.first_name,
      last_name: profileState.last_name,
      email: profileState.email,
      phone_number: profileState.phoneCode + profileState.phone_number,
      language_id: 1,
    };
    if (isFormValid(errors)) {
      const response = await updateProfile(body);
      if (response?.status === 200) {
        useAddNotification(accountSettingPage?.accountUpdated, messages?.success, "success")
      }
    }
  };

  return (
    <>
      <div className="profile-section container-fluid">

        <div className="top-bar">
          <h3>{accountSettingPage?.title}</h3>
        </div>
      </div>
      <div className="user-profile container-fluid">
        <div className="img-profile" onClick={handleBtnClick}>
          <input
            type="file"
            ref={inputFileRef}
            onChange={onFileChange}
            className="d-none"
            accept="image/*"
          />
          <div className="img-box">
            <img src={file} alt="img-profile" />
          </div>
          <div className="icon-img">
            <i className="fa-solid fa-camera"></i>
          </div>
        </div>
        <h6 className="profile-title">{accountSettingPage?.updatePhoto}</h6>
        <div className="profile-form">
          <Row className="mx-0">
            <Col sm="6" className="mb-4">
              <TextField
                label={accountSettingPage?.firstName}
                placeholder="Name"
                name="first_name"
                value={profileState?.first_name}
                onChange={(e) => {
                  setProfileState({
                    ...profileState,
                    first_name: e.target.value,
                  });
                  setErrors({
                    ...errors,
                    ...validate(e.target, {
                      required: true,
                      min: 3,
                    }),
                  });
                }}
                min={3}
                color={
                  errors?.first_name?.required || errors?.first_name?.min
                    ? 'danger'
                    : ''
                }
                errors={errors?.first_name}
              />
            </Col>
            <Col sm="6">
              <TextField
                placeholder="Name"
                label={accountSettingPage?.lastName}
                name="Last Name"
                value={profileState.last_name}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    ...validate(e.target, {
                      required: true,
                      min: 3,
                    }),
                  });
                  setProfileState({
                    ...profileState,
                    last_name: e.target.value,
                  });
                }}
                min={3}
                color={
                  errors?.last_name?.required || errors?.last_name?.min
                    ? 'danger'
                    : ''
                }
                errors={errors?.last_name}
              />
            </Col>
            <Col sm="6">
              <Row className="mx-0 align-items-center">
                <Col sm="9">
                  <TextField
                    placeholder="email@gmail.com"
                    label={accountSettingPage?.email}
                    name="email"
                    value={profileState.email}
                    onChange={(e) => {
                      setProfileState({
                        ...profileState,
                        email: e.target.value,
                      });
                      setErrors({
                        ...errors,
                        ...validate(e.target, {
                          required: true,
                        }),
                      });
                    }}
                    color={errors?.email?.required ? 'danger' : ''}
                    errors={errors?.email}
                  />
                </Col>
                <Col sm="3">
                  <button
                    className="btn btn-default w-100 py-2"
                    onClick={toggleChangePassword}
                  >
                    {accountSettingPage?.changePassword}
                  </button>
                </Col>
              </Row>
            </Col>
            <Col sm="6" className="mb-4">
              <TextFieldWithSelect
                className="phone-input"
                label={accountSettingPage?.phone}
                placeholder="Phone Number"
                name="phone_number"
                selectPosition="prepend"
                value={profileState.phone_number}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: 'phone_number', value: e.target.value },
                      {
                        required: true,
                        number: true,
                      }
                    ),
                  });
                  setProfileState({
                    ...profileState,
                    phone_number: e.target.value,
                  });
                }}
                onChangeSelect={(e) => {
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: 'phoneCode', value: e.target.value },
                      { required: true }
                    ),
                  });
                  setProfileState({
                    ...profileState,
                    phoneCode: e.target.value,
                  });
                }}
                selectValue={profileState.phoneCode}
                color={
                  errors?.phone_number?.required || errors?.phone_number?.number
                    ? 'danger'
                    : ''
                }
                errors={errors?.phone_number}
              >
                {phoneCodes?.map((item) => (
                  <option key={item.country_id} value={item.code}>
                    {item.code}
                  </option>
                ))}
              </TextFieldWithSelect>
            </Col>
          </Row>
        </div>
      </div>
      <ChangePassword
        changePasswordModal={changePasswordModal}
        toggleChangePassword={toggleChangePassword}
      />

      <Col className="d-flex flex-row-reverse py-5">
        <button className="btn btn-default w-25 py-2" onClick={submitProfile}>
          {accountSettingPage?.save}
        </button>
      </Col>
      {responseProfileCoach?.isLoading || responseProfilePO?.isLoading ? <Loader /> : null}
    </>
  );
};
export default AccountSettings;
