import React from 'react';
import logoImg from 'assets/images/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import validate, { isFormValid } from 'helpers/validate';
import { passReset } from 'services/auth';
import { store } from 'react-notifications-component';
import { useEffect } from 'react';
import PasswordField from 'components/Form/PasswordField/PasswordField';
import { Spinner } from 'reactstrap';

const ResetPassword = () => {
  const [resetPasswordState, setResetPasswordState] = useState({
    password: '',
    passwordConfirmation: '',
  });
  const [spin, setSpin] = useState(false);
  const [errors, setErrors] = useState({});
  let { email, token } = useParams();
  const history = useNavigate();

  // ----------------------------------------------------------------------------------------------------
  // Component Functions
  // ----------------------------------------------------------------------------------------------------
  const isPasswordMatch =
    resetPasswordState.passwordConfirmation === resetPasswordState.password;

  const checkFormErrors = () => {
    setErrors({
      ...errors,
      ...validate(
        { name: 'password', value: resetPasswordState.password },
        { required: true, min: 6 }
      ),
      ...validate(
        {
          name: 'confirm',
          value: isPasswordMatch,
        },
        { required: true, confirm: true }
      ),
    });
  };

  //Reset Password State
  const resetPassword = async () => {
    checkFormErrors();
    setSpin(true);
    let requestBoady = {
      email,
      hash: token,
      password: resetPasswordState.password,
      password_confirmation: resetPasswordState.passwordConfirmation,
    };
    if (isFormValid(errors)) {
      const response = await passReset(requestBoady);
      if (response?.status === 200) {
        store.addNotification({
          title: 'Created Successfully',
          message: 'Password reseted successfully',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
        setTimeout(function () {
          history.push('/auth/login');
        }, 3000);
      }
    }
  };

  useEffect(() => {
    if (!isPasswordMatch) {
      setErrors({
        ...errors,
        ...validate(
          {
            name: 'confirm',
            value: isPasswordMatch,
          },
          {
            required: true,
            confirm: true,
          }
        ),
      });
    } else {
      setErrors({
        ...errors,
        ...validate(
          {
            name: 'confirm',
            value: isPasswordMatch,
          },
          {
            required: false,
            confirm: false,
          }
        ),
      });
    }
  }, [resetPasswordState.password, resetPasswordState.passwordConfirmation]);

  return (
    <>
      <section className="app-login">
        <div className="overlay"></div>
        <div className="login-box">
          <div className="logo">
            <Link to={'/'}>
              <img src={logoImg} alt="logo" />
            </Link>
          </div>
          <div className="login-content">
            <h5 className="font-weight-bold mb-3 text-subtitle">
              Reset Password
            </h5>
            <p className="text-body">Enter Your New Password</p>
            <PasswordField
              label="Password"
              placeholder="Enter New Password"
              name="password"
              onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(
                    { name: 'password', value: e.target.value },
                    {
                      required: true,
                      min: 6,
                    }
                  ),
                });
                setResetPasswordState({
                  ...resetPasswordState,
                  password: e.target.value,
                });
              }}
              min={6}
              color={
                errors?.password?.required || errors?.password?.min
                  ? 'danger'
                  : ''
              }
              errors={errors?.password}
            />
            <p className="text-body">Confirm Password</p>
            <PasswordField
              label="Password"
              placeholder="Repeat Password"
              name="confirm"
              onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(
                    { name: 'confirm', value: !isPasswordMatch },
                    {
                      required: true,
                      confirm: true,
                    }
                  ),
                });
                setResetPasswordState({
                  ...resetPasswordState,
                  passwordConfirmation: e.target.value,
                });
              }}
              min={6}
              color={
                errors?.confirm?.required || errors?.confirm?.confirm
                  ? 'danger'
                  : ''
              }
              errors={errors?.confirm}
            />

            <button
              className="btn btn-default w-100"
              onClick={resetPassword}
              disabled={spin}
            >
              {spin ? <Spinner color="light" size="sm" /> : null}
              Submit
            </button>
          </div>
        </div>
      </section>
    </>
  );
};
export default ResetPassword;
