import Locale from "translations"

export function getPaymentMethod(payment) {
    const { calendarPage } = Locale
    switch (payment) {
        case 1: {
            return calendarPage?.cash
        }
        case 5:
        case 6: {
            return calendarPage?.tap
        }
        case 11: {
            return calendarPage?.applepay
        }
        default:
            {
                return ''
            }
    }
} 