import { api } from 'services/api'
import UpdateAddon from './UpdateAddon'

const useApi = api.injectEndpoints({
    endpoints: build => ({
        UpdateAddon: UpdateAddon(build),
    }),
    overrideExisting: false,
})

export const {
    useUpdateAddonMutation
} = useApi
