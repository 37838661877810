import { api } from 'services/api'
import getStadiumsOfCoachUser from './getStadiumsOfCoachUser'

const useApi = api.injectEndpoints({
  endpoints: build => ({
    getStadiumsOfCoachUser: getStadiumsOfCoachUser(build),
  }),
  overrideExisting: false,
})

export const {
  useGetStadiumsOfCoachUserMutation
} = useApi
