import { api } from 'services/api'
import cancelSingleBooking from './cancelSingleBooking'

const useApi = api.injectEndpoints({
    endpoints: build => ({
        cancelSingleBooking: cancelSingleBooking(build),
      }),
      overrideExisting: false,
})

export const {
    useCancelSingleBookingMutation
} = useApi