import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
export default (build: EndpointBuilder<any, any, any>) =>
    build.mutation<Response, body>({
        query: body => ({
            url: `/v4/pitch-owner-app/stadiums/${body?.stadiumId}/addons/${body?.id}`,
            method: 'Delete',
            body
        }),
    })

export interface body {
    stadiumId: number,
    id: number

}
