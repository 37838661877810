import React from "react";
import { useMalaebState } from "context/global";
import Locale from 'translations';

const TextFieldWithSelect = (props) => {
	const { commons } = Locale;
	const { locale } = useMalaebState();
	const prependSelect = (
		<div
			className={`${props.className ?? ""} control-field__prepend pt-3 ${props.color ? " control-field__prepend--" + props.color : ""
				}`}
		>
			<select
				className={`control-field__select text-caption${locale === "ar" ? "px-3" : "px-3"}`}
				value={props.selectValue}
				onChange={props.onChangeSelect}
				name={props.selectName}
			>
				{props.children}
			</select>
		</div>
	);

	const appendSelect = (
		<div
			className={`control-field__append pt-3 ${props.errorPhoneCode ? " control-field__append-- text-danger" : ""
				}`}
		>
			<select
				className={`control-field__select ${locale === "ar" ? "pl-3" : "pr-3"}`}
				value={props.selectValue}
				onChange={props.onChangeSelect}
				name={props.selectName}
			>
				{props.children}
			</select>
		</div>
	);


	return (
		<>
			<div
				className={`my-2 input-group control-field ${props.error || props.errorPhoneCode ? " control-field-- border border-danger" : ""
					}`}
			>
				{/* Text Field Prepend */}
				{props.selectPosition === "prepend" ? prependSelect : ""}

				{/* Text Field Body */}
				<div className="control-field__body">
					{/* Label */}
					<label
						className={`control-field__label ${props.error ? " control-field__label-- text-danger" : ""
							}`}
					>
						{props.label}
					</label>

					{/* Text Input */}
					<div className="row no-gutters">
						<input
							className={`${props.className ?? ""} control-field__input w-100 text-caption`}
							id={props.id}
							value={props.value}
							onChange={props.onChange}
							onBlur={props.onBlur}
							placeholder={props.placeholder}
							onKeyUp={props.onKeyUp}
							disabled={props.disabled}
							name={props.name}
						/>
					</div>
				</div>

				{/* Text Field Append */}
				{props.selectPosition === "append" ? appendSelect : ""}
			</div>

			{/* Feedback */}
			<small
				className={`control-field__feedback control-field__feedback--${props.color
					} ${props.isInvalid ? "d-block" : "d-none"}`}
			>
				{props.feedbackMessage}
			</small>
			{/* Error Message */}
			<small className={`text-caption control-field__feedback control-field__feedback-- text-danger d-block error-message`}>
				{` ${props.error} `}
			</small>
			<small className={`text-caption control-field__feedback control-field__feedback-- text-danger d-block error-message`}>
				{`${props.errorPhoneCode}`}
			</small>
		</>
	);
};

export default TextFieldWithSelect;
