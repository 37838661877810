import { CoachesState } from "./props";

export default {

    coach: {
        id: undefined,
        phone_number: "",
        name: "",
        email: "",
        bio: "",
        sport_type_id: 1,
        spoken_languages: [],
        supported_payment_methods: [],
        image: "",
        prices: [],
        available_time_ranges: [],
        price_60: null,
        price_120: null,
        stadiums: undefined
    },
    package:
    {
        name: "",
        price: "",
        number_of_classes: "",
        number_of_users: "",
        time_ranges: undefined,
        supported_payment_methods: undefined,
        stadium: undefined
    }

} as CoachesState