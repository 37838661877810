import React from "react";
import { Route, Link } from "react-router-dom";
import DashboardLayout from "../layouts/DashboardLayout";
import { useMalaebState } from "context/global";
import { userRoleEnum } from "Pages/Coaches/enums/userRolesEnum";
import Error404 from "components/Errors/Error404/Error404";

// React Component
export default function PrivateRoute({ component: Component, ...props }) {
	const { isAuth, userInfo } = useMalaebState();
	const userRole = userInfo?.roles
	if (isAuth && userRole) {
		if (userRole?.[0] === userRoleEnum?.coach) {
			if (props?.path == "/coaches" || props?.path == "/EditDetails" || props?.path == "/profile") {

				return (
					<Route
						render={() => (
							<DashboardLayout>
								<Component {...props} /> {/* PROPS.CHILDREN FE ELL DASHBOARD COMPONENT*/}
							</DashboardLayout>
						)}
					/>
				);
			}
			else {
				return (<Error404 />)
			}

		}
		else {
			return (
				<>
					<Route
						render={() => (
							<DashboardLayout>
								<Component {...props} /> {/* PROPS.CHILDREN FE ELL DASHBOARD COMPONENT*/}
							</DashboardLayout>
						)}
					/>
				</>
			)
		}

	}
	else {

		return <Link to="/auth/login" />;

	}


}
