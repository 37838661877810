import { api } from 'services/api'
import FetchReceipt from './FetchReceipt'

const useApi = api.injectEndpoints({
    endpoints: build => ({
        FetchReceipt: FetchReceipt(build),
    }),
    overrideExisting: false,
})

export const {
    useLazyFetchReceiptQuery
} = useApi