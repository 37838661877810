import React from "react";
import Locale from "translations";
/**
 * @Component TextField
 * @Description This component for any text input group like text, email, etc.
 * @Props
 *    -> type: Type for input field
 *    -> name: Name for input field
 *    -> label: Text label for input field
 *    -> placeholder: Input field placeholder
 *    -> className: HTML classes for input field
 *    -> id: HTML ID
 *    -> color: Bootstrap color
 *    -> value: Input value
 *    -> extraText: Text for prepend input
 *    -> extraTextPostion: prepend || append
 *    -> feedbackMessage: Feedback message for validation
 *    -> onBlur: Input Function
 *    -> onChange: Input Function
 *    -> isInvalid: [Bool] set field is valid or not
 */

const TextField = (props) => {
	const { commons } = Locale;
	const prependText = (
		<div
			className={`control-field__prepend ${props.error ? " control-field__prepend-- text-danger" : ""
				}`}
		>
			<span className="form-control border-0 text-headline text-center pt-2">
				{props.extraText}
			</span>
		</div>
	);

	const appendText = (
		<div
			className={`control-field__append ${props.error ? " control-field__append-- text-danger" : ""
				}`}
		>
			<span className="form-control border-0 text-headline text-center pt-2">
				{props.extraText}
			</span>
		</div>
	);


	return (
		<>
			<div
				className={`my-2 input-group control-field + ${props.error ? " control-field-- border border-danger" : ""
					}`}
			>
				{/* Text Field Prepend */}
				{props.extraTextPosition === "prepend" ? prependText : ""}

				{/* Text Field Body */}
				<div
					className={`control-field__body ${props.disabled ? "isDisabled" : ""
						}`}
				>
					{/* Label */}
					{props.label ? (
						<label
							className={`control-field__label  ${props.error ? " control-field__label--  text-danger" : ""
								}`}
						>
							{props.label}
						</label>
					) : null}

					{/* Text Input */}
					<div className="row no-gutters justify-content-between">
						<input
							defaultValue={props.defaultValue}
							type={props.type}
							name={props.name}
							className={`${props.className ?? ""
								} control-field__input w-100 text-caption`}
							id={props.id}
							min={props.min}
							max={props.max}
							disabled={props.disabled}
							onChange={props.onChange}
							onClick={props.onClick}
							onKeyUp={props.onKeyUp}
							onBlur={props.onBlur}
							placeholder={props.placeholder}
							autoComplete="off"
							onKeyDown={props.onEnter}

							{...props}
						/>
						{/* <span className="text-danger">erroooor</span> */}
					</div>
				</div>

				{/* Text Field Append */}
				{props.extraTextPosition === "append" ? appendText : ""}
				{/* Error Message */}

			</div>

			<small
				className={`text-caption control-field__feedback control-field__ d-block error-message text-danger`}
			>
				{props.error}
			</small>
		</>
	);
};

TextField.defaultProps = {
	label: true,
	defaultValue: true
};

export default TextField;
