import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
export default (build: EndpointBuilder<any, any, any>) =>
    build.mutation<Response, body>({
        query: body => ({
            url: `/v3/pitch-owner-dashboard/number-bookings`,
            method: 'POST',
            body
        }),
    })

export interface body {
    first_match_date: string
    last_match_date: string
    match_time: string,
    duration: number,
    pitch_id: number,
    day_of_week: number|undefined,

}
