import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

export const firebaseStagingConfig = {
    apiKey: "AIzaSyB7KuH5PFcsWMpxek-SIOvz7C9zryaD6Vg",
    authDomain: "malaeb-chat-staging.firebaseapp.com",
    databaseURL: "https://malaeb-chat-staging.firebaseio.com",
    projectId: "malaeb-chat-staging",
    storageBucket: "malaeb-chat-staging.appspot.com",
    messagingSenderId: "473974462234",
    appId: "1:473974462234:web:d268bda8fe75992df27ea3",
    measurementId: "G-FTS8YLZY0M"
};

export const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKETstorageBucket,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SenderId,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics and get a reference to the service
export const analytics = getAnalytics(app);
