import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { Moment } from 'moment'

export default (build: EndpointBuilder<any, any, any>) =>
    build.mutation<Response, Body>({
        query: body => ({
            url: `/v4/pitch-owner-app/bookings/${body?.booking_id}/available-swap-pitches`,
            method: 'POST',
            body,
        }),
    })
interface Body {
    booking_id: number,

}