import moment from "moment"
import Locale from "translations"
import { getPaymentMethod } from "Pages/CalendarPage/Helpers/getPaymentMethod"


export function getActionLogValues(data, key) {
    const { calendarPage } = Locale
    switch (key) {
        case "status": {
            return data === "created" ? calendarPage?.created : calendarPage?.updated
        }
        case "booking_type": {
            return data[key] === 1 ? calendarPage?.user : calendarPage?.owner
        }

        case "booking_status": {
            return data[key] === 1 ? calendarPage?.confirmed : calendarPage?.pending
        }

        case "match_time": {
            return moment(data[key], ["HH:mm:ss"]).format("hh:mm A")
        }
        case "payment_method": {
            return getPaymentMethod(data[key])
        }

        default: {
            return data[key]
        }
    }
}
