import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { UserData } from 'Store/Calendar/props'

export default (build: EndpointBuilder<any, any, any>) =>
    build.mutation<Response, any>({
        query: body => ({
            url: `/v4/coach-dashboard/auth/me`,
            method: 'Get',
        }),
    })

interface Response {
    data: UserData
}