// const [isOpened, setIsOpened] = useState(false);
import React, { useState } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { SingleDatePicker } from 'react-dates';
import { useMalaebState } from 'context/global';
import Locale from 'translations';
import { useRef } from 'react';
import { useEffect } from 'react';
interface myprops {
  color: string,
  hasLabel: boolean,
  label: string,
  date: string,
  disabled: boolean,
  readOnly: boolean,
  placeholder: string,
  onChangeDate: any,
  isOpened: boolean,
}

export default function DatePickerField(props) {
  const [focused, setFocused] = useState(false);
  const selectRef = useRef(null);
  const handleClickOutside = (e) => {
    setFocused(true);

    setFocused(!focused);
  };

  useEffect(() => {
    if (props.isOpened) handleClickOutside(event);
  }, [props.isOpened]);

  const { locale } = useMalaebState();
  const { commons } = Locale;

  // const generateErrorsMsgs = (errors) => {
  //   let message = [];
  //   if (errors) {
  //     for (const [k, v] of Object.entries(errors)) {
  //       if (k === 'required' && v) {
  //         message.push(commons.isRequired);
  //       } else if (k === 'min' && v) {
  //         message.push(` should be at least ${props.min} chars`);
  //       } else if (k === 'email' && v) {
  //         message.push(commons.shouldBeValid);
  //       } else if (k === 'phone' && v) {
  //         message.push(commons.shouldBeValid);
  //       } else if (k === 'number' && v) {
  //         message.push(commons.shouldBeValid);
  //       } else if (k === 'date' && v) {
  //         message.push(commons.shouldBeValid);
  //       }
  //     }
  //     if (message.length === 1) {
  //       return `${props.label} ${message[0]}`;
  //     } else if (message.length > 1) {
  //       return `${props.label} ${message.join(' & ')}`;
  //     } else {
  //       return;
  //     }
  //   }
  // };

  return (
    <>
      <div
        className={`my-2 control-field + ${props.error ? ' control-field-- border border-danger'  : ''
          }`}
      >
        {/* Date Range Body */}
        <div
          className={`control-field__body position-relative ${props.disabled && props.readOnly ? 'isDisabled' : ''
            }`}
        // onClick={() => setIsOpened(!isOpened)}
        // ref={selectRef}
        >
          {/* Label */}
          {props.hasLabel ? (
            <label
              className={`control-field__label ${props.error ? ' control-field__label-- text-danger' : ''
                }`}
            >
              {props.label}
            </label>
          ) : null}

          {/* Date Inputs */}
          <div className="row no-gutters justify-content-between">
            <SingleDatePicker
              numberOfMonths={1}
              isOutsideRange={() => false}
              onOutsideClick={true}
              date={props.date}
              noBorder={true}
              name={props.name}
              focused={focused}
              // focused={props.disabled ? true : props.isOpened}
              displayFormat={() => 'DD/MM/YYYY'}
              onFocusChange={({ focused }) => setFocused(focused)}
              onDateChange={props.onChangeDate}
              placeholder={props.placeholder}
              hideKeyboardShortcutsPanel={true}
              customArrowIcon={true}
              isRTL={locale === 'en' ? false : true}
              anchorDirection={locale === 'en' ? 'left' : 'right'}
              ref={selectRef}
              {...props}
            />
            <i className="fas fa-calendar-alt date-icon p-2"></i>
          </div>
        </div>
      </div>
      <small
        className={` text-caption control-field__feedback control-field__feedback-- text-danger d-block error-message`}
      >
        {props.error}
      </small>
    </>
  );
}

DatePickerField.defaultProps = {
  hasLabel: true,
};
