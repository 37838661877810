import React from 'react'
import Locale from 'translations';

export const CoachEvent = ({ item }) => {
    const { calendarPage } = Locale
    return (
        <>
            <div className='d-flex justify-content-center bg-darkBlue'>
                <div className=' d-flex align-items-center flex-column'>
                    <p className={item.event.booking_name?.length > 15 && item.event.payment_order_duration <= 60 ? "fw-light text-font10" : "fw-light text-font13"}
                    >
                        {item.event.booking_name}
                    </p>
                    <p className='py-1 fw-light text-font13'>💰{calendarPage?.cash}</p>
                </div>
            </div>

            <div className='px-2 pt-1 d-flex justify-content-between align-items-center rbcEventContentH1 '>

                <p className='font-bold font14'>
                    {item.event.payment_currency}  {item.event.final_price}
                </p>
                <p>{item?.event?.booking_status_label}</p>
            </div>

        </>
    );
};