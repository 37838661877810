import React from "react";
import { useMalaebState } from "context/global";
import Locale from 'translations';

const TextFieldWithSelect = (props) => {
	const { commons } = Locale;
	const { locale } = useMalaebState();
	const prependSelect = (
		<div
			className={`${props.className ?? ""} control-field__prepend pt-3 ${
				props.color ? " control-field__prepend--" + props.color : ""
			}`}
		>
			<select
				className={`control-field__select ${locale === "ar" ? "px-3" : "px-3"}`}
				value={props.selectValue}
				onChange={props.onChangeSelect}
			>
				{props.children}
			</select>
		</div>
	);

	const appendSelect = (
		<div
			className={`control-field__append pt-3 ${
				props.color ? " control-field__append--" + props.color : ""
			}`}
		>
			<select
				className={`control-field__select ${locale === "ar" ? "pl-3" : "pr-3"}`}
				value={props.selectValue}
				onChange={props.onChangeSelect}
			>
				{props.children}
			</select>
		</div>
	);

	const generateErrorsMsgs = (errors) => {
		let message = [];
		if (errors) {
			for (const [k, v] of Object.entries(errors)) {
				if (k === "required" && v) {
					message.push(commons.isRequired);
				} else if (k === "min" && v) {
					message.push(` should be at least ${props.min} chars`);
				} else if (k === "maxNumber" && v) {
					message.push(` should not exceed ${props.max}`);
				} else if (k === "minNumber" && v) {
					message.push(` should be at least ${props.min}`);
				} else if (k === "email" && v) {
					message.push(commons.shouldBeValid);
				} else if (k === "phone" && v) {
					message.push(commons.shouldBeValid);
				} else if (k === "number" && v) {
					message.push(commons.shouldBeNumber);
				}
			}
			if (message.length === 1) {
				return `${props.label} ${message[0]}`;
			} else if (message.length > 1) {
				return `${props.label} ${message.join(" & ")}`;
			} else {
				return;
			}
		}
	};

	return (
		<>
			<div
				className={`my-2 input-group control-field ${
					props.color ? " control-field--" + props.color : ""
				}`}
			>
				{/* Text Field Prepend */}
				{props.selectPosition === "prepend" ? prependSelect : ""}

				{/* Text Field Body */}
				<div className="control-field__body">
					{/* Label */}
					<label
						className={`control-field__label ${
							props.color ? " control-field__label--" + props.color : ""
						}`}
					>
						{props.label}
					</label>

					{/* Text Input */}
					<div className="row no-gutters">
						<input
							className={`${props.className ?? ""} control-field__input w-100`}
							id={props.id}
							value={props.value}
							onChange={props.onChange}
							onBlur={props.onBlur}
							placeholder={props.placeholder}
							onKeyUp={props.onKeyUp}
							disabled={props.disabled}
						/>
					</div>
				</div>

				{/* Text Field Append */}
				{props.selectPosition === "append" ? appendSelect : ""}
			</div>

			{/* Feedback */}
			<small
				className={`control-field__feedback control-field__feedback--${
					props.color
				} ${props.isInvalid ? "d-block" : "d-none"}`}
			>
				{props.feedbackMessage}
			</small>
			{/* Error Message */}
			<small
				className={`control-field__feedback control-field__feedback--${props.color} d-block error-message`}
			>
				{generateErrorsMsgs(props.errors)}
			</small>
		</>
	);
};

export default TextFieldWithSelect;
