import React, { Dispatch, SetStateAction, useState } from 'react';
import Locale from 'translations';
import Loader from 'components/LoadingPage/LoadingPage';
import { useDispatch, useSelector } from 'react-redux';
import { AddonDetails } from 'services/SendAddons/addons';
import { RootState } from 'Store';
import { AddonObj } from 'Store/Calendar/props';
import { useDeleteAddonMutation } from 'services/deleteAddon';
import { useAddNotification } from '../Helpers/addNotification';
import { DeleteAddonModal } from '.';
import { CalendarSliceActions } from 'Store/Calendar';
import { useSendAddonsMutation } from 'services/SendAddons';

interface AddonModalProps {
  index: number
  addon: AddonObj
  addonDetailsList: AddonDetails[]
  addonList: AddonObj[]
  setAddonDetailsList: Dispatch<SetStateAction<AddonDetails[]>>
  setAddonList: Dispatch<SetStateAction<AddonObj[]>>
  goToCreate: (arg0: boolean) => void
}

const Addon = (props: AddonModalProps) => {
  const dispatch = useDispatch()
  const selectedStadium = useSelector((state: RootState) => state?.calendar?.selectedStadium)
  const bookingState = useSelector((state: RootState) => state?.calendar?.BookingState)
  const { addonsPage, messages } = Locale
  const [selectedAddonToDelete, setSelectedAddonToDelete] = useState<number | undefined>()
  const [deleteAddonReq, deleteAddonRes] = useDeleteAddonMutation()
  const [sendAddonsRequest, sendAddonsResponse] = useSendAddonsMutation()


  const getAddonDetails = (id) => {
    return props?.addonDetailsList?.find((addonDetails: AddonDetails) => addonDetails?.addon_id == id)
  }

  const decrementCounter = (addon: AddonObj) => {
    const rowInp = getAddonDetails(addon?.id)
    const nonChangedRows = props?.addonDetailsList?.filter((item: AddonDetails) => item?.addon_id !== addon?.id);
    if (rowInp?.count) {
      if (rowInp.count < 1) return;
      rowInp.count -= 1
      rowInp.total_price = Number(rowInp?.count * Number(addon?.cost))
      props?.setAddonDetailsList([rowInp, ...nonChangedRows])
    };
  }

  const incrementCounter = (addon: AddonObj) => {
    const rowInp = getAddonDetails(addon?.id)
    const nonChangedRows = props?.addonDetailsList?.filter((item: AddonDetails) => item?.addon_id !== addon?.id);
    if (rowInp) {
      rowInp.count += 1;
      rowInp.total_price = Number(rowInp?.count * Number(addon?.cost))
      props?.setAddonDetailsList([rowInp, ...nonChangedRows])
    }
  };

  const deleteAddon = () => {
    if (selectedStadium?.id) {
      deleteAddonReq({
        stadiumId: selectedStadium?.id,
        id: Number(selectedAddonToDelete)
      })
        .then((data: any) => {
          if (data?.error) {
            useAddNotification(data?.error?.data?.message, messages.warning, "danger")
          }
          else {
            props?.setAddonList(props?.addonList?.filter((item) => item?.id !== Number(selectedAddonToDelete)))
            sendAddonsRequest({
              id: bookingState?.id,
              addons: props?.addonDetailsList
            }).then((data: any) => {
              if (data?.error) {
                useAddNotification(data?.error?.data?.message, messages.warning, "danger")
              }
            })
            setSelectedAddonToDelete(undefined)
            useAddNotification(addonsPage?.deletedSuccess, messages.success, "success")
          }
        })
    }
  }

  const goToEdit = (addon) => {
    dispatch(CalendarSliceActions?.setAddonDetails({
      id: addon?.id,
      name: addon?.name,
      cost: addon?.cost
    }))
    props?.goToCreate(false)
  }

  return (
    <>
      <div className='mb-3 AddonBox' key={props?.index}>
        <div className='py-2  bg-white container details'>
          <div className=' d-flex align-items-center justify-content-between'>
            <h5 className='addonTitle'>{props?.addon?.name}</h5>
            <div className='d-flex align-items-center'>
              <button className='addonPrice text-lemon mr-5 text-headline fw-bold'
                onClick={() => goToEdit(props?.addon)}
              >{props?.addon?.cost} {selectedStadium?.main_currency_label}</button>
              <div className='d-flex align-items-center ml-3'>
                <i className="fa-solid fa-circle-minus text-lemon fa-xl cursor-pointer"
                  onClick={() => decrementCounter(props?.addon)}
                ></i>
                <span className='text-lemon fw-bold px-3 text-title'>{getAddonDetails(props?.addon?.id)?.count}</span>
                <i className="fa-solid fa-circle-plus text-lemon fa-xl cursor-pointer"
                  onClick={() => incrementCounter(props?.addon)}
                ></i>
              </div>
            </div>
          </div>

        </div>
        <div className='footer d-flex justify-content-between px-3 py-2 align-items-center'>
          <p className='text-captio2 fw-light italic'>{addonsPage?.click}</p>
          <span className=' text-maroon controller text-caption delete'>
            <div className='d-flex flex-column align-items-center cursor-pointer'
              onClick={() => setSelectedAddonToDelete(props?.addon?.id)}
            >
              <i className="fa-solid fa-trash-can cursor-pointer text-subtitle pb-1"></i>
            </div>
          </span>
        </div>
      </div >
      <DeleteAddonModal handleDelete={deleteAddon} text={addonsPage?.deleteModalTitle} open={selectedAddonToDelete} onClose={() => setSelectedAddonToDelete(undefined)} />
      {deleteAddonRes?.isLoading ? <Loader /> : null}
    </>
  );
};
export default Addon;
