import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import Locale from 'translations';

const SelectWithIcon = (props) => {
  const [isOpened, setIsOpened] = useState(false);
  const selectRef = useRef<any>(null);
  const { commons } = Locale;

  const handleClickOutside = (e) => {
    if (selectRef.current && !selectRef.current.contains(e.target))
      setIsOpened(false);
    else setIsOpened(true);
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => window.removeEventListener('click', handleClickOutside);
  }, []);

  if (props.extruded) {
    if (props.options) {
      props.options.forEach((element) => (element.disabled = false));
      props.options.forEach((element) => {
        props.extruded.forEach((ele) => {
          if (ele.value == element.id) {
            element.disabled = true;
          }
        });
      });
    }
  }


  return (
    <div>
      <div
        className={`my-2 control-field w-100 + ${props.error ? ' control-field-- border border-danger'  : ''
          }`}
      >
        {/* Text Field Body */}
        <div
          className={`control-field__body ${props.disabled ? 'isDisabled' : ''
            }`}
          onClick={() => setIsOpened(!isOpened)}
          ref={selectRef}
        >
          <div className="d-flex align-items-center">
            <div className='icon' style={{ width: "12%" }}>
              <i className={`${props.icon} fa-fw fa-lg`}></i>
            </div>
            <div className='inputwithLabel' style={{ width: "88%" }}>
              {/* Label */}
              {props.hasLabel ? (
                <label
                  className={`control-field__label ${props.error ? ' control-field__label-- text-danger'  : ''
                    }`}
                >
                  {props.label}
                </label>
              ) : null}

              {/* Text Input */}

              <div className="row no-gutters">
                <Select
                  className="control-field__select"
                  id={props.id}
                  value={
                    props.value && !props.multi
                      ? { label: props.value }
                      : { label: props.placeholder || "Select" }
                  }
                  // getOptionLabel={(option) => option.name}
                  // getOptionValue={(option) => option.id}
                  name={props.name}
                  menuIsOpen={props.disabled ? false : isOpened}
                  isMulti={props.multi}
                  isSearchable={props.searchable}
                  isDisabled={props.disabled}
                  placeholder={props.placeholder}
                  onChange={props.onChange}
                  onClick={props.onClick}
                  options={
                    props.options && props.options.length > 0
                      ? props.options.map((option) => ({
                        value: option.id,
                        label: option.label ?? option.lable,
                        Disabled: option.disabled,
                      }))
                      : []
                  }
                  onBlur={props.onBlur}
                  isClearable={false}
                  onFocus={props.onFocus}
                  isOptionDisabled={(option) => option.Disabled}
                  defaultValue={props.defaultValue}
                  icon={props.icon}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* error */}
      <small
        className={`text-caption control-field__feedback control-field__feedback-- text-danger d-block error-message`}
      >
        {props.error}
      </small>
    </div>
  );
};

SelectWithIcon.defaultProps = {
  hasLabel: true,
};

export default SelectWithIcon;
