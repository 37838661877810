import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { UserData } from 'Store/Calendar/props'

const builder = (build: EndpointBuilder<any, any, any>) =>
    build.mutation<Response, any>({
        query: body => ({
            url: '/v4/pitch-owner-dashboard/auth/me',
            method: 'Get',
        })
    })

interface Response {
    data: UserProfile
}

export interface UserProfile extends UserData {
    id: number,
    birthday?: string,
    gender?: number,
    bio?: string,
    role?: number,
}

export interface Stadium {
    title?: string
    title_ar?: string
    id: number
    phone_code?: string
    supported_payment_methods?: PaymentMethods[]
    main_currency_label?: string,
    main_currency_label_ar?: string,
    dayoff?: DayOff[]
    label?: string
    label_ar?: string
}

export interface StadiumInAPI {
    title: string
    title_ar: string
    id: number
    phone_code?: string
    supported_payment_methods?: PaymentMethods[]
    main_currency_label?: string,
    main_currency_label_ar?: string,
    dayoff?: DayOff[]

}

export interface Pitch {
    id: number,
    lable: string
    lable_ar: string
    pitch_size: number
    prices: Price[]
    sport_type: number[]
}

export interface DayOff {
    id: number,
    stadium_id: number,
    pitch_ids: number[] | string[]
    pitch_names: string,
    start_at: string,
    end_at: string,
    start_time_at: string,
    end_time_at: string
    created_at: string
    updated_at: string
}

export enum PaymentMethods {
    CASH = '1',
    CREDIT_CARD = '5',
    APPLE_PAY = '11',
}


export enum Permissions {
    CancelBooking = "pitchowner.user_made_booking.cancel",
    AddAdons = "pitchowner.user_made_booking.add_addons",
    EditBooking = "pitchowner.user_made_booking.edit",
    AdminViewLogs = "admin.dashboard_logs.view",
    PitchOwnerViewLogs = "pitchowner.dashboard_logs.view"
}

export interface Price {
    id: number,
    day: number,
    pitch_id: number,
    price: number,
    price_60: number,
    price_90: number,
    price_120: number,
    valid_from: string
    female_friendly: number,
    valid_to: string
    updated_at: string
}

export default builder
