import React from 'react'

const CustomeHeader = (props) => {
    return (
        <>
            <div>{props.title}</div>
            <div className='text-headline'>{props.subTitle}</div>
        </>
    )
}

export default CustomeHeader