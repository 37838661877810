import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'

export default (build: EndpointBuilder<any, any, any>) =>
    build.mutation<Response, data>({
        query: body => ({
            url: `/v4/pitch-owner-app/stadiums/${body?.stadiumId}/addons/${body?.addon_id}`,
            method: 'POST',
            body,
        }),
    })

interface data {
    stadiumId: number | string
    addon_id: number
    name: string,
    cost: string
}