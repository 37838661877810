import React from 'react';
import images from 'Pages/CalendarPage/Helpers/images';
import Locale from 'translations';
import { useMalaebState } from 'context/global';
import { useSelector } from 'react-redux';
import { RootState } from 'Store';


export default function Loader() {
    const title = Locale.messages.loading;
    const selectedStadium = useSelector((state: RootState) => state?.calendar?.selectedStadium)
    const { isAuth } = useMalaebState();

    return (
        <>
            <div className={`inner-loader loader-with-background h-100 ${isAuth && selectedStadium !== undefined ? "left-17" : ""}`}>
                <div className="text-center">
                    <img src={images?.logo} alt="Loader" />
                    <div className="d-block mt-2">
                        <h1 className="text-16">{title}</h1>
                    </div>
                </div>
            </div >
        </>
    );
}

