import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'

export default (build: EndpointBuilder<any, any, any>) =>
    build.mutation<Response, SlotInfo>({
        query: body => ({
            url: `/v3/pitch-owner-dashboard/slot-bookings-count`,
            method: 'POST',
            body,
        }),
    })

export interface SlotInfo {
    start_time: string
    end_time: string
    pitch_id: number | null
}
