import moment from 'moment'
import React from 'react'

export const getDatatoSubmit = (createBookingState, role) => {
    let data
    if (role === "admin") {
        if (createBookingState.user_id) {
            data = {
                amount_paid: createBookingState.amount_paid,
                booking_name: createBookingState.booking_name,
                booking_note: createBookingState.booking_note,
                booking_type: createBookingState.booking_type,
                duration: createBookingState.payment_order_duration,
                last_match_date: moment(createBookingState.last_match_date).format('YYYY-MM-DD'),
                day_of_week: createBookingState.day_of_week,
                match_date: moment(createBookingState.match_date).format('YYYY-MM-DD'),
                match_time: createBookingState.match_time,
                payment_actual_amount: createBookingState.payment_actual_amount,
                phone_number: createBookingState.phoneCode + createBookingState.booking_phone_number,
                pitch_id: createBookingState.pitch_id,
                user_id: createBookingState.user_id,
                payment_method: createBookingState.payment_method
            }
        }
        else {
            data = {
                amount_paid: createBookingState.amount_paid,
                booking_name: createBookingState.booking_name,
                booking_note: createBookingState.booking_note,
                booking_type: createBookingState.booking_type,
                duration: createBookingState.payment_order_duration,
                last_match_date: moment(createBookingState.last_match_date).format('YYYY-MM-DD'),
                day_of_week: createBookingState.day_of_week,
                match_date: moment(createBookingState.match_date).format('YYYY-MM-DD'),
                match_time: createBookingState.match_time,
                payment_actual_amount: Number(createBookingState.payment_actual_amount),
                phone_number: createBookingState.phoneCode + createBookingState.booking_phone_number,
                pitch_id: createBookingState.pitch_id,
                payment_method: createBookingState.payment_method
            }
        }
    }
    else {
        data = {
            amount_paid: createBookingState.amount_paid,
            booking_name: createBookingState.booking_name,
            booking_note: createBookingState.booking_note,
            duration: createBookingState.payment_order_duration,
            last_match_date: moment(createBookingState?.last_match_date).format('YYYY-MM-DD'),
            day_of_week: createBookingState?.day_of_week,
            match_date: moment(createBookingState.match_date).format('YYYY-MM-DD'),
            match_time: createBookingState.match_time,
            payment_actual_amount: createBookingState.payment_actual_amount,
            phone_number: createBookingState.phoneCode + createBookingState.booking_phone_number,
            pitch_id: createBookingState.pitch_id,
            payment_method: createBookingState.payment_method
        }
    }

    return data
}
