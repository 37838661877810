import axios from 'axios';
const URL = process.env.REACT_APP_AUTH_API;


export const forgetPassword = async (data) => {
  return await axios
    .post(`${URL}/auth/password/forgot`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const passReset = async (data) => {
  return await axios
    .post(`${URL}/auth/password/reset`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const userLogout = async () => {
  return await axios
    .post(`${URL}/auth/logout`)
    .then((res) => res)
    .catch((err) => err.response);
};
