import State from './state';
import Reducer from './reducer'
import { createSlice } from '@reduxjs/toolkit'
import { CoachesState } from './props'

const slice = createSlice({
  name: 'coachesState',
  initialState: State as CoachesState,
  reducers: Reducer,
})

export const CoachesSliceActions = slice.actions

export default slice.reducer
