import { api } from 'services/api'
import upDateSingleBooking from './updateSingle'

const useApi = api.injectEndpoints({
    endpoints: build => ({
        upDateSingleBooking: upDateSingleBooking(build),
    }),
    overrideExisting: false,
})

export const {
    useUpDateSingleBookingMutation
} = useApi
