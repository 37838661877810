import { api } from 'services/api'
import sendNoShowFlag from './noShow'

const useApi = api.injectEndpoints({
    endpoints: build => ({
        sendNoShowFlag: sendNoShowFlag(build),
    }),
    overrideExisting: false,
})

export const {
    useSendNoShowFlagMutation
} = useApi
